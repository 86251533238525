const states = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO'
]

const paymentTypes = [
    'Cartão de Crédito',
    'Boleto Bancário',
    'Ao Polo',
    'Pix ou Transferência'
]

const educationalLevels = [
    {
        value: 'Bacharelado',
        label: 'Bacharelado'
    }, {
        value: 'Capacitação',
        label: 'Capacitação'
    }, /*{
        value: 'Doutorado',
        label: 'Doutorado'
    },*/ {
        value: 'Ensino Médio',
        label: 'Ensino Médio'
    },
    {
        value: 'Complementação Pedagógica',
        label: 'Complementação Pedagógica'
    }, {
        value: 'Licenciatura',
        label: 'Licenciatura'
    }, /*{
        value: 'Mestrado',
        label: 'Mestrado'
    }, */{
        value: 'Pós-graduação',
        label: 'Pós-graduação'
    }, {
        value: 'Segunda Licenciatura',
        label: 'Segunda Licenciatura'
    }, {
        value: 'Técnico',
        label: 'Técnico'
    },
    {
        value: 'Tecnólogo',
        label: 'Tecnólogo'
    },
    {
        value: 'Não especificado',
        label: 'Não especificado'
    }



]

export default {
    states, educationalLevels, paymentTypes
}