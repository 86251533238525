<template>
  <modal
    v-model:show="showModal"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template v-slot:header>
        <h4 class="modal-title" id="exampleModalLabel">
          {{ isNew ? "Cadastrar comissão" : "Editar comissão" }}
        </h4>
        <h6 class="text-muted">
          Informe o nome e as porcentagens que a empresa receberá da instituição
          de ensino
        </h6>
      </template>

      <div>
        <div class="form-group row mb-2">
          <label
            class="
              col-lg-3 col-md-12 col-sm-12 col-form-label
              form-control-label
            "
            >Instituição:</label
          >
          <div class="col-lg-9 col-md-12 col-sm-12">
            <el-select
              v-model="comission_.school"
              ref="select_course"
              value-key="name"
              loading-text="Buscando instituições"
              class="input-group-alternative"
              placeholder="Selecione a instituição de ensino que deseja cadastrar uma comissão"
              no-data-text="Nenhum instituição encontrada"
            >
              <el-option
                v-for="item in Schools"
                :key="item.value"
                :label="item.initials"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="form-group row mb-2">
          <label
            class="
              col-lg-3 col-md-12 col-sm-12 col-form-label
              form-control-label
            "
            >Nome:</label
          >
          <div class="col-lg-9 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              v-model="comission_.name"
              required
              placeholder="Identificação da comissão"
            ></base-input>
          </div>
        </div>

        <div class="form-group row mb-2">
          <label
            class="
              col-lg-3 col-md-12 col-sm-12 col-form-label
              form-control-label
            "
            >Matrícula:</label
          >
          <div class="col-lg-9 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              v-model="comission_.percentages[0]"
              type="money"
              required
              placeholder="35 %"
            ></base-input>
          </div>
        </div>

        <div class="form-group row mb-2">
          <label
            class="
              col-lg-3 col-md-12 col-sm-12 col-form-label
              form-control-label
            "
            >Demais:</label
          >
          <div class="col-lg-9 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              v-model="comission_.percentages[1]"
              type="money"
              required
              placeholder="35 %"
            ></base-input>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button
            size="sm"
            @click="showModal = false"
            class="float-right"
            type="danger"
            >CANCELAR</base-button
          >
          <base-button
            size="sm"
            @click="save"
            class="float-right"
            type="primary"
            >SALVAR</base-button
          >
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
import BaseButton from "../../components/BaseButton";
import { ElNotification, ElSelect, ElOption } from "element-plus";
export default {
  components: { BaseButton, ElSelect, ElOption },
  name: "comission-modal",
  props: ["shouldShow", "comission"],
  data() {
    return {
      comission_: this.comission || { percentages: [] },
    };
  },
  watch: {
    comission(v) {
      this.comission_ = { ...(v || { percentages: [] }) };
    },
  },
  computed: {
    isNew() {
      return this.comission ? false : true;
    },
    showModal: {
      get: function () {
        return this.shouldShow;
      },
      set: function () {
        this.$emit("close-modal", false);
      },
    },
    Schools() {
      return this?.$store?.state?.user?.schools || [];
    },
  },
  methods: {
    save() {
      if (!this.isNew) this.updateComission();
      else this.createComission();
    },
    createComission() {
      fetch(`${this.$store.state.apiUrl}comissions`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ permissions: "", ...this.comission_ }),
      })
        .then(async (response) => {
          if (response.status == 200) {
            this.errorMessages = {};
            this.$emit("close-modal", true);
            this.$emit("reload-data", true);
            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
            }),
          (e) =>
            ElNotification.error({
              message: e.message,
            })
        )
        .finally(() => (this.isLoadingSubmit = false));
    },
    updateComission() {
      fetch(`${this.$store.state.apiUrl}comissions/${this.comission_.uid}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(this.comission_),
      })
        .then(async (response) => {
          if (response.status == 200) {
            this.errorMessages = {};
            this.$emit("close-modal", true);
            this.$emit("reload-data");
            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
              message:
                "Tenha em mente que novos valores para essa comissão só surtirão efeito em novos títulos",
            }),
          (e) =>
            ElNotification.error({
              message: e.message,
            })
        )
        .finally(() => (this.isLoadingSubmit = false));
    },
  },
};
</script>
