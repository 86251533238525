<template>
  <modal
    v-model:show="showModal"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white p-3"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template #header>
        <h4 class="modal-title" id="exampleModalLabel">Editar Pagamento</h4>
      </template>

      <div>
        <base-loader v-if="isLoading"></base-loader>
        <div v-else>
          <div class="row justify-content-between px-3 py-2">
            <div class="text-left mb-2">
              <label class="form-control-label">Estado do Título:</label>
            </div>
            <div class="button-group">
              <base-button
                size="sm"
                :type="payment_.status == 'opened' ? 'primary' : 'secondary'"
                v-on:click="setPaymentStatus(payment_, 'opened')"
                >Aberto</base-button
              >
              <base-button
                size="sm"
                :type="payment_.status == 'paid' ? 'primary' : 'secondary'"
                v-on:click="setPaymentStatus(payment_, 'paid')"
                >Pago</base-button
              >
              <base-button
                size="sm"
                :type="payment_.status == 'closed' ? 'primary' : 'secondary'"
                v-on:click="setPaymentStatus(payment_, 'closed')"
                >Fechado</base-button
              >
            </div>
          </div>
          <div class="dropdown-divider"></div>

          <!--Obs-->
          <div class="form-group row mb-2">
            <label
              class="col-lg-3 col-md-12 col-sm-12 col-form-label form-control-label"
              >Valor do Título:</label
            >
            <div class="col-lg-9 col-md-12 col-sm-12">
              <base-input
                formClasses="input-group-alternative"
                v-model="payment_.cost"
                type="money"
              ></base-input>
            </div>
          </div>

          <!--Obs-->
          <div class="form-group row mb-2">
            <label
              class="col-lg-3 col-md-12 col-sm-12 col-form-label form-control-label"
              >Valor Pago:</label
            >
            <div class="col-lg-9 col-md-12 col-sm-12">
              <base-input
                formClasses="input-group-alternative"
                v-model="payment_.value"
                placeholder="não pago"
                type="money"
              ></base-input>
            </div>
          </div>

          <!--Obs-->
          <div class="form-group row mb-2">
            <label
              class="col-lg-3 col-md-12 col-sm-12 col-form-label form-control-label"
              >Vencimento do Título:</label
            >
            <div class="col-lg-9 col-md-12 col-sm-12">
              <DatePicker
                v-model="payment_.expires_at"
                timezone="UTC"
                :model-config="{ timeAdjust: '00:00:00' }"
                :popover="{ visibility: 'focus' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <base-input
                    formClasses="input-group-alternative"
                    :value="inputValue"
                    v-on="inputEvents"
                  ></base-input>
                </template>
              </DatePicker>
            </div>
          </div>

          <!--Obs-->
          <div class="form-group row mb-2">
            <label
              class="col-lg-3 col-md-12 col-sm-12 col-form-label form-control-label"
              >Data de Pagamento:</label
            >
            <div class="col-lg-9 col-md-12 col-sm-12">
              <DatePicker
                v-model="payment_.paid_at"
                timezone="UTC"
                :max-date="new Date()"
                :model-config="{ timeAdjust: '00:00:00' }"
                :popover="{ visibility: 'focus' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <base-input
                    formClasses="input-group-alternative"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="não pago"
                  ></base-input>
                </template>
              </DatePicker>
            </div>
          </div>

          <!--Obs-->
          <div class="form-group row mb-2" v-if="userCan('comissions.r')">
            <label
              class="col-lg-3 col-md-12 col-sm-12 col-form-label form-control-label"
              >% Repasse:</label
            >
            <div class="col-lg-9 col-md-12 col-sm-12">
              <base-input
                :modelValue="
                  Number(`${Number.parseFloat(payment_?.comission) * 100}`)
                "
                @update:modelValue="payment_.comission = $event / 100"
                type="money"
                formClasses="input-group-alternative"
              ></base-input>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button
            size="sm"
            @click="showModal = false"
            class="float-right"
            type="danger"
            >CANCELAR</base-button
          >
          <base-button
            size="sm"
            @click="save"
            class="float-right"
            :isLoading="isLoadingSubmit"
            type="primary"
            >SALVAR</base-button
          >
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
import BaseLoader from "../../components/BaseLoader.vue";
import { DatePicker } from "v-calendar";
import { ElNotification } from "element-plus";

export default {
  name: "edit-payment-modal",
  props: ["shouldShow", "payment"],
  components: { BaseLoader, DatePicker },
  data() {
    return {
      isLoading: true,
      payment_: {
        status: "opened",
        expires_at: new Date(),
        paid_at: null,
      },
      isLoadingSubmit: false,
    };
  },
  watch: {
    payment(v) {
      const { uid, comission, cost, expires_at, paid_at, status, value } =
        v || {};
      this.payment_ = uid
        ? { uid, comission, cost, expires_at, paid_at, status, value }
        : {
            status: "opened",
            expires_at: new Date(),
            paid_at: null,
          };
    },
  },
  mounted() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
  computed: {
    showModal: {
      get() {
        return this.shouldShow;
      },
      set(v) {
        this.$emit("close-modal", v);
      },
    },
  },
  methods: {
    userCan(permission) {
      const { authorizations } = this.$store.state.user?.role || {};

      if (authorizations) {
        const [entity, action] = permission.split(".");

        if (authorizations[entity] && authorizations[entity].includes(action))
          return true;
      }

      return false;
    },
    setPaymentStatus(payment, status) {
      payment.status = status;
      if (status === "paid") {
        payment.value = payment.cost;
        payment.paid_at = payment.expires_at;
      }
    },
    save() {
      this.isLoadingSubmit = true;
      fetch(`${this.$store.state.apiUrl}payments/${this.payment_.uid}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(this.payment_),
      })
        .then(async (response) => {
          if (response.status == 200) {
            this.$emit("close-modal", true);
            this.$emit("reload-data");
            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
            }),
          (e) =>
            ElNotification.error({
              message: e.message,
            })
        )
        .finally(() => (this.isLoadingSubmit = false));
    },
  },
};
</script>
