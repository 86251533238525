<template>
  <div class="container-fluid d-flex h-100 flex-column pt-6 px-2">
    <dashboard-navbar searchHint="Buscar curso..."></dashboard-navbar>

    <base-loader v-if="isLoading"></base-loader>
    <card
      type="secondary"
      shadow
      v-if="!hasError"
      header-classes="bg-white pb-1"
      body-classes="px-lg-1 py-lg-1"
      class="border-0 mt-4"
      footerClasses="p-0"
    >
      <template #header>
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0 text-white">Instituições de Ensino</h3>
          </div>
          <div class="col text-right">
            <base-button
              type="primary"
              :icon="a.icon"
              size="sm"
              v-for="a in NavActions"
              :key="a"
              v-on:click.stop.prevent="a.action"
            ></base-button>
          </div>
        </div>
      </template>

      <div v-if="Array.isArray(schools) && schools.length > 0">
        <card v-for="s in schools" :key="s.uid" class="m-2">
          <template #header>
            <div class="row justify-content-between">
              <div class="col">
                <h2>{{ s.initials }}</h2>
                <h5 class="text-muted">{{ s.name }}</h5>
              </div>
              <h5 class="text-muted">{{ s.cnpj }}</h5>
            </div>
          </template>
          <div>
            <h4>Quantidade de Cursos Ofertados:</h4>
            <div v-if="!Object.keys(CountCoursesByLevel(s)).length">
              <h4 class="text-muted">Nenhum curso cadastrado</h4>
            </div>
            <div
              v-else
              class="row justify-content-between px-3"
              v-for="(c, id) in CountCoursesByLevel(s)"
              :key="id"
            >
              <h5 class="text-muted">{{ id }}</h5>
              <h5 class="text-muted">{{ c }}</h5>
            </div>

            <h4>Formas de Pagamento:</h4>
            <div class="row justify-content-between px-3">
              <h5 class="text-muted">Parcelamento Máximo Boleto</h5>
              <h5 class="text-muted">{{ s.max_payments_ticket }}</h5>
            </div>
            <div class="row justify-content-between px-3">
              <h5 class="text-muted">Parcelamento Máximo Cartão de Crédito</h5>
              <h5 class="text-muted">{{ s.max_payments_credit }}</h5>
            </div>
            <div class="row justify-content-between px-3">
              <h5 class="text-muted">Recebimento único Boleto</h5>
              <h5 class="text-muted">
                {{ s.is_one_time_transfer_ticket ? "Sim" : "Não" }}
              </h5>
            </div>
            <div class="row justify-content-between px-3">
              <h5 class="text-muted">Recebimento único Cartão de Crédito</h5>
              <h5 class="text-muted">
                {{ s.is_one_time_transfer_credit ? "Sim" : "Não" }}
              </h5>
            </div>

            <h4>Repasse dos Pagamentos:</h4>
            <div class="row justify-content-between px-3">
              <h5 class="text-muted">Dia de Fechamento do Repasse</h5>
              <h5 class="text-muted">dia {{ s.closing_day }}</h5>
            </div>
            <div class="row justify-content-between px-3">
              <h5 class="text-muted">Dias de Pagamento do Repasse</h5>
              <h5 class="text-muted">dia {{ s.payday }}</h5>
            </div>
            <div class="row justify-content-between px-3">
              <h5 class="text-muted">Prazo para Pagamento do Repasse</h5>
              <h5 class="text-muted">{{ s?.settings?.payment_delay }} dias</h5>
            </div>
          </div>
          <template #footer>
            <div class="row justify-content-between">
              <div class="col-md-8 col-sm-12">
                <p class="card-text">
                  <small class="text-muted">{{ s.address }}</small>
                </p>
              </div>
              <div class="row px-4">
                <base-button
                  type="primary"
                  rounded
                  iconOnly
                  @click="openEditSchool(s)"
                  size="sm"
                  icon="fa fa-pencil"
                ></base-button>
                <el-popconfirm
                  title="Essa ação irá apagar tudo relacionado à essa Instituição"
                  confirm-button-type="danger"
                  confirm-button-text="Deletar Tudo!"
                  @confirm="openDeleteSchool(s)"
                  cancel-button-text="Cancelar"
                >
                  <template #reference>
                    <base-button
                      type="danger"
                      rounded
                      iconOnly
                      size="sm"
                      icon="fa fa-trash"
                    ></base-button>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </template>
        </card>
      </div>
      <card v-else-if="Array.isArray(schools)" shadow>
        <base-error
          title="Nenhuma Instituição foi cadastrada"
          icon="fas fa-times-circle fa-4x success"
          details="Cadastre a primeira Instituição de Ensino para visualizar"
        ></base-error>
      </card>
      <card v-else shadow>
        <base-error
          title="Erro ao carregar as instituições de ensino"
          details="Atualize a página para tentar novamente"
        ></base-error>
      </card>
    </card>
    <add-school
      @close-modal="showSchoolModal = false"
      @reload-data="fetchData"
      :shouldShow="showSchoolModal"
      :isNew="newSchool"
      :school="school"
    ></add-school>
  </div>
</template>

<script>
//import Card from "../components/Card.vue";
import DashboardNavbar from "../layout/DashboardNavbar.vue";
import "vue-loaders/dist/vue-loaders.css";
import BaseError from "../components/BaseError.vue";
import BaseLoader from "../components/BaseLoader.vue";
import AddSchool from "./Modals/AddSchoolModal.vue";
import { ElNotification, ElPopconfirm } from "element-plus";
// @ is an alias to /src

export default {
  name: "Schools",
  components: {
    DashboardNavbar,
    BaseLoader,
    AddSchool,
    BaseError,
    ElPopconfirm,
  },
  data() {
    return {
      hasError: false,
      pagination: 1,
      isLoading: false,
      limit: 5,
      newSchool: false,
      school: null,
      showSchoolModal: false,
    };
  },
  created() {
    //this.fetchData();
  },
  computed: {
    schools() {
      return this.$store.state.user.schools;
    },
    NavActions() {
      return [
        {
          icon: "fa fa-plus-square",
          action: this.openAddSchool,
        },
      ];
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column] !== "undefined";
    },
    CountCoursesByLevel(school) {
      const t =
        school?.courses?.reduce((t, c) => {
          if (!t[c.level]) t[c.level] = 1;
          else t[c.level]++;
          return t;
        }, {}) || {};

      console.log(t);
      return t;
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    openEditSchool(item) {
      this.school = item;
      this.newSchool = false;
      this.showSchoolModal = true;
    },
    openDeleteSchool(s) {
      fetch(this.$store.state.apiUrl + "schools/" + s.uid, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();

          throw response.json();
        })
        .then((e) => {
          if (isPromise(e))
            e.then((e) =>
              ElNotification.success({
                title: e.message,
              })
            );
          else
            ElNotification.success({
              title: e.message,
            });
          this.$store.dispatch("fetchSchools");
        })
        .catch((e) =>
          ElNotification.error({
            message: e.message || "Não foi possível remover essa Instituição",
          })
        )
        .finally(() => {
          this.isLoadingSubmit = false;
          this.$store.dispatch("fetchSchools");
        });
    },
    openAddSchool() {
      this.newSchool = true;
      this.school = {};
      this.showSchoolModal = true;
    },
    fetchData() {
      fetch(this.$store.state.apiUrl + "schools", {
        credentials: "include",
        //mode: "no-cors",
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => {
          if (response.status == 200) return response.json();
          else throw response.json();
        })
        .then((json) => (this.schools = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};

function isPromise(promise) {
  return !!promise && typeof promise.then === "function";
}
</script>

<style>
.avatar-xl {
  align-items: center;

  -ms-flex-pack: center;
  justify-content: center;
  height: 48px;
  width: 48px;
}
</style>
