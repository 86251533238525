import http from "./http";
export default (eventId) => ({
	async index(filters) {
		return http
			.index("events", filters)
			.then((json) => ({ events: json }))
			.catch((e) => ({ events: null, error: e }));
	},
	async get() {
		return http
			.get("events", eventId)
			.then((json) => ({ event: json }))
			.catch((e) => ({ event: null, error: e }));
	},
	async create(data) {
		return http
			.post("events", data)
			.then((json) => ({ event: json }))
			.catch((e) => ({ event: null, error: e }));
	},
	async update(data) {
		return http
			.put("events", eventId, data)
			.then((json) => ({ event: json }))
			.catch((e) => ({ event: null, error: e }));
	},
	async delete() {
		return http
			.delete("events", eventId)
			.then((json) => ({ event: json }))
			.catch((e) => ({ event: null, error: e }));
	},
});
