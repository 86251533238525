<template>
  <div class="col-12 py-5">
    <div class="row justify-content-center">
      <i :class="icon"></i>
    </div>
    <div class="row justify-content-center">
      <h2>{{ title }}</h2>
    </div>
    <div class="row justify-content-center">
      <h4>{{ details }}</h4>
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseError",
  props: {
    title: {
      type: String,
      default: "Ocorreu um erro",
    },
    details: {
      type: String,
      default: "Não foi possível contactar o servidor",
    },
    icon: {
      type: String,
      default: "fa fa-times-circle-o fa-4x error",
    },
  },
};
</script>
<style>
i.error {
  color: red;
}
i.success {
  color: green;
}
</style>