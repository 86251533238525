<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0 mt-5">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Insira seu email e senha para entrar</small>
          </div>
          <form role="form">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="email"
              v-on:keyup.enter="signIn()"
              addon-left-icon="ni ni-email-83"
              v-model="model.email"
              type="email"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="senha"
              type="password"
              v-on:keyup.enter="signIn()"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
            >
            </base-input>

            <base-checkbox
              v-model="model.shouldRemember"
              class="custom-control-alternative"
            >
              <span class="text-muted">Lembrar?</span>
            </base-checkbox>
            <div class="text-center">
              <base-button
                :isLoading="isLoadingButton"
                @click="signIn"
                type="primary"
                class="my-4"
                >Entrar</base-button
              >
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6"></div>
        <div class="col-6 text-right text-white cursor-pointer">
          <small @click="forgotPassword" href="#">Esqueci minha senha</small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElNotification, ElMessageBox } from "element-plus";
//import { ElNotification } from "element-plus";
export default {
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
        shouldRemember: false,
      },
      isLoadingButton: false,
    };
  },
  mounted() {
    setInterval(() => this.model, 5000);
  },
  methods: {
    async forgotPassword() {
      ElMessageBox.alert("Redefinir senha", {
        title: "Redefinir senha",
        message: "Contacte o administrador para redefinir sua senha.",
      });
    },
    signIn() {
      this.isLoadingButton = true;
      this.$store
        .dispatch("authenticate", {
          email: this.model.email,
          password: this.model.password,
          remain: this.model.shouldRemember,
        })
        .then((result) => {
          if (result) {
            window.document.location.href = "/";
          }
        })
        .catch((e) =>
          isPromise(e)
            ? e.then((e) =>
                ElNotification.error({
                  title: "Não foi possível entrar",
                  message: e.message,
                })
              )
            : ElNotification.error({
                title: "Não foi possível entrar",
                message: e.message,
              })
        )
        .finally(() => (this.isLoadingButton = false));
    },
  },
};
function isPromise(promise) {
  return !!promise && typeof promise.then === "function";
}
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
