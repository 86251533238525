import { createStore } from "vuex"
import constants from "./constants";
import auth from "./modules/auth";

window['NODE_ENV'] = process.env.NODE_ENV || 'development'

const store = createStore({
    state: {
        apiUrl: window.NODE_ENV === "production" ? window.location.protocol + '//api.' + window.location.hostname + '/' : 'http://localhost:3333/',
        constants,
        user: {
            schools: [],
            selectedSchool: JSON.parse(localStorage.getItem('selectedSchool'))
        }
    },
    mutations: {
        selectSchool(state, school) {
            if (school) {
                this.state.user.selectedSchool = school
                localStorage.setItem('selectedSchool', JSON.stringify(school))
            } else
                localStorage.setItem('selectedSchool', null)
        }
    },
    actions: {
        fetchSchools({ commit }) {
            fetch(this._state.data.apiUrl + "schools", {
                credentials: "include",
                //mode: "no-cors",
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((response) => {
                    if (response.status == 200) return response.json();
                    else throw response.json();
                })
                .then((json) => (this._state.data.user.schools = json))
                .finally(() => {
                    const olderSchool = JSON.parse(localStorage.getItem('selectedSchool'))
                    if (`${olderSchool}` != 'null' && this._state?.data?.user?.schools?.some(e => e.uid === olderSchool.uid))
                        return commit('selectSchool', olderSchool)

                    commit('selectSchool', this._state?.data?.user?.schools?.find((e, i) => i === 0))
                })
        }
    },
    modules: {
        auth
    }
})

store.dispatch('fetchSchools')





export default store