<template>
  <el-upload
    accept=".csv"
    :http-request="() => {}"
    action="/api"
    :limit="1"
    :before-upload="saveUploadedFile"
  >
    <el-button type="primary" size="small"
      >Clique para selecionar um arquivo</el-button
    >
  </el-upload>
</template>
<script>
import { ElUpload, ElButton } from "element-plus";
export default {
  name: "BaseUpload",
  components: { ElUpload, ElButton },
  methods: {
    saveUploadedFile(rawFile) {
      this.extractStringFromFile(rawFile);
    },
    extractStringFromFile(rawFile) {
      const reader = new FileReader();
      const self = this;

      reader.onload = (e) => {
        self.$emit("uploaded-file", e.target.result);
      };

      reader.readAsText(rawFile);
    },
  },
};
</script>