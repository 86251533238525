<template>
  <div class="card shadow mt-3" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>

    <slot />

    <div v-show="!hasDefault" class="table-responsive">
      <base-table
        thead-classes="thead-light"
        :key="keyTable"
        :data="tableData"
        tbody-classes="list"
        :hasInlineRoute="hasInlineRoute"
        :limit="limit"
        :offset="(pagination - 1) * limit"
      >
        <template v-slot:default>
          <slot name="header"></slot>
        </template>
        <template v-slot:columns>
          <slot name="columns"> </slot>
        </template>

        <!--

        <tr v-for="(items, index) of tableData" :key="index">
          <td v-for="item of items" :key="item">
            <div v-if="Array.isArray(item)">
              <div class="btn-group">
                <base-button v-for="button of item" :key="button" type="primary"><i :class="button"></i></base-button>
              </div>
            </div>
            <h5 v-else>
              {{ item.value }}
            </h5>
          </td>
        </tr>-->

        <template v-slot:data v-if="hasData">
          <slot name="data"></slot>
        </template>
      </base-table>
    </div>
    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <slot name="pagination"></slot>
    </div>
  </div>
</template>
<script>
import { uuid } from "vue-uuid";
export default {
  name: "projects-table",
  methods: {
    bindEvent(event, handler) {
      if (typeof handler === "function") handler.bind(this)(event);
    },
    async rebuildTable() {
      await this.$nextTick();
      this.keyTable = uuid.v4();
    },
  },
  props: {
    actions: {
      default: () => [],
      type: Array,
    },
    hasInlineRoute: {
      type: Boolean,
      default: false,
      description: "Table should insert click event on each row of table",
    },
    tableData: {
      type: Array,
    },
    tableColumns: {
      type: Array,
    },
    type: {
      type: String,
    },
    remote: { type: Boolean, default: false },
    title: String,
  },
  computed: {
    hasData() {
      return "data" in this.$slots;
    },
    hasDefault() {
      return "default" in this.$slots;
    },
  },
  watch: {
    tableData(v) {
      if (v?.length) {
        this.rebuildTable();
      }
    },
  },
  data() {
    return {
      limit: 15,
      pagination: 1,
      keyTable: uuid.v4(),
    };
  },
};
</script>
<style scoped>
</style>
