<template>
  <modal
    v-model:show="showModal"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template v-slot:header>
        <h4 class="modal-title" id="exampleModalLabel">
          {{ isNew ? "CADASTRAR" : "EDITAR" }} CURSO
        </h4>
        <h6 class="text-muted">
          Informe abaixo os dados do novo curso a ser cadastrado
        </h6>
      </template>

      <div class="text-left mb-2">
        <h5>Dados:</h5>
      </div>
      <form role="form">
        <div class="row">
          <div class="col col-6">
            <div class="form-group row mb-2">
              <div class="col-4">
                <label class="col-form-label form-control-label"
                  >Instituição de Ensino:</label
                >
              </div>
              <div class="col col-8">
                <el-select
                  noData="Nenhuma instituição cadastrada"
                  type="secondary"
                  :items="Schools"
                  value-key="uid"
                  placeholder="Selecione a instituição de ensino"
                  v-model="course_.school"
                >
                  <el-option
                    v-for="s in Schools"
                    :key="s.uid"
                    :label="s.initials"
                    :value="s"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <!--Nível-->
            <div class="form-group row mb-2">
              <div class="col-3">
                <label class="col-form-label form-control-label">Nível:</label>
              </div>
              <div class="col col-9">
                <base-dropdown
                  type="secondary"
                  noData="Nenhum nível cadstrado"
                  hint="Selecione o nível do curso"
                  :items="EducationalLevels"
                  v-model="course_.level"
                  menuClasses="col-12"
                >
                </base-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <!--NOME-->
            <div class="form-group row mb-2">
              <label class="col-2 col-form-label form-control-label"
                >Nome:</label
              >
              <div class="col-10">
                <base-input
                  v-model="course_.name"
                  formClasses="input-group-alternative"
                  required
                  placeholder="Nome completo do curso"
                ></base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <!--DESCRIÇÂO-->
            <div class="form-group row mb-2">
              <label class="col-2 col-form-label form-control-label"
                >Descrição:</label
              >
              <div class="col-10">
                <base-input
                  v-model="course_.description"
                  formClasses="input-group-alternative"
                  required
                  placeholder="Informação adicional do curso"
                ></base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 col-sm-12">
            <!--C/H-->
            <div class="row mb-2">
              <label class="col-4 col-form-label form-control-label"
                >C/H:</label
              >
              <div class="col-8">
                <base-input
                  formClasses="input-group-alternative"
                  required
                  mask="#*"
                  v-model="course_.workload"
                  placeholder="em horas/aula"
                ></base-input>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-sm-12">
            <!--DURAÇÃO-->
            <div class="form-group row mb-2">
              <label class="col-5 col-form-label form-control-label"
                >Duração:</label
              >
              <div class="col-7">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="course_.duration"
                  mask="#*"
                  required
                  placeholder="em meses"
                ></base-input>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-sm-12">
            <!--DURAÇÃO-->
            <div class="form-group row mb-2">
              <label class="col-5 col-form-label form-control-label"
                >Valor Padrão:</label
              >
              <div class="col-7">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="course_.cost"
                  type="money"
                  required
                  placeholder="em meses"
                ></base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="dropdown-divider"></div>
        <div class="text-left mb-2">
          <h5>Duração:</h5>
        </div>

        <div class="row">
          <div class="col-md-6 col-sm-12">
            <base-checkbox v-model="course_.hasPeriods" inline class="mb-3">
              Este curso possui períodos
            </base-checkbox>
          </div>
          <div class="col-md-6 col-sm-12">
            <!--Quantidade de Períodos-->
            <div class="row mb-2">
              <label class="col-4 col-form-label form-control-label"
                >Períodos:</label
              >
              <div class="col-6">
                <base-input
                  formClasses="input-group-alternative"
                  :disabled="!course_.hasPeriods"
                  v-model="course_.periods"
                  placeholder="período/bimestre/semestre"
                ></base-input>
              </div>
            </div>
          </div>
        </div>

        <base-collapse title="Disciplinas">
          <div>
            <template v-if="!course_.hasPeriods">
              <div class="list-group">
                <h5 class="text-muted mx-auto">
                  Para cadastrar disciplinas, selecione
                  <b>Este curso possui períodos</b>
                </h5>
              </div>
            </template>
            <template v-else-if="!course_.periods || course_.periods < 1">
              <div class="list-group">
                <h5 class="text-muted mx-auto">
                  Informe a quantidade de períodos do curso
                </h5>
              </div>
            </template>
            <template
              v-else
              v-for="p in parseInt(course_?.periods || 0)"
              :key="p"
            >
              <div class="row pl-3 justify-content-between">
                <div class="text-left mb-2">
                  <h5>{{ p }}º Período:</h5>
                </div>
                <base-button
                  outline
                  size="sm"
                  type="secondary"
                  @click="addDiscipline(p)"
                  >adicionar</base-button
                >
              </div>

              <template v-for="d of disciplinesByPeriod(p)" :key="d.id">
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <!--Nome da Disciplina-->
                    <div class="row mb-2">
                      <label class="col-4 col-form-label form-control-label"
                        >Nome:</label
                      >
                      <div class="col-8">
                        <base-input
                          formClasses="input-group-alternative"
                          v-model="course_.disciplines[d.id].name"
                          placeholder="Introdução ao Cálculo"
                        ></base-input>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 col-sm-12">
                    <!--C/H da disciplina-->
                    <div class="row mb-2">
                      <label class="col-4 col-form-label form-control-label"
                        >C/H:</label
                      >
                      <div class="col-8">
                        <base-input
                          formClasses="input-group-alternative"
                          v-model="course_.disciplines[d.id].workload"
                          mask="#*"
                          placeholder="60"
                        ></base-input>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-1 col-sm-12 d-flex align-items-center float-right"
                  >
                    <base-button
                      iconOnly
                      outline
                      rounded
                      class="btn btn-outlined"
                      link
                      size="sm"
                      type="secondary"
                      @click="removeDiscipline(d.id)"
                      ><i class="fa fa-trash" style="color: red"></i
                    ></base-button>
                  </div>
                </div>
              </template>

              <template v-if="disciplinesByPeriod(p).length == 0">
                <div class="list-group">
                  <h5 class="text-muted mx-auto">
                    clique em adicionar para inserir uma disciplina
                  </h5>
                </div>
              </template>
            </template>
          </div>
        </base-collapse>
      </form>
      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button
            size="sm"
            @click="showModal = false"
            class="float-right"
            type="danger"
            >CANCELAR</base-button
          >
          <base-button
            size="sm"
            @click="isNew ? createCourse() : updateCourse()"
            class="float-right"
            :isLoading="isLoadingSubmit"
            type="primary"
            >SALVAR</base-button
          >
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
import { ElNotification, ElSelect, ElOption } from "element-plus";
import BaseButton from "../../components/BaseButton.vue";
import BaseCollapse from "../../components/BaseCollapse.vue";
export default {
  components: { BaseButton, BaseCollapse, ElSelect, ElOption },
  name: "add-course",
  props: {
    shouldShow: {
      type: Boolean,
    },
    course: {
      type: Object,
      default: () => ({}),
      description: "course object when modal is opened by edit button",
    },
    isNew: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    showModal: {
      get: function () {
        return this.shouldShow;
      },
      set: function () {
        this.$emit("close-modal", false);
      },
    },
    SelectedSchool() {
      return this?.$store?.state?.user?.selectedSchool;
    },
    CourseDisciplines() {
      return (this.course_.disciplines || []).map((e, i) => ({ ...e, id: i }));
    },
    Schools() {
      return this.$store.state.user.schools;
    },
    EducationalLevels() {
      return this.$store.state.constants.educationalLevels.map((e) => e.label);
    },
    CourseLevel() {
      return this?.course_?.level || {};
    },
    SafeSchool() {
      return this?.course_?.school_id || {};
    },
  },
  data() {
    return {
      course_: {
        ...this.course,
        hasPeriods: this.course.periods ? true : false,
      },
      isLoadingSubmit: false,
      selectedPeriod: 1,
      selectedLevel: this.course.level,
      selectedSchool: this.SelectedSchool,
    };
  },
  watch: {
    course() {
      this.course_ = {
        ...this.course,
        hasPeriods: this.course.periods ? true : false,
      };
    },
    SelectedSchool() {
      this.course_.school = this.SelectedSchool;
    },
  },
  methods: {
    updateCourse() {
      this.isLoadingSubmit = true;
      const uid = this.course.uid;
      const course = this.course_;
      course.school_id = course?.school_id?.uid;

      fetch(this.$store.state.apiUrl + "courses/" + uid, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...this.course_,
          school: { uid: this.course_?.school?.uid },
        }),
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(() => {
          this.showModal = false;
          ElNotification.success({
            title: "Curso alterado com sucesso",
          });
        })
        .catch(async (error) => {
          error.then((e) =>
            ElNotification.error({
              title: "Não foi possível alterar o curso",
              message: e.message,
            })
          );
        })
        .finally(() => {
          this.isLoadingSubmit = false;
          this.$emit("reload-data");
        });
    },
    createCourse() {
      this.isLoadingSubmit = true;
      this.course_.school_id = this.course_?.school?.uid;

      fetch(this.$store.state.apiUrl + "courses", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...this.course_,
          school: { uid: this.course_?.school?.uid },
        }),
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(() => {
          this.showModal = false;
          ElNotification.success({
            title: "Curso cadastrado com sucesso",
          });
        })
        .catch(async (error) => {
          error.then((e) =>
            ElNotification.error({
              title: "Não foi possível cadastrar o curso",
              message: e.message,
            })
          );
        })
        .finally(() => {
          this.isLoadingSubmit = false;
          this.$emit("reload-data");
        });
    },
    selectSchool(v) {
      this.selectedSchool = v.name;
      this.course_.school_id = v.uid;
    },
    removeDiscipline: function (id) {
      console.log("removing id", id);
      if (Array.isArray(this.course_.disciplines))
        this.course_.disciplines.splice(id, 1);
    },
    selectLevel(v) {
      this.course_.level = v.value;
      this.selectedLevel = v.label;
    },
    addDiscipline: function (period) {
      if (!Array.isArray(this.course_.disciplines))
        this.course_.disciplines = [];

      this.course_.disciplines.push({
        period,
      });
    },
    disciplinesByPeriod(period) {
      return this.CourseDisciplines.filter((e) => e.period == period);
    },
  },
};
</script>
<style scoped>
.el-select {
  display: block;
}
a.dropdown-item {
  cursor: pointer;
}
</style>
