import http from "./http";
export default (disciplineId) => ({
	async index(filters) {
		return http
			.index("disciplines", filters)
			.then((json) => ({ disciplines: json }))
			.catch((e) => ({ disciplines: null, error: e }));
	},
	async get() {
		return http
			.get("disciplines", disciplineId)
			.then((json) => ({ discipline: json }))
			.catch((e) => ({ discipline: null, error: e }));
	},
	async create(data) {
		return http
			.post("disciplines", data)
			.then((json) => ({ discipline: json }))
			.catch((e) => ({ discipline: null, error: e }));
	},
	async update(data) {
		return http
			.put("disciplines", disciplineId, data)
			.then((json) => ({ discipline: json }))
			.catch((e) => ({ discipline: null, error: e }));
	},
	async delete() {
		return http
			.delete("disciplines", disciplineId)
			.then((json) => ({ discipline: json }))
			.catch((e) => ({ discipline: null, error: e }));
	},
});
