<template>
  <modal
    @update:show="showModal = $event"
    :show="showModal"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template v-slot:header>
        <h4 class="modal-title" id="exampleModalLabel">
          {{ isNewExam ? "AGENDAR PROVA" : "EDITAR AGENDAMENTO" }}
        </h4>
        <h6 class="text-muted">Um evento será criado na agenda do aluno</h6>
      </template>

      <form role="form">
        <div class="row">
          <div class="col col-12">
            <div class="form-group row mb-2">
              <div class="col-4">
                <label class="col-form-label form-control-label"
                  >Data da Aplicação:</label
                >
              </div>
              <div class="col col-8">
                <DatePicker
                  v-model="exam_.schedule"
                  timezone="UTC"
                  mode="datetime"
                  :popover="{ visibility: 'focus' }"
                  :minute-increment="30"
                  is24hr
                  :min-date="isExpired(exam) ? null : exam?.starts_in"
                  :max-date="isExpired(exam) ? null : exam?.expires_in"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <base-input
                      formClasses="input-group-alternative"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="25/02/2021"
                    ></base-input>
                  </template>
                </DatePicker>
              </div>
            </div>
          </div>
        </div>

        <div class="dropdown-divider"></div>

        <div class="row justify-content-between">
          <div class="col-md-6 col-sm-12">
            <div class="text-left mb-2">
              <label class="col-form-label form-control-label"
                >Aplicação da Prova:</label
              >
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="row justify-content-end mx-auto">
              <h5 class="mr-3">Remota</h5>
              <base-switch
                v-model="exam_.application_type"
                inline
                class="mb-3 pl-3"
              >
              </base-switch>
              <h5 class="ml-3">Presencial</h5>
            </div>
          </div>
        </div>
        <!--DESCRIÇÃO-->
        <div class="form-group row mb-2">
          <label
            class="
              col-lg-4 col-md-6 col-sm-12 col-form-label
              form-control-label
            "
            >Observação:</label
          >
          <div class="col-lg-8 col-md-6 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              required
              placeholder="Descrição breve da observação"
            >
              <textarea
                v-model="exam_.obs"
                :disabled="exam?.schedule"
                class="form-control"
                rows="4"
                maxlength="999"
              ></textarea>
            </base-input>
          </div>
        </div>

        <!-- USUÁRIO -->
        <div class="form-group row mb-2">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <label class="col-form-label form-control-label">Aplicador:</label>
          </div>
          <div class="col-lg-8 col-md-6">
            <el-select
              type="secondary"
              clearable
              value-key="value"
              placeholder="Selecione o usuário que deverá aplicar a prova"
              v-model="exam_.user_id"
            >
              <el-option
                v-for="s in users"
                :key="s.uid"
                :label="s.name"
                :value="s.uid"
              ></el-option>
            </el-select>
          </div>
        </div>
      </form>
      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button
            size="sm"
            @click="showModal = false"
            class="float-right"
            type="danger"
            >CANCELAR</base-button
          >
          <base-button
            size="sm"
            @click="save"
            :isLoading="isLoadingSubmit"
            class="float-right"
            type="primary"
            >SALVAR</base-button
          >
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
import BaseButton from "../../components/BaseButton";
import BaseSwitch from "../../components/BaseSwitch";
import { DatePicker } from "v-calendar";
import { ElNotification, ElSelect, ElOption } from "element-plus";
export default {
  components: { BaseButton, DatePicker, BaseSwitch, ElSelect, ElOption },
  name: "event-modal",
  props: ["shouldShow", "exam", "student"],
  computed: {
    showModal: {
      get() {
        return this.shouldShow;
      },
      set() {
        this.$emit("close-modal", false);
      },
    },
    isNewExam() {
      return !this.exam?.schedule;
    },
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    save() {
      this.exam_.is_realized = this.exam_.is_approved = false;
      this.updateExam();
    },
    isExpired(exam) {
      if (exam)
        return !exam.is_realized && new Date(exam.expires_in) < new Date();
      return false;
    },
    fetchUsers() {
      fetch(`${this.$store.state.apiUrl}users`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          (json) => (this.users = json),
          () => (this.hasError = true)
        )
        .finally(() => (this.isLoading = false));
    },
    updateExam() {
      fetch(`${this.$store.state.apiUrl}exams/${this.exam_.uid}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...this.exam_,
          student: { uid: this.exam_.student.uid },
        }),
      })
        .then(async (response) => {
          if (response.status == 200) {
            this.errorMessages = {};
            this.$emit("reload-data", true);
            this.$emit("close-modal", true);

            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
            }),
          (e) =>
            ElNotification.error({
              message: e.message,
            })
        )
        .finally(() => (this.isLoadingSubmit = false));
    },
  },
  watch: {
    exam(e) {
      this.exam_ = { ...(e || {}) };
      if (e?.user) {
        this.exam_.user_id = e?.user?.uid;
      }
    },
  },
  data() {
    return {
      users: [],
      exam_: { ...(this.exam || {}) },
      isLoadingSubmit: false,
    };
  },
};
</script>
