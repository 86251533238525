<template>
  <el-tooltip
    class="item"
    effect="light"
    v-if="tooltip"
    :content="tooltip"
    placement="top-start"
  >
    <component
      :is="tag"
      :type="tag === 'button' ? nativeType : ''"
      v-on:click="handleClick"
      class="btn"
      :disabled="isLoading"
      :class="classes"
    >
      <span
        class="btn-inner--icon"
        v-if="!isLoading && ($slots.icon || (icon && $slots.default))"
      >
        <slot name="icon">
          <i :class="icon + ' min-width'"></i>
        </slot>
      </span>
      <i v-if="!$slots.default" :class="icon + ' min-width'"></i>
      <span
        class="btn-inner--text"
        v-if="$slots.icon || (icon && $slots.default)"
      >
        <slot>
          {{ text }}
        </slot>
      </span>
      <slot v-if="!isLoading && !$slots.icon && !icon"></slot>
      <base-loader v-if="isLoading" type="button"></base-loader>
    </component>
  </el-tooltip>
  <component
    :is="tag"
    v-else
    :type="tag === 'button' ? nativeType : ''"
    v-on:click="handleClick"
    class="btn"
    :disabled="isLoading"
    :class="classes"
  >
    <span
      class="btn-inner--icon"
      v-if="!isLoading && ($slots.icon || (icon && $slots.default))"
    >
      <slot name="icon">
        <i style="min-width: 15px" :class="icon + ' min-width'"></i>
      </slot>
    </span>
    <i v-if="!$slots.default" :class="icon + ' min-width'"></i>
    <span
      class="btn-inner--text"
      v-if="$slots.icon || (icon && $slots.default)"
    >
      <slot>
        {{ text }}
      </slot>
    </span>
    <slot v-if="!isLoading && !$slots.icon && !icon"></slot>
    <base-loader v-if="isLoading" type="button"></base-loader>
  </component>
</template>
<script>
import BaseLoader from "./BaseLoader.vue";
import { ElTooltip } from "element-plus";
export default {
  name: "base-button",
  components: { BaseLoader, ElTooltip },
  emits: ["click"],
  props: {
    tag: {
      type: String,
      default: "button",
      description: "Button tag (default -> button)",
    },
    type: {
      type: String,
      default: "default",
      description: "Button type (e,g primary, danger etc)",
    },
    size: {
      type: String,
      default: "",
      description: "Button size lg|sm",
    },
    textColor: {
      type: String,
      default: "",
      description: "Button text color (e.g primary, danger etc)",
    },
    nativeType: {
      type: String,
      default: "button",
      description: "Button native type (e.g submit,button etc)",
    },
    icon: {
      type: String,
      default: "",
      description: "Button icon",
    },
    text: {
      type: String,
      default: "",
      description: "Button text in case not provided via default slot",
    },
    outline: {
      type: Boolean,
      default: false,
      description: "Whether button style is outline",
    },
    rounded: {
      type: Boolean,
      default: false,
      description: "Whether button style is rounded",
    },
    iconOnly: {
      type: Boolean,
      default: false,
      description: "Whether button contains only an icon",
    },
    block: {
      type: Boolean,
      default: false,
      description: "Whether button is of block type",
    },
    isLoading: {
      type: Boolean,
      default: false,
      description: "Should show loading button",
    },
    tooltip: {
      type: String,
      description: "Tooltip to be showed when user hover button",
    },
  },
  computed: {
    classes() {
      let btnClasses = [
        { "btn-block": this.block },
        { "rounded-circle": this.rounded },
        { "btn-icon-only": this.iconOnly },
        { [`text-${this.textColor}`]: this.textColor },
        { "btn-icon": this.icon || this.$slots.icon },
        this.type && !this.outline ? `btn-${this.type}` : "",
        this.outline ? `btn-outline-${this.type}` : "",
      ];
      if (this.size) {
        btnClasses.push(`btn-${this.size}`);
      }
      return btnClasses;
    },
  },
  methods: {
    handleClick(evt) {
      evt.stopImmediatePropagation();
      this.$emit("click", evt);
    },
  },
};
</script>
<style>
.min-width {
  min-width: 10px;
}
</style>
