<template>
  <div class="row justify-content-between mb-4 pl-4 pr-5 pt-1">
    <h3 v-if="date">
      {{ CurrentMonthInAgenda }}
    </h3>
    <div class="row">
      <base-button
        type="primary"
        size="sm"
        @click="changeMonth(-1)"
        icon="ni ni-bold-left"
      ></base-button>
      <base-button type="primary" size="sm" @click="calendar?.today()"
        >hoje</base-button
      >
      <base-button
        type="primary"
        size="sm"
        :disabled="!student?.events?.length"
        @click="calendar?.today()"
        >próxima prova</base-button
      >
      <base-button
        type="primary"
        size="sm"
        :disabled="!student?.events?.length"
        @click="calendar?.today()"
        >fim do período</base-button
      >
      <base-button
        type="primary"
        size="sm"
        :disabled="!student?.events?.length"
        @click="calendar?.today()"
        >fim do curso</base-button
      >
      <base-button
        type="primary"
        size="sm"
        @click="changeMonth(1)"
        icon="ni ni-bold-right"
      ></base-button>
    </div>
  </div>

  <base-calendar
    id="studentCalendar"
    :key="calendarKey"
    :options="calendarOptions"
    :events="StudentEvents"
    @get-calendar="calendar = $event"
  ></base-calendar>
  <event-modal
    @close-modal="showAddEventModal = false"
    @reload-data="emitShouldUpdate"
    :shouldShow="showAddEventModal"
    :event="event"
    :student="student"
  ></event-modal>
</template>
<script>
import BaseButton from "../../components/BaseButton";
import BaseCalendar from "../../components/BaseCalendar.vue";
import EventModal from "../Modals/EventModal.vue";
import { uuid } from "vue-uuid";

export default {
  name: "student-details-tab-agenda",
  props: ["student"],
  methods: {
    emitShouldUpdate() {
      this.$emit("should-update");
    },
    showEventModal(e) {
      this.event = { ...e };
      this.showAddEventModal = true;
    },
    rebuildCalendar() {
      this.calendarKey = uuid.v4();
    },
    changeMonth(m) {
      if (m > 0) this?.calendar?.next();
      else this?.calendar?.prev();

      this.date.setMonth(this.date.getMonth() + m);
      this.date = new Date(this.date);
    },
    showNextEvent(/*eventTitle*/) {
      //const nextEvent = this.student?.events?.filter((e) => !e);
    },
    showEventWithColors(eventCategory) {
      return this.eventColors[`${eventCategory}`] || this.eventColors["normal"];
    },
  },
  emits: ["should-update"],
  watch: {
    student() {
      this.rebuildCalendar();
    },
  },
  components: {
    BaseCalendar,
    BaseButton,
    EventModal,
  },
  data: function () {
    const _this = this;
    return {
      calendarKey: uuid.v4(),
      calendar: null,
      dateFormatter: new Intl.DateTimeFormat("pt-br", {
        month: "long",
        year: "numeric",
      }),
      showAddEventModal: false,
      event: null,
      date: new Date(),
      calendarOptions: {
        initialView: "dayGridMonth",
        selectable: true,
        headerToolbar: false,
        locale: "pt-br",
        dateClick(info) {
          _this.showEventModal({ day: info.date });
        },
        eventClick(info) {
          _this.showEventModal({ ...info.event.extendedProps.object });
        },
      },
      eventColors: {
        normal: "#0069c0",
        urgent: "#fb8c00",
        info: "#006978",
        problem: "#b91400",
      },
    };
  },
  computed: {
    CurrentMonthInAgenda() {
      return this.dateFormatter.format(this.date);
    },
    StudentEvents() {
      return (
        this.student?.events?.map((e) => ({
          title: e.label,
          id: e.uid,
          start: e.day,
          object: e,
          allDay: true,
          backgroundColor: this.showEventWithColors(e.category),
          editable: true,
        })) || []
      );
      /*return [
        {
          title: "Prova 1",
          start: new Date(y, m, 1),
          className: "event-default",
        },
        {
          id: 999,
          title: "Prova 2",
          start: new Date(y, m, d - 4, 6, 0),
          allDay: false,
          className: "event-green",
        },
      ];*/
    },
  },
};
</script>
