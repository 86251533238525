import http from "./http";
export default (enrolId) => ({
	async index(filters) {
		return http
			.index("enrols", filters)
			.then((json) => ({ enrols: json }))
			.catch((e) => ({ enrols: null, error: e }));
	},
	async get() {
		return http
			.get("enrols", enrolId)
			.then((json) => ({ enrol: json }))
			.catch((e) => ({ enrol: null, error: e }));
	},
	async create(data) {
		return http
			.post("enrols", data)
			.then((json) => ({ enrol: json }))
			.catch((e) => ({ enrol: null, error: e }));
	},
	async update(data) {
		return http
			.put("enrols", enrolId, data)
			.then((json) => ({ enrol: json }))
			.catch((e) => ({ enrol: null, error: e }));
	},
	async delete() {
		return http
			.delete("enrols", enrolId)
			.then((json) => ({ enrol: json }))
			.catch((e) => ({ enrol: null, error: e }));
	},
});
