<template>
  <modal
    v-model:show="showModal"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white p-3"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template #header>
        <h4 class="modal-title" id="exampleModalLabel">
          Renegociar Títulos da Matrícula
        </h4>
        <h6 class="text-muted">{{ enrol_?._course?.name || "" }}</h6>
      </template>

      <div>
        <div>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <div class="text-left mb-2">
                <label class="form-control-label">Renegociar Títulos:</label>
              </div>
            </div>
            <div class="col-lg-9 col-md-12">
              <el-select
                v-model="renegotiation.payments_to_be_closed"
                multiple
                clearable
                value-key="uid"
                no-data-text="Não existem títulos a renegociar"
                placeholder="Selecione os títulos que deseja renegociar"
              >
                <el-option
                  v-for="item in OnlyOpenedPayments"
                  :key="item.uid"
                  :label="`${item.order}: ${formatCurrency(item.cost)}`"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="dropdown-divider"></div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <!--Obs-->
              <div class="form-group row mb-2">
                <label
                  class="col-lg-6 col-md-12 col-sm-12 col-form-label form-control-label"
                  >Valor em Atraso:</label
                >
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <base-input
                    formClasses="input-group-alternative"
                    placeholder="R$ 59,95"
                    type="money"
                    v-model="renegotiation.cost"
                  ></base-input>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <!--Obs-->
              <div class="form-group row mb-2">
                <label
                  class="col-lg-6 col-md-12 col-sm-12 col-form-label form-control-label"
                  >Parcelamento:</label
                >
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <base-input
                    mask="#*"
                    formClasses="input-group-alternative"
                    placeholder="12 x"
                    v-model="renegotiation.total"
                  ></base-input>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <!--Obs-->
              <div class="form-group row mb-2">
                <label
                  class="col-lg-6 col-md-12 col-sm-12 col-form-label form-control-label"
                  >Forma de Pagamento:</label
                >
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <base-dropdown
                    type="secondary"
                    hint="Método de Parcelamento:"
                    v-model="renegotiation.payment_type"
                    :items="PaymentTypes"
                    menuClasses="col-12"
                  >
                  </base-dropdown>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <!--Obs-->
              <div class="form-group row mb-2">
                <label
                  class="col-lg-6 col-md-12 col-sm-12 col-form-label form-control-label"
                  >Primeiro Pagamento:</label
                >
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <DatePicker
                    v-model="renegotiation.starts_at"
                    timezone="UTC"
                    :model-config="{ timeAdjust: '00:00:00' }"
                    :popover="{ visibility: 'focus' }"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <base-input
                        formClasses="input-group-alternative"
                        :value="inputValue"
                        v-on="inputEvents"
                        placeholder="25/02/2021"
                      ></base-input>
                    </template>
                  </DatePicker>
                </div>
              </div>
            </div>
          </div>
          <!--Obs-->
          <div class="col-md-12 col-sm-12 p-0">
            <div class="row mb-2">
              <label class="col-md-2 col-sm-4 col-form-label form-control-label"
                >Comissão:</label
              >
              <div class="col-md-10 col-sm-8">
                <el-select
                  v-model="renegotiation.comission"
                  popper-append-to-body
                  clearable
                  remote
                  value-key="uid"
                  :loading="isLoadingFetchComissions"
                  @visible-change="fetchComissions"
                  class="input-group-alternative"
                  placeholder="Selecione a comissão"
                  no-data-text="Não existem comissões cadastradas"
                >
                  <div>
                    <el-option
                      v-for="item in FilteredComissions"
                      :key="item.uid"
                      :label="item.name"
                      :value="item"
                    >
                    </el-option>
                  </div>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button
            size="sm"
            @click="showModal = false"
            class="float-right"
            type="danger"
            >CANCELAR</base-button
          >
          <base-button
            size="sm"
            @click="save"
            :disabled="isLoadingSave"
            :isLoading="isLoadingSave"
            class="float-right"
            type="primary"
            >SALVAR</base-button
          >
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
import { DatePicker } from "v-calendar";
import { ElSelect, ElOption, ElNotification } from "element-plus";
import moment from "moment";

export default {
  name: "renegotiate-enrol-modal",
  props: ["shouldShow", "enrol", "payments"],
  components: { DatePicker, ElSelect, ElOption },
  data() {
    return {
      isLoading: false,
      isLoadingFetchComissions: false,
      enrol_: this.enrol || { _course: {} },
      comissions: [],
      isLoadingSave: false,
      renegotiation: {
        type: "Cartão",
      },
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
    };
  },
  watch: {
    enrol(v) {
      this.enrol_ = { ...(v || {}) };

      if (v?.comission) {
        this.renegotiation.comission = v.comission;
        this.comissions = [v.comission];
      }
    },
    showModal(v) {
      if (v) this.fetchComissions(true);
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      const queries = [];
      if (this.renegotiation?.payments_to_be_closed?.length) {
        for (let p of this.renegotiation.payments_to_be_closed) {
          queries.push(
            fetch(`${this.$store.state.apiUrl}payments/${p.uid}`, {
              method: "PUT",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({
                status: "closed",
              }),
            })
          );
        }
      }

      if (this.renegotiation?.total) {
        const expires_at_start = new Date(this.renegotiation.starts_at);
        let expires_at = expires_at_start;
        for (let i = 1; i <= this.renegotiation.total; i++) {
          queries.push(
            fetch(`${this.$store.state.apiUrl}payments`, {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({
                cost:
                  Number.parseFloat(`${this?.renegotiation?.cost}`) /
                  Number.parseInt(`${this?.renegotiation?.total}`),
                comission: this.renegotiation.comission,
                order: i,
                total: this?.renegotiation?.total,
                type: this?.renegotiation?.payment_type,
                category: "renegotiation",
                expires_at,
                student_id: this.$route.params.id,
                enrol_id: this.enrol_.uid,
              }),
            })
          );
          expires_at = new Date(
            moment(expires_at_start).add(i, "M").format("")
          );
        }
      }

      Promise.all(queries)
        .then(
          () => {
            ElNotification.success({
              message: "Títulos renegociados com sucesso!",
            });
            this.$emit("close-modal");
            this.$emit("reload-data");
          },
          (e) => ElNotification.error({ message: e.message })
        )
        .finally(() => (this.isLoadingSave = false));
    },
    formatCurrency(currency) {
      const n = Number.parseFloat(`${currency}`);
      if (!n?.isNaN) {
        return this.currencyFormatter.format(currency);
      }

      return "desconhecido";
    },
    fetchComissions(v) {
      if (v) {
        this.isLoadingFetchComissions = true;
        fetch(this.$store.state.apiUrl + "comissions", {
          credentials: "include",
          method: "GET",
        })
          .then((response) => {
            if (response.status == 200) return response.json();
            else throw response.json();
          })
          .then((json) => (this.comissions = json))
          .catch((e) => console.log(e))
          .finally(() => (this.isLoadingFetchComissions = false));
      }
    },
  },
  computed: {
    PaymentTypes() {
      return this?.$store?.state?.constants?.paymentTypes || [];
    },
    OnlyOpenedPayments() {
      return this?.payments?.filter((e) => e.status === "opened") || [];
    },
    FilteredComissions() {
      return (
        this?.comissions?.filter(
          (c) => c?.school?.uid === this.enrol?.school?.uid && c.is_active
        ) || []
      );
    },
    showModal: {
      get() {
        return this.shouldShow;
      },
      set(v) {
        this.$emit("close-modal", v);
      },
    },
  },
};
</script>
