<template>
  <modal
    v-model:show="showModal"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template v-slot:header>
        <h4 class="modal-title" id="exampleModalLabel">
          {{ isNewStudent ? "CADASTRAR ALUNO" : "EDITAR ALUNO" }}
        </h4>
        <h6 class="text-muted">
          {{
            isNewStudent
              ? "Informe abaixo os dados do aluno a ser cadastrado"
              : "Preencha os dados que deseja alterar"
          }}
        </h6>
      </template>

      <div class="text-left mb-2">
        <h5>Dados:</h5>
      </div>
      <form role="form" ref="InsertOrUpdateStudentForm">
        <div class="row">
          <div class="col-6">
            <!--CÓDIGO-->
            <div class="form-group row mb-2">
              <label
                class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                >Código:</label
              >
              <div class="col-lg-8 col-md-12 col-sm-12">
                <base-input
                  v-model="student.code"
                  v-on:blur="checkIfStudentExistsByCode"
                  name="code"
                  :error="Errors['code']"
                  formClasses="input-group-alternative"
                  required
                  mask="#*"
                  placeholder="1234"
                ></base-input>
              </div>
            </div>
          </div>

          <div class="col-6">
            <!--CPF-->
            <div class="form-group row mb-2">
              <label
                class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                >CPF:</label
              >
              <div class="col-lg-8 col-md-12 col-sm-12">
                <base-input
                  v-model="student.cpf"
                  v-on:blur="checkIfStudentExistsByCPF"
                  name="cpf"
                  :error="Errors['cpf']"
                  formClasses="input-group-alternative"
                  required
                  mask="###.###.###-##"
                  placeholder="555.555.555-66"
                ></base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <!--NOME-->
            <div class="form-group row mb-2">
              <label
                class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                >Nome:</label
              >
              <div class="col-lg-8 col-md-12 col-sm-12">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="student.name"
                  required
                  :error="Errors['name']"
                  placeholder="José Antônio de Oliveira"
                ></base-input>
              </div>
            </div>
          </div>

          <div class="col-6">
            <!--DATA NASCIMENTO-->
            <div class="form-group row mb-2">
              <label
                class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                >Data de Nascimento:</label
              >
              <div class="col-lg-8 col-md-12 col-sm-12">
                <date-picker
                  v-model="student.birthday"
                  :popover="{ visibility: 'focus' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <base-input
                      formClasses="input-group-alternative"
                      :value="inputValue"
                      :error="Errors['birthday']"
                      mask="##/##/####"
                      v-on="inputEvents"
                      placeholder="25/02/2021"
                    ></base-input>
                  </template>
                </date-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <!--RG-->
            <div class="form-group row mb-2">
              <label
                class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                >RG:</label
              >
              <div class="col-lg-8 col-md-12 col-sm-12">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="student.rg"
                  :error="Errors['rg']"
                  mask="##.###.###"
                  required
                  placeholder="00564931"
                ></base-input>
              </div>
            </div>
          </div>

          <div class="col-6">
            <!--EMISSOR RG-->
            <div class="form-group row mb-2">
              <label
                class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                >Emissor:</label
              >
              <div class="col-lg-8 col-md-12 col-sm-12">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="student.rg_emitter"
                  :error="Errors['rg_emitter']"
                  mask="A*/AA"
                  required
                  placeholder="SSP/MS"
                ></base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <!--DATA CADASTRO-->
            <div class="form-group row mb-2">
              <label
                class="col-lg-2 col-md-12 col-sm-12 col-form-label form-control-label"
                >Data de Cadastro:</label
              >
              <div class="col-lg-10 col-md-12 col-sm-12">
                <date-picker
                  v-model="student.created_at"
                  :popover="{ visibility: 'focus' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <base-input
                      formClasses="input-group-alternative"
                      :value="inputValue"
                      :error="Errors['birthday']"
                      mask="##/##/####"
                      v-on="inputEvents"
                      placeholder="25/02/2021"
                    ></base-input>
                  </template>
                </date-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="dropdown-divider"></div>

        <base-collapse title="Contato:">
          <div>
            <div class="row">
              <div class="col-6">
                <!--EMAIL-->
                <div class="form-group row mb-2">
                  <label
                    class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                    >Email:</label
                  >
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <base-input
                      formClasses="input-group-alternative"
                      :error="Errors['email']"
                      v-model="student.email"
                      type="email"
                      required
                      placeholder="email@exemplo.com"
                    ></base-input>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <!--CELULAR-->
                <div class="form-group row mb-2">
                  <label
                    class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                    >Celular:</label
                  >
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <base-input
                      formClasses="input-group-alternative"
                      v-model="student.contact.phone"
                      :error="Errors['phone']"
                      required
                      mask="!(##!) # ####!-####*"
                      placeholder="(99) 99999 - 9999"
                    ></base-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <!--EMAIL-->
                <div class="form-group row mb-2">
                  <label
                    class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                    >Contato alternativo:</label
                  >
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <base-input
                      formClasses="input-group-alternative"
                      v-model="student.alternative_contact_name"
                      required
                      placeholder="Adicione um contato alternativo"
                    ></base-input>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <!--CELULAR-->
                <div class="form-group row mb-2">
                  <label
                    class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                    >Celular:</label
                  >
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <base-input
                      formClasses="input-group-alternative"
                      v-model="student.alternative_contact_phone"
                      required
                      mask="!(##!) # ####!-####*"
                      placeholder="(99) 99999 - 9999"
                    ></base-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </base-collapse>
        <base-collapse title="Endereço:">
          <div>
            <div class="row">
              <div class="col-6">
                <!--RUA-->
                <div class="form-group row mb-2">
                  <label
                    class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                    >Rua:</label
                  >
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <base-input
                      formClasses="input-group-alternative"
                      v-model="student.addr.street"
                      :error="Errors['addr.street']"
                      placeholder="Rua da Conceição"
                    ></base-input>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <!--NUMERO-->
                <div class="form-group row mb-2">
                  <label
                    class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                    >Nº:</label
                  >
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <base-input
                      formClasses="input-group-alternative"
                      mask="#*"
                      :error="Errors['addr.number']"
                      v-model="student.addr.number"
                      placeholder="1542"
                    ></base-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <!--BAIRRO-->
                <div class="form-group row mb-2">
                  <label
                    class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                    >Bairro:</label
                  >
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <base-input
                      formClasses="input-group-alternative"
                      v-model="student.addr.place"
                      :error="Errors['addr.place']"
                      placeholder="São Leopoldo"
                    ></base-input>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <!--CEP-->
                <div class="form-group row mb-2">
                  <label
                    class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                    >CEP:</label
                  >
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <base-input
                      formClasses="input-group-alternative"
                      mask="##.###-###"
                      :error="Errors['addr.postal_code']"
                      v-model="student.addr.postal_code"
                      placeholder="99.999-000"
                    ></base-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <!--CIDADE-->
                <div class="form-group row mb-2">
                  <label
                    class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                    >Cidade:</label
                  >
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <base-input
                      formClasses="input-group-alternative"
                      :error="Errors['addr.city']"
                      v-model="student.addr.city"
                      placeholder="Rio de Janeiro"
                    ></base-input>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <!--ESTADO-->
                <div class="form-group row mb-2">
                  <label
                    class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                    >Estado:</label
                  >
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <base-dropdown
                      type="secondary"
                      hint="Estado:"
                      :error="Errors['addr.state']"
                      v-model="student.addr.state"
                      :items="statesOfBrazil"
                      menuClasses="col-12"
                    >
                    </base-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <!--Obs-->
                <div class="form-group row mb-2">
                  <label
                    class="col-lg-3 col-md-12 col-sm-12 col-form-label form-control-label"
                    >Complemento:</label
                  >
                  <div class="col-lg-9 col-md-12 col-sm-12">
                    <base-input
                      :error="Errors['addr.complement']"
                      formClasses="input-group-alternative"
                      v-model="student.addr.complement"
                      placeholder="Sobrado amarelo"
                    ></base-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </base-collapse>

        <base-collapse title="Amigo Indicação:">
          <div>
            <div class="row">
              <div class="col-6">
                <!--EMAIL-->
                <div class="form-group row mb-2">
                  <label
                    class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                    >Nome:</label
                  >
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <base-input
                      formClasses="input-group-alternative"
                      v-model="student.friends_name"
                      required
                    ></base-input>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <!--CELULAR-->
                <div class="form-group row mb-2">
                  <label
                    class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                    >Celular:</label
                  >
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <base-input
                      formClasses="input-group-alternative"
                      v-model="student.friends_phone"
                      required
                      mask="!(##!) # ####!-####*"
                      placeholder="(99) 99999 - 9999"
                    ></base-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </base-collapse>
      </form>
      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button
            size="sm"
            @click="cancel"
            class="float-right"
            type="danger"
            >CANCELAR</base-button
          >
          <el-popover
            width="350px"
            placement="bottom"
            :title="
              hasErrors
                ? 'Corrija os erros para submeter'
                : 'Não foram encontrados erros'
            "
            :content="
              hasErrors
                ? Object.values(Errors)[0]
                : 'Botão disponível para enviar'
            "
            trigger="hover"
          >
            <template #reference>
              <base-button
                size="sm"
                @click="
                  hasErrors
                    ? null
                    : isNewStudent
                    ? createStudent()
                    : updateStudent()
                "
                class="float-right"
                :isLoading="isLoadingSubmit"
                type="primary"
                >SALVAR</base-button
              ></template
            ></el-popover
          >
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
import BaseButton from "../../components/BaseButton.vue";
import BaseCollapse from "../../components/BaseCollapse.vue";
import { DatePicker } from "v-calendar";
import { ElNotification, ElPopover } from "element-plus";
export default {
  components: { BaseButton, BaseCollapse, DatePicker, ElPopover },
  name: "add-student",
  props: {
    shouldShow: {
      default: false,
      type: Boolean,
    },
    value: {
      type: Object,
      default: () => ({}),
      description:
        "Student to be updated, dont pass objects if you wanted to register a new student",
    },
  },
  watch: {
    value(v) {
      this.student = {
        ...v,
        addr: v?.addr || {},
        contact: v?.contact || {},
      };
      this.errorMessages = {};
    },
  },
  computed: {
    isNewStudent() {
      return !this.value;
    },
    SelectedSchool() {
      return this?.$store?.state?.user?.selectedSchool;
    },
    showModal: {
      get: function () {
        return this.shouldShow;
      },
      set: function () {
        this.$emit("close-modal", false);
      },
    },
    statesOfBrazil: function () {
      return this.$store.state.constants.states;
    },
    Errors() {
      return this.errorMessages || {};
    },
    hasErrors() {
      return !!Object.keys(this.Errors)?.length;
    },
    submitButtonTrigger() {
      return this.hasErrors ? "hover" : "manual";
    },
  },
  methods: {
    cancel() {
      this.showModal = false;
    },
    checkIfStudentExistsByCPF() {
      if (this.student.cpf && this.isNewStudent) {
        this.fetchStudentByField(this.student.cpf, "cpf").then((json) => {
          if (json && "uid" in json)
            return (this.errorMessages["cpf"] = "Este CPF já está cadastrado");

          delete this.errorMessages["cpf"];
        });
      }
    },
    checkIfStudentExistsByCode() {
      if (this.student.code && this.isNewStudent) {
        this.fetchStudentByField(this.student.code, "code").then((json) => {
          if (json && "uid" in json)
            return (this.errorMessages["code"] =
              "Este código já está cadastrado");

          delete this.errorMessages["code"];
        });
      }
    },
    fetchStudentByField(value, field) {
      const url = new URL(
        `${this.$store.state.apiUrl}students/${value}/${field}`
      );
      const params = {
        school_uid: this.SelectedSchool?.uid,
      };
      url.search = new URLSearchParams(params);
      return fetch(url, {
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((r) => r.json());
    },
    updateStudent() {
      this.isLoadingSubmit = true;

      fetch(`${this.$store.state.apiUrl}students/${this.student.uid}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(this.student),
      })
        .then(async (response) => {
          if (response.status == 200) {
            this.errorMessages = {};
            this.$emit("close-modal", true);
            this.$emit("reload-data");
            return response.json();
          }
          throw await response.json();
        })
        .catch((e) => {
          ElNotification.error({
            message: e.message,
          });
        })
        .finally(() => (this.isLoadingSubmit = false));
    },
    createStudent() {
      if (!this.SelectedSchool)
        return ElNotification.error({
          message:
            "Você deve selecionar uma instituição no topo da página antes de cadastrar o aluno.",
        });

      this.isLoadingSubmit = true;

      fetch(`${this.$store.state.apiUrl}students`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...this.student,
          school_uid: this.SelectedSchool.uid,
        }),
      })
        .then(async (response) => {
          if (response.status == 200) return response.json();

          throw await response.json();
        })
        .then((e) => {
          ElNotification.success({
            message: e.message,
          });
          this.$emit("close-modal");
          this.$emit("reload-data");
          this.$router.push(`/alunos/${e.student.uid}`);
        })
        .catch((e) => {
          ElNotification.error({
            message: e.message,
          });
        })
        .finally(() => (this.isLoadingSubmit = false));
    },
  },
  data() {
    return {
      isLoadingSubmit: false,
      errorMessages: {},
      student: {
        ...this.value,
        addr: this?.value?.addr || {},
        contact: this?.value?.contact || {},
      },
      educationalItems: ["UNIFAVENI", "UniBTA", "UniABC", "UniBZC"],
      educationalLevels: [
        "Educação infantil;",
        "Ensino médio",
        "Graduação",
        "2º Licenciatura",
        "Técnico",
        "Mestrado",
        "Doutorado",
      ],
    };
  },
};
</script>
