<template>
  <modal
    :show="showModal"
    @update:show="showModal = $event"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white p-3"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template #header>
        <h4 class="modal-title" id="exampleModalLabel">
          {{ enrol?._course?.name }}
        </h4>
      </template>

      <div>
        <base-loader v-if="isLoading"></base-loader>
        <div v-else>
          <div class="row justify-content-between px-3 py-2">
            <div class="text-left mb-2">
              <label class="form-control-label">Estado da Matrícula:</label>
            </div>
            <h5>{{ enrol?.status_formatted }}</h5>
          </div>
          <div class="row justify-content-between px-3 py-2">
            <div class="text-left mb-2">
              <label class="form-control-label">Períodos:</label>
            </div>
            <h5>{{ enrol?.periods_formatted }}</h5>
          </div>
          <div class="row justify-content-between px-3 py-2">
            <div class="text-left mb-2">
              <label class="form-control-label">Carga Horária:</label>
            </div>
            <h5>{{ enrol?._course?.workload }} horas</h5>
          </div>
          <div class="row justify-content-between px-3 py-2">
            <div class="text-left mb-2">
              <label class="form-control-label">Nível do Curso:</label>
            </div>
            <h5>{{ enrol?._course?.level }}</h5>
          </div>
          <div class="dropdown-divider"></div>

          <!--Timeline-->
          <el-timeline>
            <el-timeline-item
              v-for="a in ActivitiesOrderedByTimestamp"
              :key="a"
              :type="a.type"
              :timestamp="
                a.timestamp ? dateFormatter.format(new Date(a.timestamp)) : '-'
              "
              placement="top"
            >
              <el-card v-if="a.description">
                <h4>{{ a.title }}</h4>
                <p>{{ a.description }}</p>
              </el-card>
              <h4 v-else>{{ a.title }}</h4>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <template v-slot:footer>
        <div class="row justify-content-end">
          <base-button
            size="sm"
            @click="showModal = false"
            class="float-right"
            type="secondary"
            >FECHAR</base-button
          >
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
import BaseLoader from "../../components/BaseLoader.vue";
//import { DatePicker } from "v-calendar";
import { ElTimeline, ElTimelineItem, ElCard } from "element-plus";

export default {
  name: "enrol-details-modal",
  props: ["shouldShow", "enrol_uid"],
  components: { BaseLoader, ElTimeline, ElTimelineItem, ElCard },
  data() {
    return {
      isLoading: true,
      enrol: null,
      isLoadingSubmit: false,
      dateFormatter: new Intl.DateTimeFormat("pt-BR"),
    };
  },
  watch: {
    enrol_uid() {
      this.isLoading = true;
      this.fetchEnrol();
    },
  },
  computed: {
    showModal: {
      get() {
        return this.shouldShow;
      },
      set(v) {
        this.$emit("close-modal", v);
      },
    },
    Activities() {
      return [
        ...this.EnrolCreationActivities,
        ...this.EnrolPeriodsActivities,
        ...this.EnrolPaymentsActivities,
        ...this.EnrolExamActivities,
      ];
    },
    ActivitiesOrderedByTimestamp() {
      return [...this.Activities].sort((a, b) => a.timestamp - b.timestamp);
    },
    EnrolExpiresAt() {
      const lastPeriod = this.enrol?.periods?.length
        ? this.enrol.periods.at(-1)
        : null;

      return lastPeriod?.expires_at || this.enrol?.expires_at;
    },
    EnrolCreationActivities() {
      const a = [];

      a.push({
        title: "Matricula cadastrada no SM Educação",
        type: "info",
        timestamp: new Date(this?.enrol?.created_at),
      });

      a.push({
        title: "Aluno matriculado",
        type: "info",
        timestamp: new Date(this?.enrol?.start_in),
      });

      if (this?.enrol?.status in { locked: true, canceled: true })
        a.push({
          title:
            this?.enrol?.status != "locked"
              ? "O curso foi cancelado"
              : "O curso foi trancado",
          type: "warning",
          timestamp: new Date(this?.enrol?.updated_at),
        });
      else
        a.push({
          title:
            this?.enrol?.status != "finalized"
              ? "Prazo para conclusão do curso"
              : "O aluno finalizou o curso",
          type: "info",
          timestamp: new Date(this.EnrolExpiresAt),
        });
      return a;
    },
    EnrolPeriodsActivities() {
      if (!this?.enrol?.periods) return [];
      const a = [];

      this.enrol.periods.forEach((p) => {
        a.push({
          title: `Início do ${p.order}º período`,
          type: "info",
          description: `${this.enrol.disciplines
            .filter((d) => d.period === p.order)
            .map((d) => `${d.name}\n`)}`,
          timestamp: new Date(p.starts_at),
        });

        if (p.status === "completed")
          a.push({
            title: `Fim do ${p.order}º período`,
            type: "success",
            description: `${this.enrol.disciplines
              .filter((d) => d.period === p.order && d.status === "completed")
              .map((d) => `${d.name}\n`)}`,
            timestamp: new Date(p.expires_at),
          });
      });

      return a;
    },
    EnrolExamActivities() {
      const a = [];
      if (this.enrol.exams) {
        this.enrol.exams.forEach((e) => {
          a.push({
            title: `Entrou no período de provas`,
            type: "info",
            timestamp: new Date(e.starts_in),
          });

          a.push({
            title: `Saiu do período de provas`,
            type: "info",
            timestamp: new Date(e.expires_in),
          });

          if (e.schedule && e.is_realized)
            a.push({
              title: `Realizou a prova agendada`,
              type: e.is_approved ? "success" : "danger",
              timestamp: new Date(e.schedule),
            });
        });
      }
      return a;
    },
    EnrolPaymentsActivities() {
      const a = [];
      if (this?.enrol?.payments) {
        const pr = this.enrol.payments
          .filter(filterByRenegotiated)
          .sort(sortByCreatedAt);

        if (pr?.length)
          a.push({
            title: `Renegociou o pagamento de títulos`,
            type: "warning",
            timestamp: new Date(pr[0].created_at),
          });

        if (
          hasNoMoreDebits(this.enrol.payments) &&
          this.enrol.payments.length
        ) {
          a.push({
            title: `Quitou o curso`,
            type: "success",
            timestamp: new Date(lastPaidAt(this.enrol.payments)),
          });
        }

        if (hasDebits(this.enrol.payments)) {
          a.push({
            title: `Mensalidades atrasadas`,
            type: "danger",
            timestamp: new Date(firstExpiredPayment(this.enrol.payments)),
          });
        }
      }
      return a;
    },
  },
  methods: {
    fetchEnrol() {
      fetch(this.$store.state.apiUrl + "enrols/" + this?.enrol_uid, {
        credentials: "include",
        //mode: "no-cors",
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => {
          if (response.status == 200) return response.json();
          else throw response.json();
        })
        .then((json) => (this.enrol = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};

const filterByRenegotiated = (p) => p.status === "RN";
const sortByCreatedAt = (a, b) =>
  new Date(a.created_at) - new Date(b.created_at);
const hasNoMoreDebits = (ps = []) => ps?.every((p) => p.status != "opened");
const lastPaidAt = (ps = []) =>
  Math.max(...ps?.map((p) => new Date(p.paid_at)));
const hasDebits = (ps = []) => ps.some((p) => p.payment_status === "unpaid");
const firstExpiredPayment = (ps = []) =>
  Math.min(
    ...ps
      .filter((p) => p.payment_status === "unpaid")
      .map((p) => new Date(p.expires_at))
  );
</script>
