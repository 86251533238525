<template>
  <div>
    <dashboard-navbar searchHint="Busque qualquer coisa..."></dashboard-navbar>

    <div class="container-fluid d-flex h-100 flex-column pt-6 px-2">
      <card
        type="secondary"
        shadow
        header-classes="bg-white pb-1"
        body-classes="px-lg-1 py-lg-1"
        class="border-0 mt-4"
        footerClasses="p-0"
      >
        <template #header>
          <div class="row justify-content-between px-2">
            <h4>{{ $route?.name }}</h4>
          </div>
        </template>
        <div class="row p-2">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <card
              shadow
              headerClasses="px-2 py-2"
              footerClasses="pl-2 pr-3 py-2"
            >
              <template #header><h5>Email:</h5> </template>
              <template #default>
                <base-input v-model="user_.email"></base-input>
              </template>
              <template #footer>
                <div class="row justify-content-end mr-1">
                  <base-button type="primary" size="sm" @click="updateUser()"
                    >Salvar</base-button
                  >
                </div>
              </template>
            </card>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <card
              shadow
              headerClasses="px-2 py-2"
              footerClasses="pl-2 pr-3 py-2"
            >
              <template #header><h5>Senha:</h5> </template>
              <template #default>
                <div class="row justify-content-between ml-0 pl-0 mr-0">
                  <base-input
                    label="Nova senha"
                    type="password"
                    hint="Nova senha"
                    v-model="user_.password"
                  ></base-input>
                  <base-input
                    label="Confirme a senha"
                    type="password"
                    hint="Confirme a senha"
                    v-model="user_.password_"
                  ></base-input>
                </div>
              </template>
              <template #footer>
                <div class="row justify-content-end mr-1">
                  <base-button
                    :disabled="user_?.password != user_?.password_"
                    type="primary"
                    size="sm"
                    @click="updateUser({ password: User.password })"
                    >Salvar</base-button
                  >
                </div>
              </template>
            </card>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Card from "../components/Card.vue";
import DashboardNavbar from "../layout/DashboardNavbar.vue";
import "vue-loaders/dist/vue-loaders.css";
import BaseButton from "../components/BaseButton.vue";
import BaseInput from "../components/BaseInput.vue";
import { ElMessageBox } from "element-plus";
import { notifySuccess, notifyError } from "../utils/notifiers";
import UserService from "../services/users";
// @ is an alias to /src

export default {
  name: "Roles",
  components: {
    DashboardNavbar,
    Card,
    BaseButton,
    BaseInput,
  },
  data() {
    return {
      hasError: false,
      user_: { ...this.$store.state.user },
    };
  },
  computed: {
    User() {
      return this.$store?.state?.user;
    },
  },
  methods: {
    async promptUser(promptMessage) {
      return new Promise((resolve) => {
        ElMessageBox.prompt(promptMessage, {
          confirmButtonText: "Continuar",
          cancelButtonText: "Cancelar",
          inputType: "password",
        }).then(
          (v) => resolve(v?.value),
          () => resolve(null)
        );
      });
    },
    async updateUser() {
      const passwordConfirmation = await this.promptUser(
        "Digite sua senha para confirmar as alterações"
      );

      if (passwordConfirmation) {
        const { user } = await UserService(this.User.uid).update({
          ...this.user_,
          password_confirmation: passwordConfirmation,
        });

        if (user) notifySuccess("Usuário atualizado com sucesso");
        else notifyError("Erro ao atualizar usuário");
      }
    },
  },
};
</script>
