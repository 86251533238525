<template>
  <div>
    <dashboard-navbar
      searchHint="Buscar curso pelo nome"
      @update-search="updateSearch"
    ></dashboard-navbar>

    <div class="container-fluid d-flex h-100 flex-column pt-6 px-2">
      <base-loader v-if="isLoading"></base-loader>
      <projects-table
        v-else
        :tableData="courses"
        type="hover"
        :actions="NavActions"
        title="Lista de Cursos"
        @showModal="showAddCourseModal = $event"
      >
        <template #actions>
          <base-button
            type="primary"
            :icon="a.icon"
            size="sm"
            v-for="a in NavActions"
            :key="a"
            v-on:click.stop.prevent="a.action"
          ></base-button>
          <base-button
            v-if="$store.state.user.canPreview"
            type="primary"
            icon="fa fa-upload"
            size="sm"
            v-on:click.stop.prevent="() => this.openUploadDataModal()"
          ></base-button>
        </template>
        <template #columns>
          <th
            v-for="col in TableColumns"
            :key="col"
            :style="`width: ${col.width};`"
          >
            {{ col.label }}
            <span
              class="caret-wrapper"
              v-if="col?.sortable"
              @click="sortColumn(col)"
            >
              <i
                :class="{
                  'fa fa-sort-up ascending sort-icon': true,
                  'is-sorted': sortOptions[col.prop] === 'asc',
                }"
              ></i>
              <i
                :class="{
                  'fa fa-sort-down descending sort-icon': true,
                  'is-sorted': sortOptions[col.prop] === 'desc',
                }"
              ></i>
            </span>
          </th>
        </template>

        <template #data>
          <tr v-for="(c, id) in courses" :key="id">
            <td style="width: 350px">
              {{ c.name }}
            </td>
            <td>{{ c.workload }} horas</td>

            <td>
              {{ c.periods }}
            </td>

            <td>
              {{ c.__meta__.enrols_count }}
            </td>

            <td>
              <div class="btn-group float-right">
                <base-button
                  size="sm"
                  type="primary"
                  @click.stop="openEditCourse(c)"
                  ><i class="fa fa-pencil"></i
                ></base-button>
                <el-popconfirm
                  title="Criar uma cópia do curso?"
                  confirm-button-type="info"
                  confirm-button-text="Criar cópia"
                  @confirm="copyCourse(c)"
                  cancel-button-text="Cancelar"
                >
                  <template #reference>
                    <base-button
                      type="primary"
                      iconOnly
                      size="sm"
                      icon="fa fa-copy"
                    ></base-button>
                  </template>
                </el-popconfirm>
                <el-popconfirm
                  title="Tem certeza?"
                  confirm-button-type="warning"
                  confirm-button-text="Sim"
                  @confirm="
                    updateCourse({ uid: c.uid, is_active: !c.is_active })
                  "
                  cancel-button-text="Cancelar"
                >
                  <template #reference>
                    <base-button
                      :type="!c.is_active ? 'danger' : 'success'"
                      iconOnly
                      size="sm"
                      :icon="`fa fa-${!c.is_active ? 'lock' : 'lock-open'}`"
                    ></base-button>
                  </template>
                </el-popconfirm>
                <el-popconfirm
                  title="Tem certeza? Matríulas e Pagamentos também serão removidos"
                  confirm-button-type="danger"
                  confirm-button-text="Deletar Tudo!"
                  @confirm="deleteCourse(c)"
                  cancel-button-text="Cancelar"
                >
                  <template #reference>
                    <base-button
                      type="danger"
                      iconOnly
                      size="sm"
                      icon="fa fa-trash"
                    ></base-button>
                  </template>
                </el-popconfirm>
              </div>
            </td>
          </tr>
        </template>

        <template #pagination>
          <base-pagination
            @input="pagination = $event"
            :value="pagination"
            :perPage="limit"
            :total="total"
          ></base-pagination>
        </template>
      </projects-table>
    </div>

    <add-course
      @close-modal="showAddCourseModal = false"
      @reload-data="fetchCourses"
      :shouldShow="showAddCourseModal"
      :isNew="newCourse"
      :course="course"
    ></add-course>
    <filter-courses
      @close-modal="showFilterCoursesModal = false"
      @update-filters="updateFilters"
      :shouldShow="showFilterCoursesModal"
      :isNew="newCourse"
      :course="course"
    ></filter-courses>
    <upload-data-modal
      @close-modal="showUploadDataModal = false"
      uploadMessage="Você está enviando dados para cursos"
      csvRules="integration_id,name,workload"
      :upload-method="uploadMethod"
      :shouldShow="showUploadDataModal"
      @should-update="() => fetchCourses()"
    ></upload-data-modal>
  </div>
</template>

<script>
//import Card from "../components/Card.vue";
import DashboardNavbar from "../layout/DashboardNavbar.vue";
import ProjectsTable from "../views/Tables/ProjectsTable.vue";
import "vue-loaders/dist/vue-loaders.css";
import BaseLoader from "../components/BaseLoader.vue";
import AddCourse from "./Modals/AddCourseModal.vue";
import FilterCourses from "./Modals/FilterCourses.vue";
import UploadDataModal from "./Modals/UploadDataModal.vue";
import { loadCourses } from "../utils/integrations";
import { ElNotification, ElPopconfirm } from "element-plus";
import CourseService from "../services/courses";
// @ is an alias to /src

export default {
  name: "Course",
  components: {
    DashboardNavbar,
    ProjectsTable,
    BaseLoader,
    AddCourse,
    ElPopconfirm,
    UploadDataModal,
    FilterCourses,
  },
  data() {
    return {
      isLoading: true,
      showAddCourseModal: false,
      showUploadDataModal: false,
      showFilterCoursesModal: false,
      uploadMethod: loadCourses,
      pagination: 1,
      newCourse: false,
      limit: 15,
      total: 0,
      searchQuery: "",
      course: { disciplines: [] },
      courses: [],
      sortOptions: {
        name: "asc",
      },
    };
  },
  created() {
    this.fetchCourses();
  },
  watch: {
    SelectedSchool() {
      this.pagination = 1;
      this.searchQuery = "";
      this.fetchCourses();
    },
    pagination() {
      this.fetchCourses();
    },
  },
  computed: {
    SelectedSchool() {
      return this?.$store?.state?.user?.selectedSchool;
    },
    NavActions() {
      return [
        {
          icon: "fa fa-plus-square",
          action: this.showAddCourse,
        },
        {
          icon: "fa fa-filter",
          action: () => {
            this.openFilterCoursesModal();
          },
        },
        {
          icon: "fa fa-download",
          action: () => {
            console.log("download clicked");
          },
        },
      ];
    },

    TableColumns: () => [
      {
        label: "nome",
        sortable: true,
        width: "200px",
        prop: "name",
      },
      {
        label: "carga horária",
        sortable: true,
        prop: "workload",
      },
      {
        label: "períodos",
        sortable: true,
        prop: "periods",
      },
      {
        label: "matriculas",
        sortable: false,
        prop: "__meta__.enrols_count",
      },
      {
        label: "ações",
        sortable: false,
      },
    ],
  },
  methods: {
    openFilterCoursesModal() {
      this.showFilterCoursesModal = true;
    },
    openUploadDataModal() {
      this.showUploadDataModal = true;
    },
    async sortColumn(v) {
      if (this.sortOptions[v.prop]) {
        if (this.sortOptions[v.prop] != "asc") this.sortOptions[v.prop] = "asc";
        else delete this.sortOptions[v.prop];
      } else this.sortOptions[v.prop] = "desc";

      this.fetchCourses();
    },
    async copyCourse(originCourse) {
      const { course, error } = await CourseService().create({
        ...originCourse,
        name: originCourse.name + " 2",
        school_id: this.SelectedSchool?.uid,
      });

      if (course)
        ElNotification.success({
          title: "Curso copiado com sucesso",
        });
      else
        ElNotification.error({
          title: "Não foi possível criar a cópia do curso",
          message: error?.message || "",
        });

      this.fetchCourses();
    },
    async updateCourse(_) {
      const { course } = await CourseService(_.uid).update(_);

      if (course) {
        ElNotification.success("Curso atualizado com sucesso");
        this.fetchCourses();
      }
    },
    updateSearch(v) {
      this.searchQuery = v;
      this.pagination = 1;
      this.fetchCourses();
    },
    updateFilters(filters) {
      this.pagination = 1;
      this.fetchCourses(filters);
    },
    isEmpty(o) {
      for (let i in o) return false;
      return true;
    },
    navigateToRoute(items) {
      if (this.hasInlineRoute) this.$router.push(`/cursos/${items[0]}`);
    },
    hasValue(item, column) {
      return item[column] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    openEditCourse(item) {
      this.course = item;
      this.newCourse = false;
      this.showAddCourseModal = true;
    },
    async deleteCourse(v) {
      this.isLoadingSubmit = true;

      if (v?.uid) {
        const { course, error } = await CourseService(v.uid).delete();

        if (course)
          ElNotification.success({
            title: "Curso removido com sucesso",
          });
        else
          ElNotification.error({
            title: "Não foi possível remover o curso",
            message: error?.message,
          });
      }

      this.isLoadingSubmit = false;
      this.fetchCourses();
    },
    showAddCourse() {
      this.newCourse = true;
      this.course = {};
      this.showAddCourseModal = true;
    },
    async fetchCourses(filters) {
      this.isLoading = true;

      const { courses: response } = await this.fetchData(filters);

      const { data: courses, total } = response;

      if (courses) this.courses = courses;
      if (total) this.total = total;

      this.isLoading = false;
    },
    fetchData(filters = {}) {
      return CourseService().index({
        pagination: this.pagination || 1,
        limit: this.limit || 15,
        queryName: this.searchQuery || "",
        ordenation: JSON.stringify(this.sortOptions),
        school_uid: this.SelectedSchool?.uid,
        ...filters,
      });
    },
  },
};
</script>
