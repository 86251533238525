<template>
  <div>
    <dashboard-navbar searchHint="Buscar aluno por Nome, CPF e Código" @update-search="updateSearch"></dashboard-navbar>

    <div class="container-fluid d-flex h-100 flex-column pt-6 px-2">
      <base-loader v-if="isLoading && !students"></base-loader>
      <projects-table v-else hasInlineRoute type="hover" :tableData="students" title="Lista de Alunos"
        @showModal="showAddStudentModal = $event">
        <template #actions>
          <base-button type="primary" icon="fa fa-plus-square" size="sm"
            v-on:click.stop.prevent="() => this.showAddStudent(null)"></base-button>
          <base-button v-if="$store.state.user.canPreview" type="primary" icon="fa fa-refresh" size="sm"
            v-on:click.stop.prevent="() => this.openSyncPaymentsModal()"></base-button>
          <base-button v-if="$store.state.user.canPreview" type="primary" icon="fa fa-upload" size="sm"
            v-on:click.stop.prevent="() => this.openUploadDataModal()"></base-button>
          <base-button type="primary" icon="fa fa-filter" title="Filtrar" size="sm"
            v-on:click.stop.prevent="() => openFilterStudentsModal()"></base-button>
          <base-button type="primary" icon="fa fa-download" size="sm"
            v-on:click.stop.prevent="() => generatePDF()"></base-button>
        </template>

        <template #columns>
          <th v-for="col in TableColumns" :key="col">
            {{ col.label }}
            <span class="caret-wrapper" v-if="col?.sortable" @click="sortColumn(col)">
              <i :class="{
                'fa fa-sort-up ascending sort-icon': true,
                'is-sorted': sortOptions[col.prop] === 'asc',
              }"></i>
              <i :class="{
                'fa fa-sort-down descending sort-icon': true,
                'is-sorted': sortOptions[col.prop] === 'desc',
              }"></i>
            </span>
          </th>
        </template>

        <template #data v-if="!HasNoStudents">
          <tr v-for="(c, id) in students" :key="id">
            <td>
              {{ c.code }}
            </td>
            <td>
              {{ cpfFormatter.format(c.cpf) }}
            </td>
            <td>
              {{ c.name }}
            </td>
            <td>
              {{ c.contact?.phone ? phoneFormatter.format(c.contact?.phone) : '-' }}
            </td>
            <td>
              {{ c.friends_name }}
            </td>

            <td>
              {{ c.friends_phone }}
            </td>

            <td>
              {{ c.enrols.length }}
            </td>

            <td>
              <div class="btn-group float-right">
                <base-button type="primary" icon="fa fa-external-link-alt" size="sm"
                  @click="navigateToRoute(c)"></base-button>
                <base-button size="sm" type="primary" @click.stop="showAddStudent(c)"><i
                    class="fa fa-pencil"></i></base-button>
                <el-popconfirm title="Tem certeza? Matrículas e Pagamentos também serão removidos"
                  confirm-button-type="danger" confirm-button-text="Deletar Tudo!" @confirm="deleteStudent(c)"
                  cancel-button-text="Cancelar">
                  <template #reference>
                    <base-button type="danger" iconOnly size="sm" icon="fa fa-trash"></base-button>
                  </template>
                </el-popconfirm>
              </div>
            </td>
          </tr>
        </template>

        <template #pagination>
          <base-pagination @input="pagination = $event" :value="pagination" :perPage="limit"
            :total="total"></base-pagination>
        </template>
      </projects-table>
    </div>

    <add-student @close-modal="showAddStudentModal = false" :shouldShow="showAddStudentModal" :value="student"
      @reload-data="fetchData"></add-student>

    <filter-students-modal @close-modal="showFilterStudentsModal = false" :shouldShow="showFilterStudentsModal"
      :student="student" @update-filter="updateFilter($event)"></filter-students-modal>

    <sync-payments-modal @close-modal="showSyncPaymentsModal = false"
      :shouldShow="showSyncPaymentsModal"></sync-payments-modal>

    <upload-data-modal @close-modal="showUploadDataModal = false"
      csvRules="code,birthday,email,contact,rg,street,number,place,postal_code,city,state,obs"
      :shouldShow="showUploadDataModal" :upload-method="uploadMethod"
      @should-update="() => loadStudents()"></upload-data-modal>
  </div>
</template>

<script>
//import Card from "../components/Card.vue";
import "jspdf-autotable";
import jsPDF from "jspdf";
import DashboardNavbar from "../layout/DashboardNavbar.vue";
import ProjectsTable from "../views/Tables/ProjectsTable.vue";
import "vue-loaders/dist/vue-loaders.css";
import BaseLoader from "../components/BaseLoader.vue";
import AddStudent from "./Modals/AddStudentModal.vue";
import UploadDataModal from "./Modals/UploadDataModal.vue";
import FilterStudentsModal from "./Modals/FilterStudentsModal.vue";
import { loadStudents } from "../utils/integrations";
import SyncPaymentsModal from "./Modals/SyncPaymentsModal.vue";
import { ElNotification, ElPopconfirm } from "element-plus";
import { cpfFormatter, phoneFormatter, dateFormatter } from "../utils/formatters";
// @ is an alias to /src

export default {
  name: "Students",
  components: {
    DashboardNavbar,
    ProjectsTable,
    BaseLoader,
    AddStudent,
    ElPopconfirm,
    SyncPaymentsModal,
    FilterStudentsModal,
    UploadDataModal,
  },
  data() {
    return {
      isLoading: true,
      cpfFormatter,
      sortOptions: {
        name: "asc",
      },
      showAddStudentModal: false,
      showUploadDataModal: false,
      showAddEnrollModal: false,
      pagination: 1,
      showSyncPaymentsModal: false,
      filters: {},
      uploadMethod: loadStudents,
      student: null,
      showFilterStudentsModal: false,
      searchQuery: "",
      total: 0,
      limit: 15,
      jsPDF: new jsPDF("landscape"),
      students: null,
      dateFormatter,
      phoneFormatter,
    };
  },
  created() {
    this.loadStudents();
  },
  watch: {
    SelectedSchool() {
      this.pagination = 1;
      this.searchQuery = "";
      this.loadStudents();
    },
    pagination() {
      this.loadStudents();
    },
  },
  computed: {
    SelectedSchool() {
      return this?.$store?.state?.user?.selectedSchool;
    },
    MappedStudents() {
      return this?.students?.reduce((t, e) => t.set(e.uid, e), new Map());
    },
    HasNoStudents() {
      return (this?.students?.length || 0) === 0;
    },
    TableColumns: () => [
      {
        label: "código",
        sortable: true,
        prop: "code",
      },
      {
        label: "CPF",
        sortable: true,
        prop: "cpf",
      },
      {
        label: "nome",
        sortable: true,
        prop: "name",
      },
      {
        label: "telefone",
        sortable: true,
        prop: "phone",
      },
      {
        label: "amigo",
        sortable: true,
        prop: "friends_name",
      },
      {
        label: "telefone amigo",
        sortable: true,
        prop: "friends_phone",
      },
      {
        label: "matrículas",
        sortable: false,
      },
      {
        label: "ações",
        sortable: false,
      },
    ],
  },
  methods: {
    openUploadDataModal() {
      this.showUploadDataModal = true;
    },
    updateFilter(filters) {
      this.isLoading = true;
      this.filters = filters;
      this.fetchData()
        .then((response) => {
          if (response.status == 200) return response.json();
          else throw response.json();
        })
        .then((json) => {
          this.students = json.data;
          this.total = json.total;
        })
        .finally(() => (this.isLoading = false));
    },
    openSyncPaymentsModal() {
      this.showSyncPaymentsModal = true;
    },
    async generatePDF() {
      this.isLoadingGeneratePDF = true;
      ElNotification.info({
        title: "Gerando relatório",
        message: "Isso pode levar até 1 minuto",
      });

      const { data } = await this.fetchData().then((response) =>
        response.json()
      );

      const mappedRows = data.map((e) => [
        e?.code,
        e?.name,
        this.phoneFormatter.format(e?.contact?.phone),
        this.dateFormatter.format(new Date(e?.birthday)),
        e?.friends_name,
        e?.enrols?.length,
      ]);

      const columns = [
        "código",
        "nome",
        "telefone",
        "data nasc.",
        "amigo",
        "matriculas",
      ];
      this.jsPDF.autoTable({
        columns,
        body: mappedRows,
      });

      this.jsPDF.save(`Alunos.pdf`);
      this.isLoadingGeneratePDF = false;
    },
    sortColumn(v) {
      if (this.sortOptions[v.prop]) {
        if (this.sortOptions[v.prop] != "asc") this.sortOptions[v.prop] = "asc";
        else delete this.sortOptions[v.prop];
      } else this.sortOptions[v.prop] = "desc";

      this.fetchData()
        .then((response) => {
          if (response.status == 200) return response.json();
          else throw response.json();
        })
        .then((json) => {
          this.students = json.data;
          this.total = json.total;
        });
    },
    openFilterStudentsModal() {
      this.showFilterStudentsModal = true;
    },
    isEmpty(o) {
      for (let i in o) return false;
      return true;
    },
    updateSearch(v) {
      this.searchQuery = v;
      this.pagination = 1;
      this.fetchData()
        .then((response) => {
          if (response.status == 200) return response.json();
          else throw response.json();
        })
        .then((json) => {
          this.students = json.data;
          this.total = json.total;
        });
    },
    loadStudents() {
      this.isLoading = true;

      this.fetchData()
        .then(
          (response) => response.json(),
          (e) => console.log(e)
        )
        .then((json) => {
          this.students = json.data;
          this.total = json.total;
        })
        .then(() => (this.isLoading = false));
    },
    fetchData() {
      const url = new URL(this.$store.state.apiUrl + "students");
      const params = { school_uid: this.SelectedSchool?.uid, ...this.filters };
      params.pagination = this.pagination || 1;
      params.limit = this.limit || 15;
      params.queryName = this.searchQuery || "";
      params.ordenation = JSON.stringify(this.sortOptions);

      url.search = new URLSearchParams(params);
      return fetch(url, {
        credentials: "include",
        //mode: "no-cors",
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
    },
    navigateToRoute(items) {
      let route = this.$router.resolve(`/alunos/${items.uid}`);
      window.open(route.href, "_blank");
    },
    hasValue(item, column) {
      return item[column] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    showAddStudent(v) {
      this.showAddStudentModal = true;
      this.student = v;
    },
    createEnrol(item) {
      this.student = this.MappedStudents.get(item.uid);
      this.showAddEnrollModal = true;
    },
    deleteStudent(v) {
      fetch(`${this.$store.state.apiUrl}students/${v.uid}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(() => {
          this.showModal = false;
          this.updateSearch("");
          ElNotification.success({
            title: "Aluno removido com sucesso",
          });
        })
        .catch(async (error) => {
          error.then((e) =>
            ElNotification.error({
              title: "Não foi possível remover o aluno",
              message: e.message,
            })
          );
        })
        .finally(() => {
          this.fetchData();
        });
    },
  },
};
</script>
