<template >
  <div>
    <div :key="obsKey">
      <div class="row justify-content-end pr-4 pt-1">
        <base-button type="primary" size="sm" @click="addObs()"
          >adicionar</base-button
        >
      </div>
      <div class="row p-2" v-for="n in NumberOfRows" :key="n">
        <div
          class="col-lg-6 col-md-6 col-sm-12"
          v-for="a in getNextObservations(n, 2)"
          :key="a.uid"
        >
          <card shadow headerClasses="px-2 py-2" footerClasses="pl-2 pr-3 py-2">
            <template #header>
              <div class="col-12 p-0">
                <h4 class="mb-0">{{ a.title }}</h4>
                <small class="text-muted"
                  >Criado por {{ a?.createdBy?.name || "" }} em
                  {{ dateFormatter.format(new Date(a.created_at)) }}</small
                >
              </div>
            </template>
            <p class="card-text">{{ a.description }}</p>
            <template #footer>
              <div class="row justify-content-between">
                <div class="col-md-8 col-sm-12">
                  <el-tag
                    v-for="mention in a?.mentions"
                    :key="mention"
                    class="tags-color"
                    closable
                    color="#1565c0"
                    :disable-transitions="false"
                    @close="removeMention(a, mention)"
                  >
                    {{ showFirstName(mention?.user?.name || "") }}
                  </el-tag>
                  <el-autocomplete
                    class="input-new-tag"
                    size="mini"
                    v-if="!!userMentionsInObs[a.uid]"
                    value-key="name"
                    popper-class="expand-popper"
                    :fetch-suggestions="queryUsers"
                    placeholder="+ Usuário"
                    trigger-on-focus
                    @select="addMention($event, a)"
                  >
                  </el-autocomplete>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="showInputMention(a)"
                    >+ Usuário</el-button
                  >
                </div>
                <div class="row px-4">
                  <base-button
                    :type="a?.is_checked ? 'success' : 'secondary'"
                    rounded
                    iconOnly
                    @click="maskObsAsChecked(a)"
                    size="sm"
                    icon="fa fa-check"
                  ></base-button>
                  <base-button
                    type="primary"
                    rounded
                    iconOnly
                    @click="editObs(a)"
                    size="sm"
                    icon="fa fa-pencil"
                  ></base-button>
                  <el-popconfirm
                    title="Remover observação do aluno?"
                    confirm-button-text="Remover"
                    cancel-button-text="Cancelar"
                    @confirm="removeObs(a)"
                  >
                    <template #reference>
                      <base-button
                        type="danger"
                        rounded
                        iconOnly
                        size="sm"
                        icon="fa fa-trash"
                      ></base-button
                    ></template>
                  </el-popconfirm>
                </div>
              </div>
            </template>
          </card>
        </div>
      </div>

      <div v-if="!observations?.length" class="row">
        <h4 class="text-muted mx-auto">
          Não existem observações cadastradas para este aluno
        </h4>
      </div>
    </div>

    <add-student-observation-modal
      :shouldShow="showAddStudentObservationModal"
      @close-modal="showAddStudentObservationModal = false"
      @reload-data="emitShouldUpdate"
      :obs="obs"
    ></add-student-observation-modal>
  </div>
</template>
<script>
import BaseButton from "../../components/BaseButton.vue";
import AddStudentObservationModal from "../Modals/AddStudentObservationModal.vue";
import {
  ElTag,
  ElAutocomplete,
  ElButton,
  ElNotification,
  ElPopconfirm,
} from "element-plus";
import { uuid } from "vue-uuid";
//import ElPopover from "element-plus";

//import dayGridPlugin from "@fullcalendar/daygrid";
//import interactionPlugin from "@fullcalendar/interaction"
//import timeGridDay from "@fullcalendar/timegrid";

export default {
  name: "student-details-tab-obs",
  props: ["student"],
  components: {
    BaseButton,
    AddStudentObservationModal,
    ElTag,
    ElAutocomplete,
    ElButton,
    ElPopconfirm,
    //ElPopover,
    //BaseButton,
  },
  emits: ["should-update"],
  data() {
    return {
      showAddStudentObservationModal: false,
      id: 0,
      obs: {},
      obsKey: uuid.v4(),
      userMentionsInObs: {},
      dateFormatter: new Intl.DateTimeFormat("pt-BR", { dateStyle: "long" }),
      observations: this?.student?.observations || [],
    };
  },
  watch: {
    student(v) {
      this.observations = v?.observations || [];
    },
  },
  computed: {
    NumberOfRows() {
      return Math.ceil(this.observations.length / 2);
    },
  },
  methods: {
    maskObsAsChecked(obs) {
      obs.is_checked = true;
      this.updateObs(obs);
    },
    queryUsers(query, callback) {
      fetch(`${this.$store.state.apiUrl}users`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          (users) => callback(users),
          () => callback([])
        );
    },
    updateObs(obs) {
      fetch(`${this.$store.state.apiUrl}observations/${obs.uid}`, {
        credentials: "include",
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...obs,
        }),
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          () => {
            ElNotification.success({
              message: "Observação atualizada com sucesso",
            });
          },
          (e) => {
            ElNotification.errro({ message: e.message });
          }
        )
        .finally(() => this.emitShouldUpdate());
    },
    emitShouldUpdate() {
      this.$emit("should-update");
    },
    addMention(user, observation) {
      fetch(`${this.$store.state.apiUrl}observations/${observation.uid}`, {
        credentials: "include",
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          addUser: user,
        }),
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          () => {
            ElNotification.success({
              message: "Usuário mencionado com sucesso",
            });
          },
          (e) => {
            ElNotification.error({ message: e.message });
          }
        )
        .finally(() => this.emitShouldUpdate());
    },
    showFirstName(name) {
      return name.split(" ")[0] || "";
    },
    userAlreadyMentioned(user, mentions) {
      return mentions?.some((u) => u.uid === user.uid) || false;
    },
    removeObs(obs) {
      fetch(`${this.$store.state.apiUrl}observations/${obs.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          () => {
            ElNotification.success({
              message: "Observação removida com sucesso",
            });
          },
          (e) => {
            ElNotification.error({ message: e.message });
          }
        )
        .finally(() => this.emitShouldUpdate());
    },
    removeMention(observation, mention) {
      fetch(`${this.$store.state.apiUrl}observations/${observation.uid}`, {
        credentials: "include",
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          removeUser: mention.user,
        }),
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          () => {
            ElNotification.success({
              message: "Menção removida com sucesso",
            });
          },
          (e) => {
            ElNotification.error({ message: e.message });
          }
        )
        .finally(() => this.emitShouldUpdate());
    },
    showInputMention(observation) {
      this.userMentionsInObs[observation.uid] = true;
    },
    addObs() {
      this.obs = null;
      this.showAddStudentObservationModal = true;
    },
    editObs(observation) {
      this.obs = observation;
      this.showAddStudentObservationModal = true;
    },
    getNextObservations(line, columns) {
      return this.observations.slice(
        (line - 1) * columns,
        (line - 1) * columns + columns
      );
    },
    hasValue(value) {
      return value != undefined;
    },
    anotation(id) {
      if (this.hasValue(this.observations[id])) return this.observations[id];
    },
  },
};
</script>
<style>
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.tags-color {
  color: #ffffff !important;
}
.el-tag__close {
  color: #ffffff !important;
}
.input-new-tag {
  position: absolute !important;
  max-width: 90px;
  vertical-align: bottom;
  line-height: 32px !important;
}

.expand-popper {
  width: 250px;
}
.el-autocomplete-suggestion {
  width: 100% !important;
}
</style>