import http from "./http";
export default (userId) => ({
  async index(filters) {
    return http
      .index("users", filters)
      .then((json) => ({ users: json }))
      .catch((e) => ({ users: null, error: e }));
  },
  async get() {
    return http
      .get("users", userId)
      .then((json) => ({ user: json }))
      .catch((e) => ({ user: null, error: e }));
  },
  async create(data) {
    return http
      .post("users", data)
      .then((json) => ({ user: json }))
      .catch((e) => ({ user: null, error: e }));
  },
  async update(data) {
    return http
      .put("users", userId, data)
      .then((json) => ({ user: json }))
      .catch((e) => ({ user: null, error: e }));
  },
  async delete() {
    return http
      .delete("users", userId)
      .then((json) => ({ user: json }))
      .catch((e) => ({ user: null, error: e }));
  },
  Log: (logId) => ({
    async index(filters) {
      return http
        .index(`users/${userId}/logs`, filters)
        .then((json) => ({ logs: json }))
        .catch((e) => ({ logs: null, error: e }));
    },
    async get() {
      return http
        .get(`users/${userId}/logs`, logId)
        .then((json) => ({ log: json }))
        .catch((e) => ({ log: null, error: e }));
    },
    async create(data) {
      return http
        .post(`users/${userId}/logs`, data)
        .then((json) => ({ log: json }))
        .catch((e) => ({ log: null, error: e }));
    },
    async update(data) {
      return http
        .put(`users/${userId}/logs`, logId, data)
        .then((json) => ({ log: json }))
        .catch((e) => ({ log: null, error: e }));
    },
    async delete() {
      return http
        .delete(`users/${userId}/logs`, logId)
        .then((json) => ({ log: json }))
        .catch((e) => ({ log: null, error: e }));
    },
  }),
  Task: (taskId) => ({
    async index(filters) {
      return http
        .index(`users/${userId}/tasks`, filters)
        .then((json) => ({ tasks: json }))
        .catch((e) => ({ tasks: null, error: e }));
    },
    async get() {
      return http
        .get(`users/${userId}/tasks`, taskId)
        .then((json) => ({ task: json }))
        .catch((e) => ({ task: null, error: e }));
    },
    async create(data) {
      return http
        .post(`users/${userId}/tasks`, data)
        .then((json) => ({ task: json }))
        .catch((e) => ({ task: null, error: e }));
    },
    async update(data) {
      return http
        .put(`users/${userId}/tasks`, taskId, data)
        .then((json) => ({ task: json }))
        .catch((e) => ({ task: null, error: e }));
    },
    async delete() {
      return http
        .delete(`users/${userId}/tasks`, taskId)
        .then((json) => ({ task: json }))
        .catch((e) => ({ task: null, error: e }));
    },
  }),
});
