const currencyFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const integerFormatter = new Intl.NumberFormat("pt-BR", {
  style: "decimal",
  minimumFractionDigits: 0,
});

const numberFormatter = new Intl.NumberFormat("pt-BR", {
  minimumFractionDigits: 2,
  style: "decimal",
});

const weightFormatter = new Intl.NumberFormat("pt-BR", {
  minimumFractionDigits: 3,
  style: "decimal",
});

const cpfFormatter = {
  format(cpf) {
    let cpfFormatted = `${cpf}`;
    cpfFormatted = `${"0".repeat(
      11 - cpfFormatted.length
    )}${cpfFormatted}`;

    return cpfFormatted.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      "$1.$2.$3-$4"
    );
  },
}

const phoneFormatter = {
  format(phone) {
    if (phone) {
      return `${phone}`.replace(
        /(\d{0,2})(\d{0,1})(\d{4})(\d{4})/,
        "($1)$2 $3-$4"
      );
    } else return phone;
  },
}

const dateReverse = (dateString) => {
  const dateParts = dateString.split('/').reverse();

  return dateParts.join('-');
}

const dateFormatter = {
  format: (date) => {
    let dateModel;
    if (typeof date === "string") dateModel = new Date(date);
    else dateModel = date;

    if (!Number.isNaN(dateModel.getTime()))
      return new Intl.DateTimeFormat("pt-BR", { dateStyle: "short" }).format(
        dateModel
      );
    else return "";
  },
};

const dateFormatterLong = {
  format: (date) => {
    let dateModel;
    if (typeof date === "string") dateModel = new Date(date);
    else dateModel = date;

    if (!Number.isNaN(dateModel.getTime()))
      return new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "long",
        timeStyle: "short",
      }).format(dateModel);
    else return "";
  },
};

export {
  currencyFormatter,
  numberFormatter,
  weightFormatter,
  integerFormatter,
  dateFormatter,
  dateReverse,
  dateFormatterLong,
  cpfFormatter,
  phoneFormatter,
};
