<template>
  <div class="col col-12 d-none d-flex flex-column" v-if="type == 'expanded'">
    <div class="row flex-grow-1 justify-content-center min-vh-100">
      <div class="d-flex flex-column h-100 my-auto align-items-center">
        <vue-loaders
          class="d-flex justify-content-center"
          name="ball-scale-multiple"
          color="#5e72e4"
          scale="1"
        >
        </vue-loaders>
        <h5>carregando</h5>
      </div>
    </div>
  </div>
  <div v-else>
    <vue-loaders
      class="d-flex justify-content-center"
      name="ball-pulse"
      color="#ffffff"
      scale="0.5"
    >
    </vue-loaders>
  </div>
</template>
<script>
import { VueLoaders } from "vue-loaders";

export default {
  name: "base-loader",
  props: {
    type: {
      type: String,
      default: "expanded",
    },
  },
  components: { VueLoaders },
};
</script>
