import http from "./http";
export default (obsId) => ({
  async index(filters) {
    return http
      .index("observations", filters)
      .then((json) => ({ observations: json }))
      .catch((e) => ({ observations: null, error: e }));
  },
  async get() {
    return http
      .get("observations", obsId)
      .then((json) => ({ observation: json }))
      .catch((e) => ({ observation: null, error: e }));
  },
  async create(data) {
    return http
      .post("observations", data)
      .then((json) => ({ observation: json }))
      .catch((e) => ({ observation: null, error: e }));
  },
  async update(data) {
    return http
      .put("observations", obsId, data)
      .then((json) => ({ observation: json }))
      .catch((e) => ({ observation: null, error: e }));
  },
  async delete() {
    return http
      .delete("observations", obsId)
      .then((json) => ({ observation: json }))
      .catch((e) => ({ observation: null, error: e }));
  },
});
