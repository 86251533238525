<template>
  <!--<el-dropdown
    max-height="200px"
    placement="bottom"
    trigger="click"
    @click="toggleDropDown"
  >
    <slot name="selectedItem">
      
    </slot>
    <el-button @click="toggleDropDown" :type="type">
      {{ selectedItem || hint
      }}<i class="el-icon-arrow-down el-icon--right"></i>
    </el-button>

    <ul
      class="dropdown-menu"
      ref="menu"
      :class="[
        { 'dropdown-menu-right': position === 'right' },
        { show: isOpen },
        menuClasses,
      ]"
    >
      <slot v-if="!items" v-on:click="closeDropDown"></slot>
      <li
        v-else
        v-for="item in items"
        :key="item"
        @click.stop.prevent="selectItem(item)"
      >
        <a
          class="dropdown-item"
          href="#"
          :class="{ active: item === selectedItem }"
        >
          {{ item }}
        </a>
      </li>
    </ul>

    <template #dropdown>
      <ul
        class="dropdown-menu"
        ref="menu"
        :class="[{ show: isOpen }, menuClasses]" 
      >
        <div v-if="!items" @click="selectItem($event.target.dataset)">
          <slot></slot>
        </div>

        <el-dropdown-item
          v-for="item in items"
          :key="item"
          @click="selectItem(item)"
          >{{ item }}</el-dropdown-item
        >
      </ul>
    </template>
  </el-dropdown>-->
  <el-select
    :no-data-text="noData"
    :placeholder="hint"
    :disabled="disabled"
    :modelValue="SafeLabel(selectedItem)"
    @change="selectedItem = $event"
  >
    <el-option v-for="item in items" :key="item" :value="item">{{
      SafeLabel(item)
    }}</el-option>
  </el-select>
</template>
<script>
import { ElSelect, ElOption } from "element-plus";
export default {
  name: "base-dropdown",
  components: { ElSelect, ElOption },
  props: {
    direction: {
      type: String,
      default: "down",
    },
    /*title: {
      type: String,
      description: "Dropdown title",
    },*/
    icon: {
      type: String,
      description: "Icon for dropdown title",
    },
    position: {
      type: String,
      default: "bottom",
      description: "Position of dropdown menu (e.g right|left)",
    },
    menuClasses: {
      type: [String, Object],
      description: "Dropdown menu classes",
    },
    valueKey: {
      type: String,
    },
    labelKey: {
      type: String,
    },
    hideArrow: {
      type: Boolean,
      description: "Whether dropdown arrow should be hidden",
    },
    appendToBody: {
      type: Boolean,
      default: true,
      description: "Whether dropdown should be appended to document body",
    },
    type: {
      type: String,
      default: () => "default",
      description: "Type of button style",
    },
    tag: {
      type: String,
      default: "li",
      description: "Dropdown html tag (e.g div, li etc)",
    },
    items: {
      type: Array,
      description: "Items for be included on dropdown",
    },
    hint: {
      type: String,
      description: "hint to be showed when no item is selected",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Dropdown button must be disabled?",
    },
    modelValue: {
      description: "model used in v-model",
    },
    noData: {
      type: String,
      default: "Nenhum dado disponível",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    selectedItem: {
      get() {
        var uid;
        if (
          this.modelValue &&
          typeof this.modelValue === "object" &&
          "uid" in this.modelValue
        )
          uid = this.modelValue.uid;
        else uid = this.modelValue;
        return this.TrueValue(uid);
      },
      set(v) {
        this.$emit("update:modelValue", this.SafeValue(v));
      },
    },
  },
  methods: {
    SafeValue(v) {
      return this.valueKey && typeof v === "object" ? v[this.valueKey] : v;
    },
    SafeLabel(v) {
      return this.labelKey && typeof v === "object" ? v[this.labelKey] : v;
    },
    TrueValue(v) {
      if (this.valueKey)
        return this?.items?.filter((_) => _[this.valueKey] === v)[0];
      else return v;
    },
  },
};
</script>
<style>
.dropdown {
  list-style-type: none;
}

.dropdown .dropdown-toggle {
  cursor: pointer;
}
</style>
