<template>
  <nav
    class="
      navbar navbar-vertical
      fixed-left
      navbar-expand-md navbar-light
      bg-white
      hidden-scroll
    "
    id="sidenav-main"
  >
    <div class="container-fluid">
      <!--Toggler-->

      <router-link
        class="
          navbar-brand
          brand-img
          navbar-collapse
          expand
          show
          p-0
          hidden-before
        "
        to="/"
      >
        <img :src="logo" class="navbar-brand-img expand-img" alt="..." />
      </router-link>
      <navbar-toggle-button @click="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>

      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show hidden-before"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>

        <el-scrollbar
          :max-height="getAvailableHeight()"
          @scroll="showScroll"
          tag="ul"
          view-class="navbar-nav"
          ><slot name="links"> </slot
        ></el-scrollbar>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import { ElScrollbar } from "element-plus";

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
    ElScrollbar,
  },
  props: {
    logo: {
      type: String,
      default: "img/brand/logo.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    getAvailableHeight() {
      return `${innerHeight - 108}px`;
    },
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    showScroll(s) {
      console.log(`scrolling... ${s}`);
    },
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
.expand-img {
  max-height: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
  margin-left: auto;
  margin-right: auto;
}
.brand-img {
  line-height: 100px;
}
.navbar-nav {
  width: 100%;
  height: 100%;
}
.hidden-scroll {
  overflow: hidden !important;
}
.hidden-before:before {
  margin: 0px !important;
}
</style>
