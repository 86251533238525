import http from "./http";
export default (studentId) => ({
	async index(filters) {
		return http
			.index("students", filters)
			.then((json) => ({ students: json }))
			.catch((e) => ({ students: null, error: e }));
	},
	async get() {
		return http
			.get("students", studentId)
			.then((json) => ({ student: json }))
			.catch((e) => ({ student: null, error: e }));
	},
	async create(data) {
		return http
			.post("students", data)
			.then((json) => ({ student: json }))
			.catch((e) => ({ student: null, error: e }));
	},
	async update(data) {
		return http
			.put("students", studentId, data)
			.then((json) => ({ student: json }))
			.catch((e) => ({ student: null, error: e }));
	},
	async delete() {
		return http
			.delete("students", studentId)
			.then((json) => ({ student: json }))
			.catch((e) => ({ student: null, error: e }));
	},
});
