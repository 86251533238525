<template>
  <div class="table-responsive">
    <div class="row justify-content-end">
      <div class="col-md-6 col-sm-12">
        <div class="row mb-2 justify-content-end">
          <label class="col-form-label form-control-label"
            >Filtrar por curso:</label
          >
          <div class="col-md-7 col-sm-8">
            <el-select
              v-model="filterByCourseUid"
              clearable
              class="input-group-alternative"
              placeholder="Selecione o curso"
            >
              <el-option
                v-for="item in StudentCourses"
                :key="item"
                :label="item.name"
                :value="item.uid"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <base-table
      thead-classes="thead-light"
      tbody-classes="list"
      type="hover"
      :limit="limit"
      :offset="(pagination - 1) * limit"
    >
      <template v-slot:columns>
        <th v-for="col in StudentPaymentsColumns" :key="col">
          {{ col.label }}
          <span
            class="caret-wrapper"
            v-if="col?.sortable"
            @click="sortColumn(col)"
          >
            <i
              :class="{
                'fa fa-sort-up ascending sort-icon': true,
                'is-sorted': sortOptions[col.prop] === 'asc',
              }"
            ></i>
            <i
              :class="{
                'fa fa-sort-down descending sort-icon': true,
                'is-sorted': sortOptions[col.prop] === 'desc',
              }"
            ></i>
          </span>
        </th>
      </template>

      <template #data>
        <tr
          v-for="payment in LimitedRows"
          :key="payment.uid"
          :class="{
            paid: payment.status === 'paid',
            unpaid:
              payment.status != 'paid' &&
              new Date(payment.expires_at) < new Date(),
            closed: payment.status === 'closed',
          }"
        >
          <td>
            <h5>
              {{ payment?.enrol?._course?.name }}
            </h5>
          </td>

          <td>
            <h5>{{ payment?.order || 0 }} / {{ payment.total || 0 }}</h5>
          </td>

          <td>
            <h5>
              {{ payment?.type }}
            </h5>
          </td>

          <td>
            <h5>
              {{
                payment?.expires_at
                  ? new Date(payment?.expires_at).toLocaleDateString()
                  : ""
              }}
            </h5>
          </td>

          <td>
            <h5>
              {{
                payment?.paid_at
                  ? new Date(payment?.paid_at).toLocaleDateString()
                  : ""
              }}
            </h5>
          </td>

          <td>
            <h5>
              {{
                currencyFormatter.format(
                  payment?.status === "paid" && payment?.value
                    ? payment.value
                    : payment?.cost
                ) || ""
              }}
            </h5>
          </td>

          <td v-if="userCan('comissions.r')">
            <h5>
              {{
                Number.parseFloat(payment?.comission) != NaN
                  ? Number.parseFloat(payment?.comission) * 100
                  : ""
              }}
              %
            </h5>
          </td>

          <td>
            <div class="btn-group float-right">
              <base-button
                size="sm"
                type="primary"
                @click.stop="showPaymentDetails(payment)"
                ><i class="fa fa-info-circle"></i
              ></base-button>
              <base-button
                size="sm"
                type="primary"
                @click.stop="showEditPayment(payment)"
                ><i class="fa fa-pencil"></i
              ></base-button>
              <el-popconfirm
                cancel-button-text="Cancelar"
                confirm-button-text="Deletar"
                title="Remover título de pagamento?"
                @confirm="deletePayment(payment)"
              >
                <template #reference>
                  <base-button size="sm" type="danger"
                    ><i class="fa fa-trash"></i
                  ></base-button>
                </template>
              </el-popconfirm>
            </div>
          </td>
        </tr>
      </template>
    </base-table>
  </div>
  <div class="row justify-content-end pr-4">
    <base-pagination
      @input="pagination = $event"
      :value="pagination"
      :perPage="limit"
      :showTotalPagination="false"
      :total="NotClosedPayments?.length"
    ></base-pagination>
  </div>

  <payment-details-modal
    :shouldShow="showPaymentDetailsModal"
    :payment="payment"
    @close-modal="showPaymentDetailsModal = false"
  ></payment-details-modal>
  <edit-payment-modal
    :shouldShow="showEditPaymentModal"
    :payment="payment"
    @reload-data="updateData"
    @close-modal="showEditPaymentModal = false"
  ></edit-payment-modal>
</template>
<script>
//import BaseButton from "../../components/BaseButton.vue";

//import dayGridPlugin from "@fullcalendar/daygrid";
//import interactionPlugin from "@fullcalendar/interaction"
//import timeGridDay from "@fullcalendar/timegrid";
import PaymentDetailsModal from "../Modals/PaymentDetailsModal.vue";
import EditPaymentModal from "../Modals/EditPaymentModal.vue";
import { ElPopconfirm, ElNotification, ElSelect, ElOption } from "element-plus";

export default {
  name: "student-details-tab-finance",
  props: {
    payments: {
      type: Object,
      description: "List of payments belongs to student",
    },
    enrols: {
      type: Object,
      description: "List of enrols belongs to student",
    },
  },
  components: {
    EditPaymentModal,
    PaymentDetailsModal,
    ElPopconfirm,
    ElSelect,
    ElOption,
  },
  data() {
    return {
      pagination: 1,
      sortOptions: {},
      limit: 10,
      filterByCourseUid: "",
      showPaymentDetailsModal: false,
      showEditPaymentModal: false,
      payment: null,
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
    };
  },
  emits: ["should-update"],
  computed: {
    FilteredPayments() {
      return this.filterByCourseUid
        ? this.payments?.filter(
            (p) => p?.enrol?._course?.uid === this.filterByCourseUid
          )
        : this.payments;
    },
    NotClosedPayments() {
      return this.FilteredPayments.filter((p) => p.status != "closed") || [];
    },
    StudentCourses() {
      return this.enrols
        ?.filter((e) => e.status != "locked" && e.status != "canceled")
        .map((e) => e._course);
    },
    SortedRows() {
      let rows = this.NotClosedPayments;

      for (let p in this.sortOptions) {
        const o = this.sortOptions[p];

        if (o) {
          rows = rows.sort((a, b) => {
            const chainProperties = p.split(".");
            while (chainProperties.length > 0) {
              const k = chainProperties.shift();
              a = a[k];
              b = b[k];
            }
            if (o === "desc") return a > b ? -1 : 1;
            else return a > b ? 1 : -1;
          });
        }
      }

      return rows;
    },
    Offset() {
      return (this.pagination - 1) * this.limit;
    },
    LimitedRows() {
      return this.SortedRows.slice(this.Offset, this.Offset + this.limit);
    },
    StudentPaymentsColumns: function () {
      const tableHeaders = [
        {
          label: "curso",
          sortable: true,
          prop: "enrol._course.name",
        },
        {
          label: "parcela/total",
          sortable: true,
          prop: "order",
        },
        {
          label: "forma",
          sortable: false,
          prop: "order",
        },
        {
          label: "vencimento",
          sortable: true,
          prop: "expires_at",
        },
        {
          label: "pagamento",
          sortable: true,
          prop: "paid_at",
        },
        {
          label: "valor",
          sortable: true,
          prop: "cost",
        },
      ];

      if (this.userCan("comissions.r"))
        tableHeaders.push({
          label: "repasse",
          sortable: true,
          prop: "comission",
        });

      tableHeaders.push({
        label: "ações",
        sortable: false,
      });

      return tableHeaders;
    },
  },
  methods: {
    userCan(permission) {
      const { authorizations } = this.$store.state.user?.role || {};

      if (authorizations) {
        const [entity, action] = permission.split(".");

        if (authorizations[entity] && authorizations[entity].includes(action))
          return true;
      }

      return false;
    },
    sortColumn(v) {
      if (this.sortOptions[v.prop]) {
        if (this.sortOptions[v.prop] != "asc") this.sortOptions[v.prop] = "asc";
        else delete this.sortOptions[v.prop];
      } else this.sortOptions[v.prop] = "desc";
    },
    updateData() {
      this.$emit("should-update");
    },
    showPaymentDetails(p) {
      this.showPaymentDetailsModal = true;
      this.payment = p;
    },
    showEditPayment(p) {
      this.showEditPaymentModal = true;
      this.payment = p;
    },
    deletePayment(s) {
      fetch(this.$store.state.apiUrl + "payments/" + s.uid, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();

          throw response.json();
        })
        .then((e) => {
          ElNotification.success({
            title: e.message,
          });
        })
        .catch((e) =>
          ElNotification.error({
            message: e.message || "Não foi possível remover esse pagamento",
          })
        )
        .finally(() => {
          this.$emit("should-update");
        });
    },
  },
};
</script>
<style scoped>
tr.paid {
  background-color: #e0f2f1;
}
tr.unpaid {
  background-color: #ffebee;
}
tr.closed {
  background-color: #a4a4a4;
}
tbody.list {
  overflow-x: hidden;
  width: 100%;
}
</style>
