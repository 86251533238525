<template>
  <div>
    <dashboard-navbar
      searchHint="Buscar título..."
      @update-search="updateSearch"
    ></dashboard-navbar>

    <div class="container-fluid d-flex h-100 flex-column pt-6 px-2">
      <base-loader v-if="isLoading"></base-loader>
      <projects-table
        v-else
        :summary-method="calculateSummaries"
        show-summary
        class="table-responsive table-flush"
        header-row-class-name="table-head"
        header-align="center"
        cell-class-name="reduce-padding"
        :tableData="students"
        :row-class-name="getRowClassNames"
        type="hover"
        title="Relatório de inadimplentes"
        @showModal="showAddStudentModal = $event"
      >
        <template #actions>
          <base-button
            type="primary"
            class="mt-2"
            icon="fa fa-filter"
            title="Filtrar"
            size="sm"
            v-on:click.stop.prevent="openFilterModal"
          ></base-button>

          <el-popover placement="bottom" :width="300" v-model="dialogVisible">
            <p>Em qual formato deseja baixar?</p>
            <div style="text-align: right; margin: 0">
              <base-button
                size="sm"
                type="text"
                disabled
                @click="dialogVisible = false"
                >Excel</base-button
              >
              <base-button
                size="sm"
                type="text"
                @click="() => generateCSV((dialogVisible = false))"
                >CSV</base-button
              >
              <base-button
                type="text"
                size="sm"
                v-on:click.stop.prevent="
                  () => generatePDF((dialogVisible = false))
                "
                >PDF</base-button
              >
            </div>
            <template #reference>
              <base-button
                type="primary"
                icon="fa fa-download"
                class="mt-2"
                size="sm"
                :disabled="isLoadingGeneratePDF"
              ></base-button>
            </template>
          </el-popover>
        </template>
        <template #columns>
          <th v-for="col in TableColumns" :key="col">
            {{ col.label }}
            <span
              class="caret-wrapper"
              v-if="col?.sortable"
              @click="sortColumn(col)"
            >
              <i
                :class="{
                  'fa fa-sort-up ascending sort-icon': true,
                  'is-sorted': sortOptions[col.prop] === 'asc',
                }"
              ></i>
              <i
                :class="{
                  'fa fa-sort-down descending sort-icon': true,
                  'is-sorted': sortOptions[col.prop] === 'desc',
                }"
              ></i>
            </span>
          </th>
        </template>

        <template #data v-if="students?.length">
          <tr
            v-for="(c, id) in students"
            :key="id"
            :class="{
              contacted: studentWasContacted(c),
            }"
          >
            <td>
              <div>
                {{ cpfFormatter.format(c.cpf) }}
              </div>
            </td>
            <td>
              <div>{{ c.name }}</div>
            </td>

            <td>
              <div>{{ c?.contact?.phone }}</div>
            </td>

            <td>
              <div>
                {{ sumTotalPayments(c.payments) }}
              </div>
            </td>

            <td>
              <div class="btn-group float-right">
                <base-button
                  type="primary"
                  iconOnly
                  size="sm"
                  @click="navigateToRoute(c)"
                  icon="fa fa-external-link-alt"
                ></base-button>
                <base-button
                  :type="studentWasContacted(c) ? 'success' : 'primary'"
                  iconOnly
                  :tooltip="
                    studentWasContacted(c)
                      ? 'Já entrou em contato com o aluno'
                      : 'Marcar como feito'
                  "
                  size="sm"
                  @click="setStudentContacted(c)"
                  icon="fa fa-check"
                ></base-button>
                <base-button
                  type="primary"
                  iconOnly
                  tooltip="Informações dos títulos vencidos"
                  size="sm"
                  @click="openUnpaidDetailsModal(c)"
                  icon="fa fa-info"
                ></base-button>
                <base-button
                  type="primary"
                  iconOnly
                  tooltip="Adicionar observação para o aluno"
                  size="sm"
                  @click="openAddObservationModal(c)"
                  icon="fa fa-sticky-note"
                ></base-button>
              </div>
            </td>
          </tr>
        </template>

        <template #pagination>
          <base-pagination
            @input="pagination = $event"
            :value="pagination"
            :perPage="limit"
            :total="total"
          ></base-pagination>
        </template>
      </projects-table>
      <filter-incomings-modal
        :shouldShow="showFilterModal"
        :fields="fieldsToShowInFilter"
        @close-modal="showFilterModal = false"
        @update-filter="updateFilter($event)"
      ></filter-incomings-modal>

      <student-observation-modal
        :shouldShow="showStudentObservationModal"
        :student="student"
        @close-modal="showStudentObservationModal = false"
      ></student-observation-modal>

      <unpaid-details-modal
        :shouldShow="showUnpaidDetailsModal"
        :student="student"
        @close-modal="showUnpaidDetailsModal = false"
      ></unpaid-details-modal>
    </div>
  </div>
</template>

<script>
//import Card from "../components/Card.vue";
import "jspdf-autotable";
import jsPDF from "jspdf";
import DashboardNavbar from "../layout/DashboardNavbar.vue";
import ProjectsTable from "./Tables/ProjectsTable.vue";
import "vue-loaders/dist/vue-loaders.css";
import BaseLoader from "../components/BaseLoader.vue";
import { ElNotification } from "element-plus";
import UnpaidDetailsModal from "./Modals/UnpaidDetailsModal.vue";
import StudentObservationModal from "./Modals/AddStudentObservationModal.vue";
import FilterIncomingsModal from "./Modals/FilterIncomingsModal.vue";
// @ is an alias to /src

export default {
  name: "Students",
  components: {
    DashboardNavbar,
    ProjectsTable,
    BaseLoader,
    FilterIncomingsModal,
    StudentObservationModal,
    UnpaidDetailsModal,
  },
  data() {
    return {
      fieldsToShowInFilter: {
        type: true,
        comission: true,
        expired: true,
        method: true,
        expire_date: true,
      },
      isLoading: true,
      pagination: 1,
      filterOptions: {},
      sortOptions: {
        name: "asc",
      },
      student: null,
      searchQuery: "",
      showUnpaidDetailsModal: false,
      total: 0,
      jsPDF: new jsPDF("landscape"),
      isLoadingGeneratePDF: false,
      showStudentObservationModal: false,
      limit: 15,
      students: null,
      showFilterModal: false,
      dateFormatter: new Intl.DateTimeFormat("pt-BR"),
      currencyFormatter: new Intl.NumberFormat("pt-br", {
        minimumFractionDigits: 2,
      }),
      cpfFormatter: {
        format(cpf) {
          let cpfFormatted = `${cpf}`;
          cpfFormatted = `${"0".repeat(
            11 - cpfFormatted.length
          )}${cpfFormatted}`;

          return cpfFormatted.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4"
          );
        },
      },
      phoneFormatter: {
        format(phone) {
          if (phone) {
            let phoneFormatted = `${phone}`;
            switch (phoneFormatted.length) {
              case 9:
              case 11:
                return phoneFormatted.replace(
                  /(\d{2})(\d{1})(\d{4})(\d{4})/,
                  "($1)$2 $3-$4"
                );
              default:
                return phoneFormatted.replace(
                  /(\d{2})(\d{4})(\d{4})/,
                  "($1)$2-$3"
                );
            }
          } else return phone;
        },
      },
    };
  },
  created() {
    this.loadUnpaids();
  },
  watch: {
    SelectedSchool() {
      this.pagination = 1;
      this.searchQuery = "";
      this.loadUnpaids();
    },
    pagination() {
      this.loadUnpaids();
    },
  },
  computed: {
    SelectedSchool() {
      return this?.$store?.state?.user?.selectedSchool;
    },
    TableColumns: () => [
      {
        label: "cpf",
        sortable: true,
        prop: "cpf",
      },
      {
        label: "nome",
        sortable: true,
        prop: "name",
      },
      {
        label: "telefone",
        sortable: false,
        prop: "cost",
      },
      {
        label: "débito",
        sortable: false,
        prop: "cost",
      },
      {
        label: "ações",
        sortable: false,
      },
    ],
  },
  methods: {
    sortColumn(v) {
      if (this.sortOptions[v.prop]) {
        if (this.sortOptions[v.prop] != "asc") this.sortOptions[v.prop] = "asc";
        else delete this.sortOptions[v.prop];
      } else this.sortOptions[v.prop] = "desc";

      this.fetchData()
        .then((response) => {
          if (response.status == 200) return response.json();
          else throw response.json();
        })
        .then((json) => {
          this.students = json.data;
          this.total = json.total;
        });
    },
    sumTotalPayments(payments) {
      return this.currencyFormatter.format(
        payments?.reduce(
          (t, e) => (t += e.payment_status === "unpaid" ? Number(e.cost) : 0),
          0
        ) || 0
      );
    },
    openUnpaidDetailsModal(student) {
      this.student = student;
      this.showUnpaidDetailsModal = true;
    },
    calculateSummaries({ data }) {
      return [
        "Total:",
        "",
        "",
        this.sumTotalPayments(data?.map((d) => d.payments)?.flat()),
      ];
    },
    lastExpiration(payments) {
      return payments?.sort(
        (a, b) => new Date(b.expires_at) - new Date(a.expires_at)
      )?.[0];
    },
    async generateCSV() {
      this.isLoadingGeneratePDF = true;
      ElNotification.info({
        title: "Gerando relatório",
        message: "Isso pode levar até 1 minuto",
      });

      const data = await this.fetchData(false).then((response) =>
        response.json()
      );

      const mappedRows = data?.map((e) => {
        const enrols = e.enrols;
        const map = [];

        for (let __ in enrols) {
          let _ = enrols[__];
          if (_.debts?.length)
            map.push([
              this.cpfFormatter.format(e?.cpf),
              e?.name,
              this.phoneFormatter.format(e?.contact?.phone),
              _?._course?.name,
              this.sumTotalPayments(_?.debts),
              _?.debts?.length || 0,
              _?.debts?.length
                ? this.dateFormatter.format(
                    new Date(this.lastExpiration(_?.debts)?.expires_at)
                  )
                : "",
            ]);
        }

        return map;
      });

      const columns = [...this.TableColumns];
      columns.length--;
      columns.splice(3, 0, { label: "curso" });

      mappedRows.unshift([
        [
          ...columns.map((c) => c.label),
          "qtd títulos",
          "último vencimento",
        ]?.map((c) => c.toUpperCase()),
      ]);

      this.csvExport(mappedRows);

      this.isLoadingGeneratePDF = false;
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        ...arrData
          ?.flat()
          .map((item) => Object.values(item)?.flat(Infinity).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Exportação.csv");
      link.click();
    },
    async generatePDF() {
      this.isLoadingGeneratePDF = true;
      ElNotification.info({
        title: "Gerando relatório",
        message: "Isso pode levar até 1 minuto",
      });

      const data = await this.fetchData(false).then((response) =>
        response.json()
      );

      const mappedRows = data?.map((e) => {
        const enrols = e.enrols;
        const map = [];

        if (enrols?.filter((e) => e.debts?.length)?.length > 1) {
          console.log("mais de uma matricula em débito");
          debugger;
        }

        for (let __ in enrols) {
          let _ = enrols[__];
          map.push([
            this.cpfFormatter.format(e?.cpf),
            e?.name,
            this.phoneFormatter.format(e?.contact?.phone),
            _?._course?.name,
            this.sumTotalPayments(_?.debts),
            e?.payments?.length || 0,
          ]);
        }

        return map;
      });

      mappedRows.push(this.calculateSummaries({ data }));

      const columns = [...(this.TableColumns || [])];
      columns.length--;
      columns.splice(3, 0, { label: "curso" });

      this.jsPDF.autoTable({
        columns: columns.map((c) => c.label),
        body: mappedRows.flat(),
      });

      this.jsPDF.save(`repasse.pdf`);
      this.isLoadingGeneratePDF = false;
    },
    studentWasContacted(student) {
      return (
        student.contacted_at &&
        new Date(lastExpiredAt(student?.payments)) <=
          new Date(student.contacted_at)
      );
    },
    setStudentContacted(student) {
      const url = new URL(
        this.$store.state.apiUrl + "students/" + student?.uid
      );
      if (student)
        fetch(url, {
          credentials: "include",
          //mode: "no-cors",
          method: "PUT",
          body: JSON.stringify({
            contacted_at: this.studentWasContacted(student)
              ? new Date(0, 0, 0)
              : new Date(),
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((r) => {
            if (r.status == 200) {
              this.loadUnpaids();
              return r.json();
            }
          })
          .then(() =>
            ElNotification.success({
              title: "Aluno atualizado com sucesso",
              message: this.studentWasContacted(student)
                ? "O aluno foi desmarcado"
                : "o aluno foi marcado como contactado.",
            })
          );
    },
    getFileName() {
      const options = this.filterOptions;
      let name = "";
      name += "Relatório de Inadimplência";
      for (let f in options) {
        switch (f) {
          case "type":
            name += this.getPaymentType(options[f]);
            break;
          case "status":
            name +=
              options[f] === "paid"
                ? "Pago"
                : options[f] === "opened"
                ? "Aberto"
                : options[f] === "unpaid"
                ? "Atrasado"
                : options[f] === "closed"
                ? "Fechado"
                : "";
            break;
          case "category":
            name += this.getPaymentCategory(options[f]);
            break;
          case "is_received":
            name += options[f] ? "Recebido" : "Não Recebido";
            break;
        }
      }

      return name;
    },
    getPaymentCategory: (category) =>
      ({ renegotiation: "RN", monthly: "MN", tax: "MT" }[category] || "MN"),
    getPaymentType: (type) =>
      ({ credit: "crédito", doc: "boleto", pix: "transf.", polo: "polo" }[
        type
      ] || "desconhecido"),
    updateFilter(v) {
      this.filterOptions = v;
      this.loadUnpaids();
    },
    isEmpty(o) {
      for (let i in o) return false;
      return true;
    },
    updateSearch(v) {
      this.searchQuery = v;
      this.pagination = 1;
      this.loadUnpaids();
    },
    fetchData(paginate = true) {
      const url = new URL(this.$store.state.apiUrl + "students");
      const params = {
        defaulters: true,
        school_uid: this.SelectedSchool?.uid,
        ...(this.filterOptions ? this.filterOptions : {}),
        status: "opened",
      };

      if (paginate) {
        params.pagination = this.pagination || 1;
        params.limit = this.limit || 15;
      }
      params.queryName = this.searchQuery || "";
      params.ordenation = JSON.stringify(this.sortOptions);

      url.search = new URLSearchParams(params);
      return fetch(url, {
        credentials: "include",
        //mode: "no-cors",
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
    },
    getRowClassNames({ row }) {
      return row.payment_status;
    },
    loadUnpaids() {
      this.isLoading = true;
      this.fetchData()
        .then((response) => response.json())
        .then((json) => {
          this.students = json.data;
          this.total = json.total;
        })
        .then(() => (this.isLoading = false));
    },
    navigateToRoute(items) {
      let route = this.$router.resolve(`/alunos/${items.uid}`);
      window.open(route.href, "_blank");
    },
    hasValue(item, column) {
      return item[column] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    openFilterModal() {
      this.showFilterModal = true;
    },
    openAddObservationModal(s) {
      this.student = s;
      this.showStudentObservationModal = true;
    },
  },
};
const lastExpiredAt = (ps = []) =>
  Math.max(...ps?.map((p) => new Date(p.expires_at)));
</script>
<style scoped>
tbody.list {
  overflow-x: hidden;
  width: 100%;
}
.contacted {
  background-color: #e0f2f1;
}
</style>
