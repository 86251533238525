<template>
  <form v-on:submit="onSubmit">
    <slot></slot>
  </form>
</template>
<script>
import { uuid } from "vue-uuid";
export default {
  name: "base-form",
  props: {
    key: {
      type: String,
      required: false,
      default: uuid.v4(),
    },
  },
  emits: ["onSubmit"],
  methods: {
    onSubmit() {
      this.$emits("onSubmit");
    },
  },
};
</script>
