<template>
  <dashboard-navbar searchHint="Busque qualquer coisa..."></dashboard-navbar>

  <div class="container-fluid d-flex h-100 flex-column pt-6 px-2">
    <base-loader v-if="isLoading"></base-loader>
    <base-error v-else-if="hasError" :details="errorMessage"></base-error>
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-1 py-lg-1"
      class="border-0 mt-4"
      footerClasses="p-0"
      v-else
    >
      <template #header>
        <div class="row justify-content-between px-2">
          <h4>Cargos Cadastrados</h4>
          <div class="row pr-2">
            <base-button
              type="primary"
              :icon="a.icon"
              size="sm"
              v-for="a in NavActions"
              :key="a"
              v-on:click.stop.prevent="a.action"
            ></base-button>
          </div>
        </div>
      </template>
      <div class="row p-2" v-for="n in NumberOfRows" :key="n">
        <div
          class="col-lg-6 col-md-12 col-sm-12"
          v-for="a in getNextRoles(n, 2)"
          :key="a.uid"
        >
          <card shadow headerClasses="px-2 py-2" footerClasses="pl-2 pr-3 py-2">
            <template #header>
              <div class="col-12 p-0">
                <h4 class="mb-0">{{ a.name }}</h4>
                <small class="text-muted"
                  >{{ a.__meta__.related_users }} usuários vinculados</small
                >
              </div>
            </template>
            <div class="col-12">
              <h4 class="text-muted">Permissões:</h4>
              <div class="list-group">
                <div
                  class="list-group-item"
                  v-for="p in SystemPermissions"
                  :key="p"
                >
                  <div class="row justify-items-between">
                    <div class="col">
                      <h4>{{ p.label }}</h4>
                    </div>
                    <div class="col">
                      <div class="row btn-group justify-content-end">
                        <base-button
                          iconOnly
                          :type="
                            roleHasPermission(p, a, 'r')
                              ? 'primary'
                              : 'secondary'
                          "
                          @click="updatePermission(p, a, 'r')"
                          size="sm"
                          icon="fa fa-eye"
                        ></base-button>
                        <base-button
                          iconOnly
                          size="sm"
                          :type="
                            roleHasPermission(p, a, 'w')
                              ? 'primary'
                              : 'secondary'
                          "
                          @click="updatePermission(p, a, 'w')"
                          icon="fa fa-plus-square"
                        ></base-button>
                        <base-button
                          iconOnly
                          size="sm"
                          :type="
                            roleHasPermission(p, a, 'u')
                              ? 'primary'
                              : 'secondary'
                          "
                          @click="updatePermission(p, a, 'u')"
                          icon="fa fa-pencil"
                        ></base-button>
                        <base-button
                          iconOnly
                          size="sm"
                          :type="
                            roleHasPermission(p, a, 'd')
                              ? 'primary'
                              : 'secondary'
                          "
                          @click="updatePermission(p, a, 'd')"
                          icon="fa fa-trash"
                        ></base-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
              <div class="row justify-content-between">
                <div class="col-md-8 col-sm-12">
                  <p class="card-text">
                    <small class="text-muted"
                      >Criado em
                      {{
                        new Intl.DateTimeFormat("pt-br", {
                          dateStyle: "long",
                        }).format(new Date(a.created_at))
                      }}</small
                    >
                  </p>
                </div>
                <div class="row px-4">
                  <base-button
                    type="primary"
                    rounded
                    iconOnly
                    @click="openRoleModal(a)"
                    size="sm"
                    icon="fa fa-pencil"
                  ></base-button>
                  <el-popconfirm
                    title="Você tem certeza que quer deletar este cargo?"
                    @confirm="deleteRole(a)"
                  >
                    <template #reference>
                      <base-button
                        type="danger"
                        rounded
                        iconOnly
                        size="sm"
                        icon="fa fa-trash"
                      ></base-button>
                    </template>
                  </el-popconfirm>
                </div>
              </div>
            </template>
          </card>
        </div>
      </div>
    </card>
  </div>
  <roles-modal
    :shouldShow="showRolesModal"
    :role="role"
    @reload-data="fetchRoles"
    @close-modal="showRolesModal = false"
  ></roles-modal>
</template>

<script>
//import Card from "../components/Card.vue";
import DashboardNavbar from "../layout/DashboardNavbar.vue";
import "vue-loaders/dist/vue-loaders.css";
import BaseLoader from "../components/BaseLoader.vue";
import BaseError from "../components/BaseError.vue";
import RolesModal from "./Modals/RolesModal.vue";
import { ElNotification, ElPopconfirm } from "element-plus";
// @ is an alias to /src

export default {
  name: "Roles",
  components: {
    DashboardNavbar,
    BaseLoader,
    RolesModal,
    BaseError,
    ElPopconfirm,
  },
  data() {
    return {
      isLoading: true,
      pagination: 1,
      hasError: false,
      errorMessage: "",
      limit: 5,
      showRolesModal: false,
      role: null,
      roles: null,
    };
  },
  created() {
    this.fetchRoles();
  },
  computed: {
    SystemPermissions() {
      return [
        {
          label: "Usuários",
          value: "users",
        },
        {
          label: "Logs",
          value: "user_logs",
        },
        {
          label: "Cargos",
          value: "roles",
        },
        {
          label: "Alunos",
          value: "students",
        },
        {
          label: "Pagamentos",
          value: "payments",
        },
        {
          label: "Matrículas",
          value: "enrols",
        },
        {
          label: "Instituições",
          value: "schools",
        },
        {
          label: "Cursos",
          value: "courses",
        },
        {
          label: "Comissões",
          value: "comissions",
        },
        {
          label: "Eventos",
          value: "events",
        },
        {
          label: "Provas",
          value: "enrol_exams",
        },
      ];
    },
    NumberOfRows() {
      return Math.ceil(this.RolesList.length / 2);
    },
    Offset() {
      return (this.pagination - 1) * this.limit;
    },
    RolesList() {
      return [...this.roles.entries()].map((e) => ({ uid: e[0], ...e[1] }));
    },
    LimitedRows() {
      return this.RolesList.slice(this.Offset, this.Offset + this.limit);
    },
    NavActions() {
      return [
        {
          icon: "fa fa-plus-square",
          action: () => this.openRolesModal(null), //this.showAddCourse,
        },
      ];
    },

    TableColumns: () => ["nome", "email", "cargo", "ações"],
  },
  methods: {
    roleHasPermission(p, r, o) {
      return (
        Object.prototype.hasOwnProperty.call(r?.permissions || {}, p.value) &&
        Array.isArray(r.permissions[p.value]) &&
        r?.permissions[p.value].includes(o)
      );
    },
    updatePermission(p, r, o) {
      if (!r.permissions) r.permissions = {};
      if (this.roleHasPermission(p, r, o))
        r.permissions[p.value] = r.permissions[p.value].filter((e) => e != o);
      else {
        if (!Array.isArray(r.permissions[p.value])) r.permissions[p.value] = [];
        r.permissions[p.value].push(o);
      }

      this.updateRole(r);
    },
    openRolesModal(role) {
      this.role = role;
      this.showRolesModal = true;
    },
    getNextRoles(line, columns) {
      return this.RolesList.slice(
        (line - 1) * columns,
        (line - 1) * columns + columns
      );
    },
    hasValue(item, column) {
      return item[column] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    fetchRoles() {
      fetch(`${this.$store.state.apiUrl}roles`, {
        credentials: "include",
      })
        .then(async (response) => {
          if (response.status === 200) return response.json();
          else throw await response.json();
        })
        .then(
          (json) => (this.roles = json),
          (e) => {
            this.hasError = true;
            this.errorMessage = e.message;
          }
        )
        .finally(() => (this.isLoading = false));
    },
    click() {},
    deleteRole(r) {
      fetch(`${this.$store.state.apiUrl}roles/${r.uid}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) {
            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
            }),
          (e) =>
            ElNotification.error({
              message: e.message,
            })
        )
        .finally(() => this.fetchRoles());
    },
    openRoleModal(a) {
      this.role = a;
      this.showRolesModal = true;
    },
    updateRole(r) {
      fetch(`${this.$store.state.apiUrl}roles/${r.uid}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(r),
      })
        .then(async (response) => {
          if (response.status == 200) {
            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
            }),
          (e) =>
            ElNotification.error({
              message: e.message,
            })
        )
        .finally(() => this.fetchRoles());
    },
  },
};
</script>
