<template>
  <dashboard-navbar searchHint="Buscar curso..."></dashboard-navbar>

  <div class="container-fluid d-flex h-100 flex-column pt-6 px-2">
    <base-loader v-if="isLoading"></base-loader>
    <projects-table
      v-else
      type="hover"
      :tableData="LimitedRows"
      title="Lista de Comissões Cadastradas"
      @showModal="showComissionModal = $event"
    >
      <template #actions>
        <base-button
          type="primary"
          icon="fa fa-plus-square"
          size="sm"
          v-on:click.stop.prevent="openComissionModal(null)"
        ></base-button>
      </template>
      <template #columns>
        <th v-for="col in TableColumns" :key="col">{{ col }}</th>
      </template>

      <template #data v-if="LimitedRows?.length">
        <tr v-for="c of LimitedRows" :key="c.uid">
          <td>
            {{ c.name }}
          </td>
          <td>
            {{ c.school.initials }}
          </td>

          <td>
            {{ ShowPercentages(c.percentages) }}
          </td>

          <td>
            <div class="btn-group float-right">
              <el-popconfirm
                title="Tem certeza?"
                confirm-button-type="warning"
                confirm-button-text="Sim"
                @confirm="
                  updateComission({ uid: c.uid, is_active: !c.is_active })
                "
                cancel-button-text="Cancelar"
              >
                <template #reference>
                  <base-button
                    :type="!c.is_active ? 'danger' : 'success'"
                    iconOnly
                    size="sm"
                    :icon="`fa fa-${!c.is_active ? 'lock' : 'lock-open'}`"
                  ></base-button>
                </template>
              </el-popconfirm>
              <base-button
                size="sm"
                type="primary"
                @click.stop="openComissionModal(c)"
                ><i class="fa fa-pencil"></i
              ></base-button>

              <el-popconfirm
                title="Tem certeza? Matríulas e Pagamentos também serão removidos"
                confirm-button-type="danger"
                confirm-button-text="Deletar Tudo!"
                @confirm="deleteComission(c)"
                cancel-button-text="Cancelar"
              >
                <template #reference>
                  <base-button
                    type="danger"
                    iconOnly
                    size="sm"
                    icon="fa fa-trash"
                  ></base-button>
                </template>
              </el-popconfirm>
            </div>
          </td>
        </tr>
      </template>

      <template #pagination>
        <base-pagination
          @input="pagination = $event"
          :value="pagination"
          :perPage="limit"
          :total="comissions.length"
        ></base-pagination>
      </template>
    </projects-table>
  </div>

  <comission-modal
    @close-modal="showComissionModal = false"
    @reload-data="fetchData"
    :shouldShow="showComissionModal"
    :comission="comission"
  ></comission-modal>
</template>

<script>
//import Card from "../components/Card.vue";
import DashboardNavbar from "../layout/DashboardNavbar.vue";
import ProjectsTable from "../views/Tables/ProjectsTable.vue";
import "vue-loaders/dist/vue-loaders.css";
import BaseLoader from "../components/BaseLoader.vue";
import ComissionModal from "./Modals/ComissionModal.vue";
import ComissionService from "../services/comissions";
import { ElNotification, ElPopconfirm } from "element-plus";
// @ is an alias to /src

export default {
  name: "Comission",
  components: {
    DashboardNavbar,
    ProjectsTable,
    BaseLoader,
    ComissionModal,
    ElPopconfirm,
  },
  data() {
    return {
      isLoading: true,
      showComissionModal: false,
      pagination: 1,
      limit: 15,
      comissions: [],
      comission: null,
    };
  },
  watch: {
    SelectedSchool() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  computed: {
    Offset() {
      return (this.pagination - 1) * this.limit;
    },
    LimitedRows() {
      return this.comissions.slice(this.Offset, this.Offset + this.limit);
    },
    SelectedSchool() {
      return this.$store.state.user.selectedSchool;
    },
    TableColumns: () => ["nome", "instituição", "porcentagens", "ações"],
  },
  methods: {
    isEmpty(o) {
      for (let i in o) return false;
      return true;
    },

    ShowPercentages(percentages) {
      return `Matrícula: ${percentages[0]} %\nDemais: ${percentages[1]} %`;
    },
    deleteComission(v) {
      fetch(this.$store.state.apiUrl + "comissions/" + v.uid, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(() => {
          this.showModal = false;
          ElNotification.success({
            title: "Comissão removida com sucesso",
          });
        })
        .catch(async (error) => {
          error.then((e) =>
            ElNotification.error({
              title: "Não foi possível remover a comissão",
              message: e.message,
            })
          );
        })
        .finally(() => {
          this.isLoadingSubmit = false;
          this.fetchData();
        });
    },
    openComissionModal(v) {
      this.comission = v;
      this.showComissionModal = true;
    },
    async updateComission({ uid, is_active }) {
      const { comission } = await ComissionService(uid).update({
        is_active,
      });

      if (comission) {
        ElNotification.success({
          title: "Comissão atualizada com sucesso",
        });
        this.fetchData();
      }
    },
    fetchData() {
      this.isLoading = true;
      const url = new URL(this.$store.state.apiUrl + "comissions");
      const params = { school_uid: this.SelectedSchool?.uid };

      if (!this.isEmpty(params)) url.search = new URLSearchParams(params);
      fetch(url, {
        credentials: "include",
        method: "GET",
      })
        .then((response) => {
          if (response.status == 200) return response.json();
          else throw response.json();
        })
        .then((json) => (this.comissions = json))
        .catch((e) => console.log(e))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
