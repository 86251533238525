<template>
  <modal
    @update:show="showModal = $event"
    :show="showModal"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template v-slot:header>
        <div class="row justify-space-between">
          <div class="col">
            <h4 class="modal-title" id="exampleModalLabel">
              {{ isNew ? "MATRICULAR ALUNO" : "EDITAR MATRÍCULA" }}
            </h4>
            <h6 class="text-muted">
              {{ isNew ? "Matriculando o" : "Editando matrícula do" }} aluno
              {{ student?.name }}
            </h6>
          </div>
          <div class="button-group" v-if="!isNew">
            <base-button
              size="sm"
              :type="enrol_.status == 'created' ? 'default' : 'secondary'"
              v-on:click="enrol_.status = 'created'"
              >Criada</base-button
            >
            <base-button
              size="sm"
              :type="enrol_.status == 'progress' ? 'info' : 'secondary'"
              v-on:click="enrol_.status = 'progress'"
              >Cursando</base-button
            >
            <base-button
              size="sm"
              :type="enrol_.status == 'finalized' ? 'success' : 'secondary'"
              v-on:click="enrol_.status = 'finalized'"
              >Concluída</base-button
            >
            <base-button
              size="sm"
              :type="enrol_.status == 'locked' ? 'warning' : 'secondary'"
              v-on:click="enrol_.status = 'locked'"
              >Trancada</base-button
            >
            <base-button
              size="sm"
              :type="enrol_.status == 'pending' ? 'warning' : 'secondary'"
              v-on:click="enrol_.status = 'pending'"
              >Pendente</base-button
            >
            <base-button
              size="sm"
              :type="enrol_.status == 'canceled' ? 'danger' : 'secondary'"
              v-on:click="enrol_.status = 'canceled'"
              >Cancelada</base-button
            >
          </div>
        </div>
      </template>

      <form>
        <div class="row">
          <div class="col-12">
            <!--SELEÇÃO DO CURSO-->
            <div class="row mb-2">
              <label class="col-md-2 col-form-label form-control-label"
                >Curso:</label
              >
              <div class="col-md-10 pl-0">
                <el-select
                  v-model="enrol_._course"
                  ref="select_course"
                  filterable
                  clearable
                  remote
                  value-key="uid"
                  loading-text="Buscando curso"
                  class="input-group-alternative"
                  placeholder="Digite as iniciais do curso para filtrar"
                  :filter-method="searchCourse"
                  :loading="isSearchCourseLoading"
                  no-data-text="Nenhum curso encontrado"
                >
                  <template #empty>
                    <div v-if="!isSearchCourseLoading">
                      <div class="row justify-content-center p-2">
                        <i
                          class="fa fa-exclamation-circle fa-lg align-self-center"
                          style="color: red"
                        ></i>
                        <h5 class="text-muted ml-1 my-auto">
                          Curso não encontrado, certifique-se de selecionar a
                          instituição correta
                        </h5>
                      </div>
                      <div class="dropdown-divider"></div>
                      <div class="row justify-content-center">
                        <base-button
                          @click="openAddCourse"
                          size="sm"
                          outline
                          type="text"
                          >cadastrar novo curso</base-button
                        >
                      </div>
                    </div>

                    <div v-else>
                      <div class="row justify-content-center p-2">
                        <h5 class="text-muted ml-1 my-auto">
                          Buscando curso...
                        </h5>
                      </div>
                    </div>
                  </template>

                  <el-option
                    v-for="item in FilteredCourses"
                    :key="item[0]"
                    :label="`${item[1].level}: ${item[1].name} - ${item[1].workload} horas`"
                    :value="item[1]"
                    :disabled="!item[1].is_active"
                    :title="item[1].description"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 col-sm-12">
            <div class="row mb-2">
              <label class="col-5 col-form-label form-control-label"
                >Código:</label
              >
              <div class="col-7">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="enrol_.code"
                  mask="#*"
                  placeholder="apenas números"
                  type="number"
                ></base-input>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="row mb-2">
              <label class="col-4 col-form-label form-control-label"
                >Data:</label
              >
              <div class="col-8">
                <DatePicker
                  :popover="{ visibility: 'focus' }"
                  timezone="UTC"
                  :model-config="{ timeAdjust: '00:00:00' }"
                  v-model="enrol_.start_in"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <base-input
                      formClasses="input-group-alternative"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="25/02/2021"
                    ></base-input>
                  </template>
                </DatePicker>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="row mb-2">
              <label class="col-6 col-form-label form-control-label"
                >Períodos:</label
              >
              <div class="col-6">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="enrol_.periods"
                  mask="#*"
                  placeholder="apenas números"
                  type="number"
                ></base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row flex justify-space-between">
          <div class="col-md-8 col-10">
            <label class="col-form-label form-control-label"
              >É uma nova matrícula?</label
            >
          </div>
          <div class="col-md-4 col-2">
            <div class="row justify-content-end">
              <base-checkbox
                formClasses="input-group-alternative"
                v-model="enrol_.is_new"
              ></base-checkbox>
            </div>
          </div>
        </div>

        <base-collapse title="Disciplinas" :key="enrol_._course">
          <div v-if="enrol_._course">
            <div class="list-group" v-if="!enrol_?._course?.periods">
              <h5 class="text-muted mx-auto">
                Este curso não permite cadastro de disciplinas
              </h5>
            </div>
            <div
              v-else
              v-for="p in enrol?.periods || enrol_?._course?.periods"
              :key="p"
            >
              <div class="row justify-content-between mb-1 px-4">
                <h5>{{ p }}º Período</h5>
                <base-button
                  type="secondary"
                  size="sm"
                  :disabled="p <= enrol_.current_period"
                  @click="addDisciplineInPeriod(p)"
                  >adicionar</base-button
                >
              </div>
              <div
                :class="{
                  row: true,
                  'discipline-completed': d.status === 'completed',
                  'discipline-canceled': d.status === 'canceled',
                }"
                v-for="(d, id) in showDisciplinesByPeriod(p)"
                :key="id"
              >
                <div class="col-md-6 col-sm-12">
                  <!--Nome da Disciplina-->
                  <div class="row mb-2">
                    <label class="col-4 col-form-label form-control-label"
                      >Nome:</label
                    >
                    <div class="col-8">
                      <base-input
                        v-model="d.name"
                        formClasses="input-group-alternative"
                        v-on:keyup.enter="() => updateDiscipline(d)"
                        :disabled="!isNew && p <= enrol_.current_period"
                        placeholder="Introdução ao Cálculo"
                      ></base-input>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 col-sm-12">
                  <!--C/H da disciplina-->
                  <div class="row mb-2">
                    <label class="col-4 col-form-label form-control-label"
                      >C/H:</label
                    >
                    <div class="col-8">
                      <base-input
                        v-model="d.workload"
                        formClasses="input-group-alternative"
                        :disabled="!isNew && p <= enrol_.current_period"
                        v-on:keyup.enter="() => updateDiscipline(d)"
                        placeholder="60"
                        mask="#*"
                      ></base-input>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-1 col-sm-12 d-flex align-items-center float-right"
                >
                  <base-button
                    iconOnly
                    outline
                    rounded
                    class="btn btn-outlined"
                    link
                    size="sm"
                    type="secondary"
                    :disabled="!isNew || p <= enrol_.current_period"
                    @click="removeDiscipline(d.uid)"
                    ><i class="fa fa-trash" style="color: red"></i
                  ></base-button>
                </div>
              </div>
              <div class="list-group" v-if="dontHasDisciplinesInPeriod(p)">
                <h5 class="text-muted mx-auto">
                  Nenhuma disciplina cadastrada para o período
                </h5>
              </div>
            </div>
          </div>

          <template v-else>
            <div class="list-group">
              <h5 class="text-muted mx-auto">
                Selecione um curso para visualizar as disciplinas
              </h5>
            </div>
          </template>
        </base-collapse>

        <base-collapse title="Taxa de Matrícula">
          <div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="row mb-2">
                  <label
                    class="col-md-5 col-sm-4 col-form-label form-control-label"
                    >Valor:</label
                  >
                  <div class="col-md-7 col-sm-8">
                    <base-input
                      formClasses="input-group-alternative"
                      v-model="enrol_.tax_cost"
                      placeholder="R$"
                      type="money"
                    ></base-input>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="row mb-2">
                  <label class="col-4 col-form-label form-control-label"
                    >Parcelas:</label
                  >
                  <div class="col-8">
                    <base-input
                      formClasses="input-group-alternative"
                      v-model="enrol_.taxes"
                      placeholder="apenas números"
                      mask="#*"
                    ></base-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="row mb-2">
                  <label
                    class="col-md-5 col-sm-4 col-form-label form-control-label"
                    >Forma Pagamento:</label
                  >
                  <div class="col-md-7 col-sm-8">
                    <el-select
                      v-model="enrol_.tax_payment_type"
                      clearable
                      class="input-group-alternative"
                      placeholder="Selecione o tipo de parcelamento"
                    >
                      <el-option
                        v-for="(item, id) in PaymentTypes"
                        :key="item + id"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="row mb-2">
                  <label class="col-4 col-form-label form-control-label"
                    >Vencimento:</label
                  >
                  <div class="col-8">
                    <DatePicker
                      v-model="enrol_.tax_expires_in"
                      timezone="UTC"
                      :model-config="{ timeAdjust: '00:00:00' }"
                      :popover="{ visibility: 'focus' }"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <base-input
                          formClasses="input-group-alternative"
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="25/02/2021"
                        ></base-input>
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
            </div></div
        ></base-collapse>

        <base-collapse title="Mensalidades">
          <div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="row mb-2">
                  <label
                    class="col-md-5 col-sm-4 col-form-label form-control-label"
                    >Valor Total:</label
                  >
                  <div class="col-md-7 col-sm-8">
                    <base-input
                      formClasses="input-group-alternative"
                      v-model="enrol_.cost"
                      placeholder="R$"
                      type="money"
                    ></base-input>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="row mb-2">
                  <label class="col-4 col-form-label form-control-label"
                    >Parcelas:</label
                  >
                  <div class="col-8">
                    <base-input
                      formClasses="input-group-alternative"
                      v-model="enrol_.payments"
                      placeholder="apenas números"
                      mask="#*"
                    ></base-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="row mb-2">
                  <label
                    class="col-md-5 col-sm-4 col-form-label form-control-label"
                    >Forma Pagamento:</label
                  >
                  <div class="col-md-7 col-sm-8">
                    <el-select
                      v-model="enrol_.payment_type"
                      ref="select_payment_type"
                      clearable
                      class="input-group-alternative"
                      placeholder="Selecione o tipo de parcelamento"
                      :filter-method="searchCourse"
                      :loading="isSearchCourseLoading"
                    >
                      <el-option
                        v-for="(item, id) in PaymentTypes"
                        :key="item + id"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="row mb-2">
                  <label class="col-4 col-form-label form-control-label"
                    >Vencimento:</label
                  >
                  <div class="col-8">
                    <DatePicker
                      v-model="enrol_.payments_starts_in"
                      timezone="UTC"
                      :model-config="{ timeAdjust: '00:00:00' }"
                      :popover="{ visibility: 'focus' }"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <base-input
                          formClasses="input-group-alternative"
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="25/02/2021"
                        ></base-input>
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </base-collapse>

        <base-collapse title="Vendedor">
          <div>
            <div class="row">
              <div class="col-12">
                <!--EMAIL-->
                <div class="form-group row mb-2">
                  <label
                    class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
                    >Nome:</label
                  >
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <base-input
                      :disabled="!isNew"
                      formClasses="input-group-alternative"
                      v-model="enrol_.seller_name"
                      required
                    ></base-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </base-collapse>

        <div class="dropdown-divider"></div>
        <h4 class="m-0">Comissão de Venda</h4>
        <small class="text-muted"
          >A comissão será aplicada em cada título cadastrado
          individualmente</small
        >

        <div class="col-md-12 col-sm-12 p-0">
          <div class="row mb-2">
            <label class="col-md-2 col-sm-4 col-form-label form-control-label"
              >Comissão:</label
            >
            <div class="col-md-10 col-sm-8">
              <el-select
                v-model="enrol_.comission"
                clearable
                remote
                value-key="uid"
                :loading="isLoadingFetchComissions"
                @visible-change="fetchComissions"
                popper-append-to-body
                class="input-group-alternative"
                placeholder="Selecione o tipo de parcelamento"
              >
                <template #empty>
                  <h4 class="text-muted">Não existem comissões cadastradas</h4>
                </template>
                <div v-if="Comissions?.length">
                  <el-option
                    v-for="item in Comissions || []"
                    :key="item?.uid"
                    :label="item?.name"
                    :value="item"
                  >
                  </el-option>
                </div>
                <div v-else>
                  <h4 v-if="enrol_?._course" class="text-muted">
                    Não existem comissões cadastradas
                  </h4>
                  <h4 v-else class="text-muted">
                    Você deve selecionar um curso primeiro
                  </h4>
                </div>
              </el-select>
            </div>
          </div>
        </div>
      </form>
      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button
            size="sm"
            @click="showModal = false"
            class="float-right"
            type="danger"
            >CANCELAR</base-button
          >
          <base-button
            size="sm"
            @click="save"
            :tooltip="
              isNew
                ? ''
                : 'Após a criação da matrícula não é possível editar as disciplinas'
            "
            :isLoading="isLoadingSubmit"
            class="float-right"
            type="primary"
            >SALVAR</base-button
          >
        </div>
      </template>
    </card>
    <event-modal
      :shouldShow="showAddEventModal"
      :student="student"
      @reload-data="() => continueUpdating()"
      @close-modal="showAddEventModal = false"
      :event="newEvent"
    ></event-modal>
    <add-course-modal
      :shouldShow="showAddCourseModal"
      :course="newCourse"
      :isNew="true"
      @close-modal="showAddCourseModal = false"
    ></add-course-modal>
  </modal>
</template>

<script>
import BaseButton from "../../components/BaseButton";
import { ElSelect, ElOption, ElNotification } from "element-plus";
import AddCourseModal from "./AddCourseModal.vue";
import EventModal from "./EventModal.vue";
import { DatePicker } from "v-calendar";
import BaseCollapse from "../../components/BaseCollapse.vue";
import { confirmOnCenter } from "../../utils/notifiers";
import { uuid } from "vue-uuid";
import DisciplineService from "../../services/disciplines";

export default {
  components: {
    BaseButton,
    ElSelect,
    ElOption,
    AddCourseModal,
    EventModal,
    DatePicker,
    BaseCollapse,
  },
  name: "add-enroll-modal",
  emits: ["close-modal"],
  props: {
    shouldShow: {
      type: Boolean,
      default: () => false,
    },
    student: {
      type: Object,
      default: () => ({}),
    },
    enrol: {
      type: Object,
    },
  },
  computed: {
    showModal: {
      get: function () {
        return this.shouldShow;
      },
      set: function () {
        this.$emit("close-modal", false);
      },
    },
    isNew() {
      return !this.enrol;
    },
    FilteredCourses() {
      return [...this.courses.entries()].filter((e) =>
        e[1]?.name?.toLowerCase()?.includes(this.searchQuery.toLowerCase())
      );
    },
    PaymentTypes() {
      return this?.$store?.state?.constants?.paymentTypes || [];
    },
    Comissions() {
      return this.comissions?.filter(({ is_active }) => is_active) || [];
    },
    MappedDisciplines: {
      get() {
        return this.isNew
          ? this?.enrol_?._course?.disciplines || []
          : this.enrol_.disciplines;
      },
      set(v) {
        this.enrol_._course.disciplines = v;
      },
    },
  },
  data() {
    return {
      enrol_: {},
      searchQuery: "",
      newCourse: {},
      comissions: [],
      isLoadingSubmit: false,
      isLoadingFetchComissions: false,
      showAddCourseModal: false,
      isSearchCourseLoading: false,
      expandDisciplinesCollapse: false,
      newEvent: {},
      showAddEventModal: false,
      courses: [],
      continueUpdating: () => {},
    };
  },
  watch: {
    enrol(v) {
      if (v) {
        this.enrol_ = { ...v };

        if (v?.comission) this.comissions = [v?.comission];

        this.searchCourse(v?._course.name);

        if (this.shouldShow) this.checkEnrolDisciplines();
      }
    },
  },
  methods: {
    async saveDiscipline(d) {
      return await DisciplineService().create(d);
    },
    async updateDiscipline(d) {
      return await DisciplineService(d.uid).update(d);
    },
    async checkEnrolDisciplines() {
      if (!this.enrol?.disciplines?.length && this.enrol.status === "created") {
        confirmOnCenter(
          "Deseja sincronizar com as disciplinas do curso?",
          "Matrícula sem disciplinas"
        ).then(() => {
          const disciplines = this.enrol_._course.disciplines.map((d) => ({
            status: "waiting",
            name: d.name,
            period: d.period,
            workload: d.workload,
            enrol_id: this.enrol.uid,
            student_id: this.student.uid,
            uid: uuid.v4(),
          }));

          this.saveNewDisciplinesIntoEnrol(disciplines);
        });
      }
    },
    async saveNewDisciplinesIntoEnrol(disciplines) {
      if (Array.isArray(disciplines)) {
        this.enrol_.disciplines = disciplines;

        await Promise.all(
          disciplines.map((discipline) => this.saveDiscipline(discipline))
        );
      }
    },
    save() {
      this.isNew ? this.createEnrol() : this.updateEnrol();
    },
    async updateEnrol() {
      if (
        this.checkIfStatusWasChanged() &&
        this.enrol_.status in STATUS_TO_CREATE_EVENTS
      )
        await this.openEventModal(this.enrol_);

      this.isLoadingSubmit = true;

      fetch(`${this.$store.state.apiUrl}enrols/${this.enrol_.uid}`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({
          ...this.enrol_,
        }),
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(() => {
          this.showModal = false;
          ElNotification.success({
            title: "Matrícula atualizada com sucesso",
          });
        })
        .catch(async (error) => {
          error.then((e) =>
            ElNotification.error({
              title: "Não foi possível atualizar a matrícula",
              message: e.message,
            })
          );
        })
        .finally(() => {
          this.isLoadingSubmit = false;
          this.$emit("reload-data");
        });
    },
    checkIfStatusWasChanged() {
      return this.enrol_?.status !== this.enrol?.status;
    },
    openEventModal() {
      const self = this;
      return new Promise(function (resolve) {
        self.newEvent = {
          label: `Matricula ${STATUS_LABELS[self.enrol_.status]}`,
          description: `Curso: ${self.enrol?._course?.name}`,
          category: "info",
          visibility: "public",
        };
        self.continueUpdating = resolve;
        self.showAddEventModal = true;
      });
    },
    createEnrol() {
      this.isLoadingSubmit = true;
      fetch(`${this.$store.state.apiUrl}enrols`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          ...this.enrol_,
          student_uid: this.$route.params.id,
        }),
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(() => {
          this.showModal = false;
          ElNotification.success({
            title: "Matrícula realizada com sucesso",
          });
        })
        .catch(async (error) => {
          error.then((e) =>
            ElNotification.error({
              title: "Não foi possível realizar a matrícula",
              message: e.message,
            })
          );
        })
        .finally(() => {
          this.isLoadingSubmit = false;
          this.$emit("reload-data");
        });
    },
    fetchComissions(v) {
      if (v) {
        this.isLoadingFetchComissions = true;
        const url = new URL(`${this.$store.state.apiUrl}comissions`);
        const params = {
          queryName: v,
          school_uid: this?.$store?.state?.user?.selectedSchool?.uid,
        };
        url.search = new URLSearchParams(params);
        fetch(url, {
          credentials: "include",
          method: "GET",
        })
          .then((response) => {
            if (response.status == 200) return response.json();
            else throw response.json();
          })
          .then(
            (json) =>
              (this.comissions = json.filter(
                (j) => j?.school?.uid === this.enrol_?._course?.school?.uid
              ))
          )
          .catch((e) => console.log(e))
          .finally(() => (this.isLoadingFetchComissions = false));
      }
    },
    openAddCourse() {
      this.showAddCourseModal = true;
    },
    dontHasDisciplinesInPeriod(p) {
      return this.showDisciplinesByPeriod(p).length == 0;
    },
    showDisciplinesByPeriod(p) {
      return this?.MappedDisciplines?.filter((e) => e.period == p);
    },
    addDisciplineInPeriod(p) {
      if (this.isNew)
        this.enrol_._course.disciplines.push({
          period: p,
          uid: uuid.v4(),
        });
      else
        this.enrol_.disciplines.push({
          period: p,
          uid: uuid.v4(),
        });
    },
    removeDiscipline(uid) {
      if (this.isNew)
        this.enrol_._course.disciplines =
          this.enrol_._course.disciplines.filter((d) => d.uid != uid);
      else
        this.enrol_.disciplines = this.enrol_.disciplines.filter(
          (d) => d.uid != uid
        );
    },
    searchCourse(v) {
      if (v.length > 3) {
        this.isSearchCourseLoading = true;
        const url = new URL(`${this.$store.state.apiUrl}courses`);
        const params = {
          queryName: v,
          school_uid: this?.$store?.state?.user?.selectedSchool?.uid,
        };
        url.search = new URLSearchParams(params).toString();

        fetch(url, {
          credentials: "include",
        })
          .then((response) => (response.ok ? response.json() : response))
          .then((json) => (this.courses = json));
        setTimeout(() => {
          this.isSearchCourseLoading = false;
          this.searchQuery = v;
        }, 1000);
      } else this.searchQuery = "";
    },
  },
};
const STATUS_TO_CREATE_EVENTS = {
  canceled: true,
  finalized: true,
  locked: true,
  pending: true,
};
const STATUS_LABELS = {
  canceled: "Cancelada",
  finalized: "Concluída",
  pending: "Pendente",
  locked: "Trancada",
};
</script>
<style scoped>
.el-select {
  display: block !important;
}
div.discipline-completed {
  background-color: #e6ffff;
}
div.discipline-canceled {
  background-color: #ffcccb;
}
</style>
