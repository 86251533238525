<template>
  <modal v-model:show="showModal" body-classes="p-0" :showClose="true" modal-classes="modal-dialog-centered modal-lg">
    <card type="secondary" shadow header-classes="bg-white pb-1" body-classes="px-lg-5 py-lg-1" class="border-0">
      <template v-slot:header>
        <h4 class="modal-title" id="exampleModalLabel">
          Filtrar alunos
        </h4>
        <h6 class="text-muted">Informe os filtros e clique em filtrar</h6>
      </template>
      <form role="form">
        <!--<div class="row">
          <div class="col col-12">
            <div class="form-group row mb-2">
              <div class="col-3">
                <label class="col-form-label form-control-label"
                  >Estado do Boleto:</label
                >
              </div>
              <div class="col col-9">
                <el-select
                  type="secondary"
                  clearable
                  value-key="value"
                  placeholder="Selecione o estado do boleto"
                  v-model="filters.status"
                >
                  <el-option
                    v-for="s in PaymentStatus"
                    :key="s.value"
                    :label="s.name"
                    :value="s.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>-->

        <!--<div class="row">
          <div class="col col-12">
            <div class="form-group row mb-2">
              <div class="col-3">
                <label class="col-form-label form-control-label"
                  >Nível do Curso:</label
                >
              </div>
              <div class="col col-9">
                <el-select
                  type="secondary"
                  multiple
                  clearable
                  value-key="value"
                  placeholder="Filtrar apenas titulos que constam no repasse"
                  v-model="filters.level"
                >
                  <el-option
                    v-for="s in EducationalLevels"
                    :key="s.value"
                    :label="s.name"
                    :value="s.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>-->

        <div class="row">
          <div class="col col-12">
            <div class="form-group row mb-2">
              <div class="col-3">
                <label class="col-form-label form-control-label">Financeiro:</label>
              </div>
              <div class="col col-9">
                <el-select type="secondary" clearable value-key="value" placeholder="Alunos com ou sem financeiro" class="input-group-alternative"
                  v-model="filters.has_payments">
                  <el-option v-for="s in [
                    { value: true, label: 'Com financeiro' },
                    { value: false, label: 'Sem financeiro' },
                  ]" :key="s.value" :label="s.label" :value="s.value"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <!--ESTADO-->
            <div class="form-group row mb-2">
              <label class="col-lg-3 col-md-12 col-sm-12 col-form-label form-control-label">Estado:</label>
              <div class="col-lg-9 col-md-12 col-sm-12">
                <base-input formClasses="input-group-alternative" :modelValue="filters.addr_state" @update:modelValue="(val) => filters = {...filters, addr_state: val.toUpperCase().slice(0,2)}" placeholder="Digite as siglas do estado para busca"></base-input>
              </div>
            </div>
          </div>
        </div>

        <!--<div class="row">
          <div class="col col-12">
            <div class="form-group row mb-2">
              <div class="col-3">
                <label class="col-form-label form-control-label"
                  >Estado da matrícula:</label
                >
              </div>
              <div class="col col-9">
                <el-select
                  type="secondary"
                  clearable
                  value-key="value"
                  placeholder="Matrículas com ou sem financeiro"
                  v-model="filters.status"
                >
                  <el-option
                    v-for="s in [
                      { value: 'created', label: 'Criada' },
                      { value: 'progress', label: 'Cursando' },
                      { value: 'locked', label: 'Trancada' },
                      { value: 'canceled', label: 'Cancelada' },
                      { value: 'finalized', label: 'Concluída' },
                    ]"
                    :key="s.value"
                    :label="s.label"
                    :value="s.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>-->

        <!--<div class="row mb-2">
          <label class="col-10 col-form-label form-control-label"
            >Meses cursando:</label
          >
          <div class="col-2">
            <base-input
              formClasses="input-group-alternative"
              v-model="filters.months_in_progress"
              mask="##"
            ></base-input>
          </div>
        </div>

        <div class="row mb-2">
          <label class="col-10 col-form-label form-control-label"
            >Meses restantes:</label
          >
          <div class="col-2">
            <base-input
              formClasses="input-group-alternative"
              v-model="filters.months_left"
              mask="##"
            ></base-input>
          </div>
        </div>-->

        <base-collapse title="Filtrar por data de cadastro">
          <div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="row mb-2">
                  <label class="col-4 col-form-label form-control-label">Início:</label>
                  <div class="col-8">
                    <DatePicker v-model="filters.created_at_start" timezone="UTC" :model-config="{ timeAdjust: '00:00:00' }"
                      :popover="{ visibility: 'focus' }">
                      <template v-slot="{ inputValue, inputEvents }">
                        <base-input formClasses="input-group-alternative" :value="inputValue" v-on="inputEvents"
                          placeholder="Selecione no calendário"></base-input>
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="row mb-2">
                  <label class="col-4 col-form-label form-control-label">Fim:</label>
                  <div class="col-8">
                    <DatePicker v-model="filters.created_at_end" timezone="UTC" :model-config="{ timeAdjust: '00:00:00' }"
                      :popover="{ visibility: 'focus' }">
                      <template v-slot="{ inputValue, inputEvents }">
                        <base-input formClasses="input-group-alternative" :value="inputValue" v-on="inputEvents"
                          placeholder="Selecione no calendário"></base-input>
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </base-collapse>

        <base-collapse title="Filtrar por data de nascimento">
          <div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="row mb-2">
                  <label class="col-4 col-form-label form-control-label">Início:</label>
                  <div class="col-8">
                    <DatePicker v-model="filters.birthday_start" timezone="UTC" :model-config="{ timeAdjust: '00:00:00' }"
                      :popover="{ visibility: 'focus' }">
                      <template v-slot="{ inputValue, inputEvents }">
                        <base-input formClasses="input-group-alternative" :value="inputValue" v-on="inputEvents"
                          placeholder="Selecione no calendário"></base-input>
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="row mb-2">
                  <label class="col-4 col-form-label form-control-label">Fim:</label>
                  <div class="col-8">
                    <DatePicker v-model="filters.birthday_end" timezone="UTC" :model-config="{ timeAdjust: '00:00:00' }"
                      :popover="{ visibility: 'focus' }">
                      <template v-slot="{ inputValue, inputEvents }">
                        <base-input formClasses="input-group-alternative" :value="inputValue" v-on="inputEvents"
                          placeholder="Selecione no calendário"></base-input>
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </base-collapse>
      </form>
      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button size="sm" @click="showModal = false" class="float-right" type="danger">CANCELAR</base-button>
          <div class="row mr-2">
            <base-button size="sm" @click="cleanFilter" class="float-right" type="secondary">LIMPAR</base-button>
            <base-button size="sm" @click="updateFilter" class="float-right" type="primary">FILTRAR</base-button>
          </div>
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
import BaseButton from "../../components/BaseButton.vue";
import BaseCollapse from "../../components/BaseCollapse.vue";
import { ElSelect, ElOption } from "element-plus";
import { DatePicker } from "v-calendar";
export default {
  components: { BaseButton, ElSelect, ElOption, BaseCollapse, DatePicker },
  name: "filter-students-modal",
  props: {
    shouldShow: {
      type: Boolean,
    },
  },
  computed: {
    FilterAddrState:{
      get: function(){
        return this.filters.addr_state;
      },
      set: function(val){
        this.filters.addr_state = val.toUpperCase().slice(0, 2);
      },
    },
    showModal: {
      get: function () {
        return this.shouldShow;
      },
      set: function () {
        this.$emit("close-modal", false);
      },
    },
    EducationalLevels() {
      return this.$store.state.constants.educationalLevels;
    },
    PaymentStatus() {
      return [
        { value: "closed", name: "Fechado" },
        { value: "opened", name: "Aberto" },
        { value: "paid", name: "Pago" },
      ];
    },
  },
  data() {
    return {
      filters: {},
    };
  },
  emits: ["update-filter", "close-modal"],
  methods: {
    updateFilter() {
      this.$emit("update-filter", this.filters);
      this.$emit("close-modal");
    },
    cleanFilter() {
      this.filters = {};
      this.updateFilter();
    },
  },
};
</script>
<style scoped>
.el-select {
  display: block;
}

a.dropdown-item {
  cursor: pointer;
}
</style>
