<template>
  <div>
    <dashboard-navbar
      searchHint="Buscar tarefas"
      @updateSearch="updateSearch"
    ></dashboard-navbar>

    <div class="container-fluid d-flex h-100 flex-column pt-6 px-2">
      <base-loader v-if="isLoading"></base-loader>
      <projects-table
        v-else
        type="hover"
        title="Minhas tarefas"
        :tableData="tasks"
        @showModal="showTaskModal = $event"
      >
        <template #actions>
          <base-button
            type="primary"
            class="mt-2"
            icon="fa fa-plus-square"
            size="sm"
            v-on:click.stop.prevent="openTaskModal()"
          ></base-button>
        </template>
        <template #columns>
          <th v-for="col in TableColumns" :key="col">
            {{ col.label }}
            <span
              class="caret-wrapper"
              v-if="col?.sortable"
              @click="sortColumn(col)"
            >
            </span>
          </th>
        </template>

        <template #data v-if="enrols?.length">
          <tr
            v-for="(c, id) in enrols"
            :key="id"
            :class="c?.completed_at ? 'completed' : 'created'"
          >
            <td>
              {{ c?.title }}
            </td>
            <td>
              {{ c?.student?.name }}
            </td>
            <td>
              {{ c?.description }}
            </td>
            <td>
              <div class="btn-group float-right">
                <base-button
                  type="primary"
                  size="sm"
                  icon="fa fa-external-link-alt"
                  @click="navigateToRoute(c.student)"
                ></base-button>
                <base-button
                  size="sm"
                  @click="openAddObsModal(c?.student)"
                  type="primary"
                >
                  <i class="ni ni-book-bookmark"></i>
                </base-button>
                <base-button
                  size="sm"
                  icon="fa fa-edit"
                  @click="openTaskModal(c)"
                  type="primary"
                ></base-button>
                <el-popconfirm
                  title="Remover tarefa?"
                  confirm-button-text="sim"
                  cancel-button-text="não"
                  @confirm="() => {}"
                >
                  <template #reference>
                    <base-button size="sm" type="danger"
                      ><i class="fa fa-trash"></i
                    ></base-button>
                  </template>
                </el-popconfirm>
              </div>
            </td>
          </tr>
        </template>

        <template #pagination>
          <base-pagination
            @input="pagination = $event"
            :value="pagination"
            :perPage="limit"
            :total="total"
          ></base-pagination>
        </template>
      </projects-table>
    </div>
    <add-student-observation-modal-vue
      :shouldShow="showAddObsModal"
      @close-modal="showAddObsModal = false"
    >
    </add-student-observation-modal-vue>
    <task-modal
      :shouldShow="showTaskModal"
      @close-modal="showTaskModal = false"
    ></task-modal>
  </div>
</template>

<script>
/** External dependencies */
import "vue-loaders/dist/vue-loaders.css";
import { ElPopconfirm } from "element-plus";

/** Internal dependencies */
import DashboardNavbar from "../layout/DashboardNavbar.vue";
import ProjectsTable from "./Tables/ProjectsTable.vue";
import UserService from "../services/users";
import BaseLoader from "../components/BaseLoader.vue";
import { cpfFormatter, phoneFormatter } from "../utils/formatters";
import AddStudentObservationModalVue from "./Modals/AddStudentObservationModal.vue";
import TaskModal from "./Modals/TaskModal.vue";

// @ is an alias to /src

export default {
  name: "Tasks",
  components: {
    DashboardNavbar,
    ProjectsTable,
    BaseLoader,
    ElPopconfirm,
    AddStudentObservationModalVue,
    TaskModal,
  },
  data() {
    return {
      isLoading: true,
      tasks: null,
      showTaskModal: false,
      showAddObsModal: false,
      searchQuery: "",
      cpfFormatter,
      phoneFormatter,
    };
  },
  created() {
    this.loadTasks();
  },
  watch: {
    SelectedSchool() {
      this.pagination = 1;
      this.searchQuery = "";
      this.loadTasks();
    },
    pagination() {
      this.loadTasks();
    },
  },
  computed: {
    SelectedSchool() {
      return this?.$store?.state?.user?.selectedSchool;
    },
    TableColumns: () => [
      {
        label: "título",
        prop: "title",
      },
      {
        label: "aluno",
        prop: "students.name",
      },
      {
        label: "descrição",
        prop: "description",
      },
      {
        label: "ações",
        sortable: false,
      },
    ],
  },
  methods: {
    async loadTasks() {
      this.isLoading = true;
      const { tasks } = await UserService().Task().index();

      if (tasks) this.tasks = tasks;

      this.isLoading = false;
    },
    openTaskModal() {
      this.showTaskModal = true;
    },
    openAddObsModal() {
      this.showAddObsModal = true;
    },
    updateSearch(searchQuery) {
      this.searchQuery = searchQuery;
      this.loadTasks();
    },
    navigateToRoute(items) {
      let route = this.$router.resolve(`/alunos/${items.uid}`);
      window.open(route.href, "_blank");
    },
  },
};
</script>
<style>
tr.completed {
  background-color: #e0f2f1;
}
tr.created {
  background-color: #eeffff;
}
</style>