<template>
  <div>
    <div class="table-responsive">
      <div class="row justify-content-end m-1">
        <div class="col-4">
          <el-select
            v-model="selectedLevel"
            placeholder="Selecione aqui o nível de ensino"
            multiple
          >
            <el-option
              v-for="level in EducationalLevels"
              :key="level"
              :value="level.value"
              >{{ level.value }}</el-option
            >
          </el-select>
        </div>
      </div>
      <base-table
        thead-classes="thead-light"
        tbody-classes="list"
        type="hover"
        :limit="limit"
        :data="LimitedRows"
        :offset="(pagination - 1) * limit"
      >
        <template v-slot:columns>
          <th v-for="col in StudentEnrolsColumns" :key="col">
            {{ col }}
          </th>
        </template>

        <template #data v-if="LimitedRows?.length">
          <tr
            v-for="enrol in LimitedRows"
            :key="enrol.uid"
            :class="`status-${enrol.status}`"
          >
            <td>
              <h5>
                {{
                  enrol.start_in
                    ? new Date(enrol.start_in).toLocaleDateString()
                    : new Date(enrol.created_at).toLocaleDateString()
                }}
              </h5>
            </td>
            <td>
              <h5>
                {{ enrol.code }}
              </h5>
            </td>
            <td>
              <h5>
                {{ enrol._course.name }}
              </h5>
            </td>
            <td>
              <h5>
                {{ enrol.periods_formatted }}
              </h5>
            </td>
            <td>
              <h5>
                {{
                  new Date(
                    enrol?.periods_?.find((e, i, a) => i == a?.length - 1)
                      ?.starts_at || enrol.start_in
                  )?.toLocaleDateString()
                }}
              </h5>
            </td>
            <td>
              <h5>
                {{
                  new Date(
                    enrol?.periods_?.find((e, i, a) => i == a?.length - 1)
                      ?.expires_at || enrol.expires_at
                  )?.toLocaleDateString()
                }}
              </h5>
            </td>
            <td>
              <div class="btn-group float-right">
                <base-button
                  size="sm"
                  type="primary"
                  icon="fa fa-copy"
                  :disabled="
                    enrol.status in
                    { finalized: true, locked: true, canceled: true }
                  "
                  @click.stop="showReEnrollment(enrol)"
                ></base-button>
                <base-button
                  size="sm"
                  type="primary"
                  icon="fa fa-dollar"
                  tooltip="Renegociar matrícula"
                  @click.stop="showRenegotiate(enrol)"
                ></base-button>
                <base-button
                  size="sm"
                  type="primary"
                  icon="fa fa-edit"
                  tooltip="Editar matrícula"
                  @click.stop="openEnrolModal(enrol)"
                ></base-button>
                <base-button
                  size="sm"
                  type="primary"
                  icon="fa fa-info"
                  tooltip="Informações da matrícula"
                  @click.stop="openEnrolDetailsModal(enrol)"
                ></base-button>

                <el-popconfirm
                  title="Remover matrícula? Os títulos de pagamento também serão removidos"
                  confirm-button-text="sim"
                  cancel-button-text="não"
                  @confirm="deleteEnrol(enrol)"
                >
                  <template #reference>
                    <base-button
                      icon="fa fa-trash"
                      size="sm"
                      type="danger"
                    ></base-button>
                  </template>
                </el-popconfirm>
              </div>
            </td>
          </tr>
        </template>
      </base-table>
    </div>
    <div class="row justify-content-end pr-4">
      <base-pagination
        @input="pagination = $event"
        :value="pagination"
        :perPage="limit"
        :total="enrols.length"
        :showTotalPagination="false"
      ></base-pagination>
    </div>

    <reenroll-modal
      :shouldShow="showReEnrollmentModal"
      :studentData="student"
      :enrolData="selectedEnrol"
      @reload-data="reloadEnrols"
      @close-modal="showReEnrollmentModal = false"
    ></reenroll-modal>
    <renegotiate-enrol-modal
      :shouldShow="showRenegotiateEnrolModal"
      :enrol="selectedEnrol"
      :payments="EnrolPayments"
      @reload-data="reloadEnrols"
      @close-modal="showRenegotiateEnrolModal = false"
    ></renegotiate-enrol-modal>
    <enrol-modal
      :shouldShow="showEnrolModal"
      :enrol="selectedEnrol"
      :student="student"
      @reload-data="reloadEnrols"
      @close-modal="showEnrolModal = false"
    ></enrol-modal>
    <enrol-details-modal
      :shouldShow="showEnrolDetailsModal"
      :enrol_uid="selectedEnrol?.uid"
      @reload-data="reloadEnrols"
      @close-modal="showEnrolDetailsModal = false"
    ></enrol-details-modal>
  </div>
</template>
<script>
//import BaseButton from "../../components/BaseButton.vue";

//import dayGridPlugin from "@fullcalendar/daygrid";
//import interactionPlugin from "@fullcalendar/interaction"
//import timeGridDay from "@fullcalendar/timegrid";

import ReEnrollModal from "../Modals/ReEnrollModal.vue";
import RenegotiateEnrolModal from "../Modals/RenegotiateEnrolModal.vue";
import EnrolModal from "../Modals/AddEnrollModal.vue";
import EnrolDetailsModal from "../Modals/EnrolDetailsModal.vue";
import { ElNotification, ElPopconfirm, ElSelect, ElOption } from "element-plus";

export default {
  name: "student-details-tab-finance",
  components: {
    "reenroll-modal": ReEnrollModal,
    "renegotiate-enrol-modal": RenegotiateEnrolModal,
    ElPopconfirm,
    EnrolModal,
    EnrolDetailsModal,
    ElSelect,
    ElOption,
  },
  props: {
    enrols: {
      default: [],
      description: "list of enrols of selected student",
    },
    student: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["should-update"],
  data() {
    return {
      pagination: 1,
      limit: 10,
      showReEnrollmentModal: false,
      enrollmentUid: "",
      showRenegotiateEnrolModal: false,
      selectedEnrol: null,
      showEnrolModal: false,
      showEnrolDetailsModal: false,
      selectedLevel: [],
    };
  },
  computed: {
    EducationalLevels() {
      return this.$store.state.constants.educationalLevels;
    },
    StudentEnrolsColumns: function () {
      return [
        "data da matrícula",
        "código",
        "curso",
        "periodos/total",
        "inicio",
        "termino",
        "ações",
      ];
    },
    EnrolPayments() {
      if (!Array.isArray(this?.student?.payments) && !this.selectedEnrol)
        return [];

      return this.student.payments.filter(
        (p) => p?.enrol?.uid === this?.selectedEnrol?.uid
      );
    },
    FilteredRows() {
      if (this.selectedLevel?.length) {
        const self = this;
        return this.enrols.filter((e) =>
          self.selectedLevel.includes(e._course.level)
        );
      }
      return this.enrols;
    },
    LimitedRows() {
      return this.FilteredRows.slice(this.Offset, this.Offset + this.limit);
    },
    Offset() {
      return (this.pagination - 1) * this.limit;
    },
  },
  methods: {
    showReEnrollment(v) {
      if (v) {
        this.selectedEnrol = v;
        this.showReEnrollmentModal = true;
      }
    },
    openEnrolDetailsModal(e) {
      if (e) {
        this.selectedEnrol = e;
        this.showEnrolDetailsModal = true;
      }
    },
    openEnrolModal(e) {
      if (e) {
        this.selectedEnrol = {
          course: {
            ...e._course,
            disciplines: e.disciplines,
            school: e.school,
          },
          ...e,
        };
        this.showEnrolModal = true;
      }
    },
    showRenegotiate(v) {
      this.showRenegotiateEnrolModal = true;
      this.selectedEnrol = v;
    },
    deleteEnrol(e) {
      fetch(this.$store.state.apiUrl + "enrols/" + e.uid, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.$emit("should-update");

            return response.json();
          }

          throw response.json();
        })
        .then((e) => {
          if (isPromise(e))
            e.then((e) =>
              ElNotification.success({
                title: e.message,
              })
            );
          else
            ElNotification.success({
              title: e.message,
            });
        })
        .catch((e) =>
          ElNotification.error({
            message: e.message || "Não foi possível remover essa matrícula",
          })
        );
    },
    reloadEnrols() {
      this.$emit("should-update");
    },
  },
};
function isPromise(promise) {
  return !!promise && typeof promise.then === "function";
}
</script>
<style>
tr.status-created {
  background-color: #eeffff;
}
tr.status-canceled {
  background-color: #ffebee;
}
tr.status-locked {
  background-color: #eceff1;
}
</style>
