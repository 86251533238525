<template>
  <div>
    <dashboard-navbar searchHint="Buscar curso..."></dashboard-navbar>
    <div class="container-fluid d-flex h-100 flex-column pt-6 px-2">
      <base-loader v-if="isLoading"></base-loader>
      <card
        type="secondary"
        shadow
        header-classes="bg-white pb-1"
        body-classes="px-lg-1 py-lg-1"
        class="border-0 mt-4"
        footerClasses="p-0"
        v-else-if="!hasError"
      >
        <template #header>
          <div class="row align-items-center">
            <div class="col">
              <h2>{{ student.name }}</h2>
            </div>
            <div class="col text-right">
              <base-button
                type="primary"
                icon="fa fa-pencil"
                title="Editar aluno"
                size="sm"
                v-on:click="openEditStudent"
              ></base-button>
              <base-button
                type="primary"
                icon="ni ni-single-copy-04"
                title="Cadastrar matricula"
                size="sm"
                v-on:click="openAddEnrol"
              ></base-button>
              <base-button
                type="primary"
                icon="ni ni-email-83"
                size="sm"
                disabled
                v-on:click="onClick"
              ></base-button>
              <el-popconfirm
                title="Tem certeza? Matrículas e Pagamentos também serão removidos"
                confirm-button-type="danger"
                confirm-button-text="Deletar Tudo!"
                @confirm="deleteStudent"
                cancel-button-text="Cancelar"
              >
                <template #reference>
                  <base-button
                    type="danger"
                    title="Excluir aluno"
                    size="sm"
                    icon="fa fa-trash"
                  ></base-button>
                </template>
              </el-popconfirm>
            </div>
          </div>
        </template>

        <tabs pills @TabActive="selectedTab = $event">
          <tab-pane title="Dados Cadastrais" id="personal"> </tab-pane>
          <tab-pane title="Matrículas" id="enrols"> </tab-pane>
          <tab-pane title="Financeiro" id="finance"> </tab-pane>
          <tab-pane title="Agenda" id="agenda"> </tab-pane>
          <tab-pane title="Observações" id="obs"></tab-pane>
        </tabs>

        <template #footer>
          <template v-if="selectedTab == 'personal'">
            <div class="px-2">
              <student-details-tab-personal
                :student="student"
              ></student-details-tab-personal>
            </div>
          </template>

          <template v-if="selectedTab == 'enrols'">
            <student-details-tab-enrols
              :enrols="student.enrols"
              :student="student"
              @should-update="fetchStudent"
            ></student-details-tab-enrols>
          </template>

          <template v-if="selectedTab == 'finance'">
            <student-details-tab-finance
              @should-update="fetchStudent"
              :payments="student.payments"
              :enrols="student?.enrols"
            ></student-details-tab-finance>
          </template>

          <template v-if="selectedTab == 'agenda'">
            <student-details-tab-agenda
              :student="student"
              @should-update="fetchStudent"
            ></student-details-tab-agenda>
          </template>

          <template v-if="selectedTab == 'obs'">
            <student-details-tab-obs
              :student="student"
              @should-update="fetchStudent"
            ></student-details-tab-obs>
          </template>
        </template>
      </card>
      <base-error v-else details="O aluno não foi encontrado"></base-error>
    </div>

    <add-student-modal
      v-if="student"
      :shouldShow="showEditStudentModal"
      :value="student"
      @reload-data="fetchStudent()"
      @close-modal="showEditStudentModal = false"
    ></add-student-modal>

    <add-enrol-modal
      :shouldShow="showAddEnrolModal"
      @reload-data="fetchStudent()"
      @close-modal="showAddEnrolModal = false"
      :student="student"
    ></add-enrol-modal>
  </div>
</template>

<script>
//import Card from "../components/Card.vue";
import DashboardNavbar from "../layout/DashboardNavbar.vue";
import "vue-loaders/dist/vue-loaders.css";
import BaseLoader from "../components/BaseLoader.vue";
import Card from "../components/Card.vue";
//import BasePagination from "../components/BasePagination.vue";
import StudentDetailsTabAgenda from "../views/Tabs/StudentDetailsAgenda.vue";
import StudentDetailsTabObs from "../views/Tabs/StudentDetailsObs.vue";
import StudentDetailsTabPersonal from "../views/Tabs/StudentDetailsPersonal.vue";
import StudentDetailsTabFinance from "../views/Tabs/StudentDetailsFinance.vue";
import StudentDetailsTabEnrols from "../views/Tabs/StudentDetailsEnrols.vue";
import AddStudentModal from "../views/Modals/AddStudentModal.vue";
import AddEnrolModal from "./Modals/AddEnrollModal.vue";
import BaseError from "../components/BaseError.vue";
import { ElNotification, ElPopconfirm } from "element-plus";

// @ is an alias to /src

export default {
  name: "StudentDetails",
  components: {
    DashboardNavbar,
    BaseLoader,
    Card,
    StudentDetailsTabEnrols,
    StudentDetailsTabFinance,
    StudentDetailsTabAgenda,
    StudentDetailsTabObs,
    StudentDetailsTabPersonal,
    AddStudentModal,
    AddEnrolModal,
    BaseError,
    ElPopconfirm,
  },
  data() {
    return {
      isLoading: true,
      selectedTab: "",
      pagination: 1,
      limit: 10,
      showEditStudentModal: false,
      showAddEnrolModal: false,
      student: null,
      hasError: false,
    };
  },
  created() {
    this.fetchStudent();
  },
  methods: {
    deleteStudent() {
      fetch(`${this.$store.state.apiUrl}students/${this?.student?.uid}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(() => {
          this.showModal = false;
          ElNotification.success({
            title: "Aluno removido com sucesso",
          });
        })
        .catch(async (error) => {
          error.then((e) =>
            ElNotification.error({
              title: "Não foi possível remover o aluno",
              message: e.message,
            })
          );
        })
        .finally(() => {
          this.$router.go(-1);
        });
    },
    openEditStudent() {
      this.showEditStudentModal = true;
      this.$forceUpdate();
    },
    fetchStudent() {
      fetch(
        `${this.$store.state.apiUrl}students/${this.$route.params.id}/uid`,
        {
          credentials: "include",
          method: "GET",
        }
      )
        .then((response) => {
          if (response.status === 200) return response.json();
          throw response.json();
        })
        .then((json) => (this.student = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
    openAddEnrol() {
      this.showAddEnrolModal = true;
    },
    onClick: function () {},
  },
};
</script>
