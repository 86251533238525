<template>
  <modal
    @update:show="showModal = $event"
    :show="showModal"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white p-3"
      body-classes="px-lg-3 py-lg-1"
      class="border-0"
    >
      <template #header>
        <h3 class="modal-title">
          {{ enrol?._course?.name }}
        </h3>
        <h6 class="text-muted">
          Rematrícula período {{ enrol?.current_period }} de
          {{ enrol?.periods || "1" }}.
        </h6>
      </template>

      <div class="row pl-3 justify-content-between">
        <div class="text-left mb-2">
          <h5>Início e Fim do Período:</h5>
        </div>
      </div>
      <!--Obs-->
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <DatePicker
            v-model="enrol.start"
            timezone="UTC"
            :model-config="{ timeAdjust: '12:00:00' }"
            :popover="{ visibility: 'focus' }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="form-group row mb-2">
                <label
                  class="col-lg-3 col-md-12 col-sm-12 col-form-label form-control-label"
                  >Início:</label
                >
                <div class="col-lg-9 col-md-12 col-sm-12">
                  <base-input
                    formClasses="input-group-alternative"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="25/02/2021"
                  ></base-input>
                </div>
              </div>
            </template>
          </DatePicker>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="form-group row mb-2">
            <label
              class="col-lg-3 col-md-12 col-sm-12 col-form-label form-control-label"
              >Fim:</label
            >
            <div class="col-lg-9 col-md-12 col-sm-12">
              <base-input
                formClasses="input-group-alternative"
                :value="formatDate(enrol.end)"
                disabled
                placeholder="25/02/2021"
              ></base-input>
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown-divider"></div>
      <div class="row pl-3 justify-content-between">
        <div class="text-left mb-2">
          <h5>Período de Provas:</h5>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <DatePicker
            v-model="reenrol.exam_starts_in"
            :min-date="enrol.start ? new Date(enrol.start) : new Date()"
            :model-config="{ timeAdjust: '12:00:00' }"
            :popover="{ visibility: 'focus' }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="form-group row mb-2">
                <label
                  class="col-lg-3 col-md-12 col-sm-12 col-form-label form-control-label"
                  >Início:</label
                >
                <div class="col-lg-9 col-md-12 col-sm-12">
                  <base-input
                    formClasses="input-group-alternative"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="25/02/2021"
                  ></base-input>
                </div>
              </div>
            </template>
          </DatePicker>
        </div>

        <div class="col-lg-6 col-md-12">
          <DatePicker
            v-model="reenrol.exam_expires_in"
            ref="exam_expires_in"
            :max-date="enrol.end ? new Date(enrol.end) : new Date()"
            :model-config="{ timeAdjust: '12:00:00' }"
            :popover="{
              visibility: 'focus',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="form-group row mb-2">
                <label
                  class="col-lg-3 col-md-12 col-sm-12 col-form-label form-control-label"
                  >Fim:</label
                >
                <div class="col-lg-9 col-md-12 col-sm-12">
                  <base-input
                    formClasses="input-group-alternative"
                    :value="inputValue"
                    v-on="inputEvents"
                    @focus="moveToSelectedDateMonth"
                    placeholder="25/02/2021"
                  ></base-input>
                </div>
              </div>
            </template>
          </DatePicker>
        </div>
      </div>

      <div class="dropdown-divider"></div>
      <div class="row pl-3 justify-content-between">
        <div class="text-left mb-2">
          <h5>Disciplinas do Período Atual:</h5>
        </div>
      </div>

      <div
        v-for="d in ActualDisciplines"
        :key="d.uid"
        :class="{
          row: true,
          'discipline-completed': d.status === 'completed',
          'discipline-canceled': d.status === 'canceled',
        }"
      >
        <div class="col-md-5 col-sm-12">
          <!--Nome da Disciplina-->
          <div class="row mb-2">
            <label class="col-4 col-form-label form-control-label">Nome:</label>
            <div class="col-8">
              <base-input
                v-model="d.name"
                formClasses="input-group-alternative"
                disabled
                placeholder="..."
              ></base-input>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-sm-12">
          <!--C/H da disciplina-->
          <div class="row mb-2">
            <label class="col-4 col-form-label form-control-label">C/H:</label>
            <div class="col-8">
              <base-input
                disabled
                v-model="d.workload"
                formClasses="input-group-alternative"
                placeholder="60"
              ></base-input>
            </div>
          </div>
        </div>
        <div
          class="col-md-2 col-sm-12 d-flex align-items-center justify-content-end"
        >
          <div class="row pr-1">
            <base-button
              iconOnly
              rounded
              class="btn"
              link
              size="sm"
              type="secondary"
              @click="setCompleted(d)"
              ><i class="ni ni-check-bold" style="color: blue"></i
            ></base-button>

            <base-button
              iconOnly
              rounded
              class="btn"
              link
              size="sm"
              type="secondary"
              @click="setCanceled(d)"
              ><i class="ni ni-fat-delete" style="color: red"></i
            ></base-button>
          </div>
        </div>
      </div>

      <template v-if="ActualDisciplines.length == 0">
        <div class="list-group py-2">
          <h5 class="text-muted mx-auto">
            Nenhuma disciplina foi cadastrada no período atual
          </h5>
        </div>
      </template>

      <div class="dropdown-divider"></div>
      <div class="row pl-3 justify-content-between mb-1">
        <div class="text-left mb-2">
          <h5>Disciplinas do Próximo Período:</h5>
        </div>
        <base-button
          outline
          size="sm"
          type="secondary"
          @click="addDiscipline({ period: enrol.current_period + 1 })"
          >ADICIONAR</base-button
        >
      </div>

      <div class="list-group" v-if="!NextDisciplines?.length && !isLastPeriod">
        <h5 class="text-muted mx-auto">
          Nenhuma disciplina cadastrada para o próximo período
        </h5>
      </div>

      <div class="row" v-for="d in NextDisciplines" :key="d.uid">
        <div class="col-md-6 col-sm-12">
          <!--Nome da Disciplina-->
          <div class="row mb-2">
            <label class="col-4 col-form-label form-control-label">Nome:</label>
            <div class="col-8">
              <base-input
                v-model="d.name"
                formClasses="input-group-alternative"
                placeholder="Introdução ao Cálculo"
              ></base-input>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-sm-12">
          <!--C/H da disciplina-->
          <div class="row mb-2">
            <label class="col-4 col-form-label form-control-label">C/H:</label>
            <div class="col-8">
              <base-input
                v-model="d.workload"
                formClasses="input-group-alternative"
                placeholder="60"
              ></base-input>
            </div>
          </div>
        </div>
        <div class="col-md-1 col-sm-12 d-flex align-items-center float-right">
          <base-button
            iconOnly
            outline
            rounded
            class="btn btn-outlined"
            link
            size="sm"
            type="secondary"
            @click="removeDiscipline(d.uid)"
            ><i class="fa fa-trash" style="color: red"></i
          ></base-button>
        </div>
      </div>

      <template v-if="NextDisciplines.length == 0 && isLastPeriod">
        <div class="list-group">
          <h5 class="text-muted mx-auto">O aluno chegou ao final do curso</h5>
        </div>
      </template>

      <template v-if="!isLastPeriod && !CourseHasNoPeriods">
        <div class="dropdown-divider"></div>
        <div class="row pl-3 justify-content-between">
          <div class="text-left mb-2">
            <h5>Adicionar Parcelas:</h5>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-12">
            <!--Obs-->
            <div class="form-group row mb-2">
              <label
                class="col-lg-6 col-md-12 col-sm-12 col-form-label form-control-label"
                >Valor Total:</label
              >
              <div class="col-lg-6 col-md-12 col-sm-12">
                <base-input
                  formClasses="input-group-alternative"
                  placeholder="R$ 59,95"
                  v-model="reenrol.cost"
                  type="money"
                ></base-input>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <!--Obs-->
            <div class="form-group row mb-2">
              <label
                class="col-lg-6 col-md-12 col-sm-12 col-form-label form-control-label"
                >Parcelas:</label
              >
              <div class="col-lg-6 col-md-12 col-sm-12">
                <base-input
                  mask="#*"
                  v-model="reenrol.total"
                  formClasses="input-group-alternative"
                  placeholder="12 x"
                ></base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-12">
            <!--Obs-->
            <div class="form-group row mb-2">
              <label
                class="col-lg-6 col-md-12 col-sm-12 col-form-label form-control-label"
                >Forma de Pagamento:</label
              >
              <div class="col-lg-6 col-md-12 col-sm-12">
                <base-dropdown
                  type="secondary"
                  hint="Método de Parcelamento:"
                  v-model="reenrol.payment_type"
                  :items="PaymentTypes"
                  menuClasses="col-12"
                >
                </base-dropdown>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <!--Obs-->
            <div class="form-group row mb-2">
              <label
                class="col-lg-6 col-md-12 col-sm-12 col-form-label form-control-label"
                >Primeiro Pagamento:</label
              >
              <div class="col-lg-6 col-md-12 col-sm-12">
                <DatePicker
                  v-model="reenrol.payment_start"
                  timezone="UTC"
                  :model-config="{ timeAdjust: '12:00:00' }"
                  :popover="{ visibility: 'focus' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <base-input
                      formClasses="input-group-alternative"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="25/02/2021"
                    ></base-input>
                  </template>
                </DatePicker>
              </div>
            </div>
          </div>
        </div>
        <!--Obs-->
        <div class="form-group row mb-2">
          <label
            class="col-lg-3 col-md-12 col-sm-12 col-form-label form-control-label"
            >Comissão:</label
          >
          <div class="col-lg-9 col-md-12 col-sm-12">
            <el-select
              v-model="reenrol.comission"
              clearable
              remote
              value-key="uid"
              :loading="isLoadingFetchComissions"
              @visible-change="fetchComissions"
              class="input-group-alternative"
              no-data-text="Não existem comissões cadastradas"
              loading-text="Buscando comissões no servidor..."
              placeholder="Selecione a comissão para cadastrar os títulos"
            >
              <div>
                <el-option
                  v-for="item in comissions"
                  :key="item.uid"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </div>
            </el-select>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="row justify-content-between noMargin">
          <div class="row justify-content-end noMargin">
            <base-button
              size="sm"
              @click="showModal = false"
              class="float-right"
              type="danger"
              >CANCELAR</base-button
            >
            <base-button
              size="sm"
              class="float-right"
              type="warning"
              @click="cancelPeriod"
              :disabled="!ActualPeriod"
              >RETROCEDER PERIODO</base-button
            >
          </div>

          <popover
            placement="bottom"
            :width="350"
            :trigger="hasErrors ? 'hover' : 'manual'"
          >
            <div class="align-items-center p-1">
              <div class="row justify-content-center align-items-center">
                <i
                  class="fa fa-exclamation-circle fa-lg mr-3"
                  style="color: red"
                ></i>
                <h4>Cuidado!</h4>
              </div>

              <div class="row justify-content-center">
                <h6 v-if="hasDebits">
                  Existem débitos relacionados à esta matrícula
                </h6>
              </div>
            </div>
            <template #reference>
              <base-button
                size="sm"
                @click="() => save(isFirstPeriod)"
                class="float-right"
                type="primary"
                v-if="isLastPeriod || isFirstPeriod"
                :isLoading="isLoadingSubmit"
                id="reenrol-button"
                >{{
                  isLastPeriod
                    ? "CONCLUIR"
                    : isFirstPeriod
                    ? "DEFERIR"
                    : "REMATRICULAR"
                }}</base-button
              >
              <el-popconfirm
                v-else
                title="Deseja avançar o período?"
                position="top"
                placement="top"
                confirm-button-type="primary"
                confirm-button-text="Sim"
                @confirm="() => save(true)"
                @cancel="() => save(false)"
                cancel-button-text="Não, só salvar"
                cancel-button-type="warning"
              >
                <template #reference>
                  <base-button
                    size="sm"
                    class="float-right"
                    type="primary"
                    :isLoading="isLoadingSubmit"
                    id="reenrol-button"
                    >{{
                      isLastPeriod
                        ? "CONCLUIR"
                        : isFirstPeriod
                        ? "DEFERIR"
                        : "REMATRICULAR"
                    }}</base-button
                  >
                </template>
              </el-popconfirm>
            </template>
          </popover>
        </div>
      </template>
    </card>
  </modal>
</template>
<script>
import { DatePicker } from "v-calendar";
import { uuid } from "vue-uuid";
import EnrolPeriodService from "../../services/periods.js";
import {
  ElPopover,
  ElSelect,
  ElOption,
  ElNotification,
  ElPopconfirm,
  //  ElScrollbar,
} from "element-plus";
import ObservationService from "../../services/observations";
export default {
  name: "reenrollment-modal",
  components: {
    DatePicker,
    popover: ElPopover,
    ElSelect,
    ElPopconfirm,
    ElOption,
    //   ElScrollbar,
  },
  props: ["shouldShow", "enrolData", "studentData"],
  data() {
    return {
      reenrol: {},
      enrol: { ...(this.enrolData || {}) },
      intlDate: new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
      }),
      student: {
        ...(this.studentData || {}),
      },
      isLoadingFetchComissions: false,
      comissions: [],
      isLoadingSubmit: false,
    };
  },
  watch: {
    enrolData(v) {
      this.enrol = { ...(v || {}) };
      this.reenrol = {};
      if (v?.comission) {
        this.comissions = [v.comission];
        this.reenrol.comission = v.comission;
      }
    },
    "enrol.start": function (v) {
      const newDate = new Date(v);
      if (newDate != "Invalid Date") {
        newDate.setMonth(
          newDate.getMonth() +
            this.enrol._course.duration / (this.enrol._course.periods || 1)
        );
        newDate.setDate(newDate.getDate() - 1);
        this.enrol.end = newDate;
      }
    },
  },
  computed: {
    PaymentTypes() {
      return this?.$store?.state?.constants?.paymentTypes || [];
    },
    showModal: {
      get() {
        return this.shouldShow;
      },
      set(v) {
        this.$emit("close-modal", v);
      },
    },
    ActualDisciplines() {
      return (
        this.enrol?.disciplines?.filter(
          (e) => e.period === this.enrol.current_period
        ) || []
      );
    },
    ActualPeriod() {
      return this.enrol?.periods_?.find(
        (p) => p.order === this.enrol.current_period
      );
    },
    NextDisciplines() {
      return (
        this.enrol?.disciplines?.filter(
          (e) => e.period === this.enrol.current_period + 1
        ) || []
      );
    },
    hasDebits() {
      return (
        this?.student?.payments?.filter(
          (e) => e.status === "opened" && e.enrol.uid === this.enrol.uid
        ).length > 0
      );
    },
    ActualDisciplinesContainStatus() {
      return !!this.ActualDisciplines.filter((d) => !d.status).length;
    },
    CourseHasNoPeriods() {
      return !this.enrol.periods;
    },
    isLastPeriod() {
      return (
        this.enrol.periods && this.enrol.periods === this.enrol.current_period
      );
    },
    isFirstPeriod() {
      return this.enrol.current_period === 0;
    },
    hasErrors() {
      return this.hasDebits;
    },
  },

  methods: {
    moveToSelectedDateMonth() {
      const self = this;
      setTimeout(() => {
        self.$refs.exam_expires_in.move({
          month: new Date(self.enrol.end).getMonth() + 1,
          year: new Date(self.enrol.end).getFullYear(),
        });
      }, 200);
    },
    formatDate(v) {
      return new Date(v) != "Invalid Date" ? this.intlDate.format(v) : v;
    },
    checkErrors() {
      // if (this.hasDebits)
      //   return ElNotification.error({
      //     message: "Você não pode refazer uma matrícula que possui débitos.",
      //   });

      if (this.ActualDisciplinesContainStatus)
        return ElNotification.error({
          message:
            "Você precisa marcar o estado das disciplinas do período atual.",
        });

      return false;
    },
    async cancelPeriod() {
      await Promise.all([
        this.deletePeriod(),
        this.updateEnrol({
          uid: this.enrol.uid,
          current_period: this.enrol.current_period - 1,
        }),
      ]);
      ElNotification.success({
        message: "O período foi retrocedido com sucesso",
      });
      this.$emit("reload-data");
      this.$emit("close-modal");
    },
    updateEnrol(enrol) {
      return fetch(`${this.$store.state.apiUrl}enrols/${enrol.uid}`, {
        method: "PUT",
        body: JSON.stringify(enrol),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
    },
    deletePeriod() {
      return fetch(`${this.$store.state.apiUrl}periods/${this.enrol?.uid}`, {
        method: "DELETE",
        credentials: "include",
      });
    },
    async createObservation() {
      if (this.ActualDisciplines.filter((a) => a.status === "canceled")?.length)
        await ObservationService().create({
          student: { uid: this.student.uid },
          title: `${this.enrol._course.name} - DISCIPLINAS REPROVADAS - ${this.enrol.current_period}º PERÍODO`,
          description: `O aluno reprovou nas seguintes disciplinas:

        ${this.ActualDisciplines.filter((a) => a.status === "canceled")
          .map((a) => a.name)
          .join(", ")}`,
          users: [{ uid: this.$store.state.user.uid }],
        });
    },
    allDisciplinesWasReproved() {
      return this.ActualDisciplines.every((a) => a.status === "canceled");
    },
    markDisciplineAsStudying() {
      this.enrol.disciplines = this.enrol.disciplines.map((d) => {
        if (d.period === this.enrol.current_period)
          return {
            ...d,
            status: "studying",
          };
        return d;
      });
    },
    save(shouldAdvancePeriod = true) {
      if (this.checkErrors()) return;

      const required_fields = {};

      if (this.isLastPeriod) {
        required_fields["period_start"] = new Date();
        required_fields["period_end"] = new Date();
        required_fields["periods"] = this.enrol.periods;
        required_fields["exam"] = {
          starts_in: new Date(),
          expires_in: new Date(),
        };
        required_fields["current_period"] = this.enrol.current_period;
        required_fields["student_uid"] = this.studentData.uid;
      }

      this.isLoadingSubmit = true;
      if (this.allDisciplinesWasReproved()) this.createObservation();

      if (!shouldAdvancePeriod) this.markDisciplineAsStudying();

      if (this.CourseHasNoPeriods) {
        this.createNewEnrolPeriodAndAssign();
      } else {
        this.updateCurrentEnrolPeriod(required_fields, shouldAdvancePeriod);
      }
    },
    async createNewEnrolPeriodAndAssign() {
      const { period } = await EnrolPeriodService().create({
        starts_at: this.enrol.start,
        ends_at: this.enrol.end,
        status: "studying",
        order: 1,
        total: 1,
        enrol_id: this.enrol.uid,
      });

      if (period) {
        this.updateEnrol({
          uid: this.enrol.uid,
          current_period: 1,
          status: "progress",
        });

        this.$emit("close-modal", true);
        this.$emit("reload-data");
      }

      this.isLoadingSubmit = false;
    },
    async updateCurrentEnrolPeriod(required_fields, shouldAdvancePeriod) {
      const { period, error } = await EnrolPeriodService(this.enrol.uid).update(
        {
          period_start: this.enrol.start,
          period_end: this.enrol.end,
          periods: this.enrol.periods,
          exam: {
            starts_in: this.reenrol.exam_starts_in,
            expires_in: this.reenrol.exam_expires_in,
          },
          current_period: shouldAdvancePeriod
            ? this.enrol.current_period
            : this.enrol.current_period - 1,
          ...this.reenrol,
          disciplines: shouldAdvancePeriod
            ? this.enrol.disciplines
            : this.enrol.disciplines?.filter((d) => !d.is_reproved_discipline),
          student_uid: this.studentData.uid,
          ...required_fields, //Caso seja o último período, envia dados nos campos requeridos para passar pela validação, pois eles não serão utilizados no controller
        }
      );

      if (period) {
        this.$emit("close-modal", true);
        this.$emit("reload-data");
        ElNotification.success({
          title: period.message,
        });
      } else
        ElNotification.error({
          message: error.message,
        });

      this.isLoadingSubmit = false;
    },
    fetchComissions(v) {
      if (v) {
        this.isLoadingFetchComissions = true;
        fetch(this.$store.state.apiUrl + "comissions", {
          credentials: "include",
          method: "GET",
        })
          .then((response) => {
            if (response.status == 200) return response.json();
            else throw response.json();
          })
          .then(
            (json) =>
              (this.comissions = json.filter(
                (j) => j?.school?.uid === this.enrol?.school?.uid
              ))
          )
          .catch((e) => console.log(e))
          .finally(() => (this.isLoadingFetchComissions = false));
      }
    },
    removeDiscipline: function (uid) {
      if (Array.isArray(this.enrol.disciplines))
        this.enrol.disciplines = this.enrol.disciplines.filter(
          (d) => d.uid != uid
        );
    },
    addDiscipline: function (a) {
      if (!Array.isArray(this.enrol.disciplines)) this.enrol.disciplines = [];

      this.enrol.disciplines.push({ ...a, uid: uuid.v4() });
    },
    setCompleted(d) {
      d.status = "completed";
      this.updateDiscipline(d);
    },
    updateDiscipline(d) {
      if (d)
        fetch(`${this.$store.state.apiUrl}disciplines/${d.uid}`, {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(d),
        });
    },
    setCanceled(d) {
      if (d.status != "canceled") {
        d.status = "canceled";
        this.updateDiscipline(d);

        this.enrol?.disciplines?.push({
          name: d.name,
          status: "studying",
          workload: d.workload,
          period: d.period + 1,
          is_reproved_discipline: true,
        });
      }
    },
  },
};
</script>
<style scoped>
div.discipline-completed {
  background-color: #e6ffff;
}
div.discipline-canceled {
  background-color: #ffcccb;
}

i.fa-exclamation-circle {
  width: 25px;
  height: 25px;
}
.el-scrollbar__view {
  padding-right: 15px;
}
.noMargin {
  margin: 0px !important;
}
</style>
