<template>
  <modal
    v-model:show="showModal"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template v-slot:header>
        <h4 class="modal-title" id="exampleModalLabel">
          {{ isNew ? "Adicionar Observação" : "Editar Observação" }}
        </h4>
        <h6 class="text-muted">
          Esta observação ficará disponível para todos os usuários
        </h6>
      </template>

      <form>
        <div class="row">
          <div class="col-12">
            <!--TÍTULO-->
            <div class="form-group row mb-2">
              <label
                class="
                  col-lg-2 col-md-12 col-sm-12 col-form-label
                  form-control-label
                "
                >Título:</label
              >
              <div class="col-lg-10 col-md-12 col-sm-12">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="obs_.title"
                  required
                  placeholder="Digite o título desta observação"
                >
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <!--DESCRIÇÃO-->
            <div class="form-group row mb-2">
              <label
                class="
                  col-lg-2 col-md-12 col-sm-12 col-form-label
                  form-control-label
                "
                >Descrição:</label
              >
              <div class="col-lg-10 col-md-12 col-sm-12">
                <base-input
                  formClasses="input-group-alternative"
                  required
                  placeholder="Descrição breve da observação"
                >
                  <textarea
                    v-model="obs_.description"
                    class="form-control"
                    rows="4"
                    maxlength="999"
                  ></textarea>
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="dropdown-divider"></div>

        <div class="row justify-content-between">
          <div class="col-md-6 col-sm-12">
            <div class="text-left mb-2">
              <h5>Cadastrar evento na agenda:</h5>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="row justify-content-end mx-auto">
              <base-switch
                v-model="obs_.shouldRegisterEvent"
                inline
                :disabled="!isNew"
                class="mb-3 pl-3"
              >
              </base-switch>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col col-6">
            <div class="form-group mb-2">
              <label class="col-form-label form-control-label"
                >Data do Evento:</label
              >
              <base-input
                v-if="!obs_.shouldRegisterEvent"
                formClasses="input-group-alternative"
                disabled
                placeholder="Selecione cadastrar evento"
              ></base-input>
              <DatePicker
                v-else
                :popover="{ visibility: 'focus' }"
                timezone="UTC"
                :model-config="{timeAdjust: '00:00:00'}"
                v-model="obs_.day"
                :class="{ 'disabled-date-picker': !obs_.shouldRegisterEvent }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <base-input
                    formClasses="input-group-alternative"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="25/02/2021"
                  ></base-input>
                </template>
              </DatePicker>
            </div>
          </div>

          <div class="col col-6">
            <div class="form-group mb-2">
              <label class="col-form-label form-control-label"
                >Tipo do Evento:</label
              >
              <base-dropdown
                :disabled="!obs_.shouldRegisterEvent"
                class="input-group-alternative"
                hint="Selecione o tipo do evento"
                v-model="obs_.event_category"
                valueKey="value"
                labelKey="label"
                :items="EventCategories"
              ></base-dropdown>
            </div>
          </div>
        </div>
      </form>

      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button
            size="sm"
            @click="showModal = false"
            class="float-right"
            type="danger"
            >CANCELAR</base-button
          >
          <base-button
            size="sm"
            @click="save"
            class="float-right"
            type="primary"
            >{{ isNew ? "CADASTRAR" : "SALVAR" }}</base-button
          >
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
import BaseButton from "../../components/BaseButton.vue";
import { ElNotification } from "element-plus";
import { DatePicker } from "v-calendar";
//import BaseCollapse from "../../components/BaseCollapse.vue";
export default {
  components: { BaseButton, DatePicker },
  name: "add-obs",
  props: {
    shouldShow: {
      default: () => false,
      type: Boolean,
    },
    obs: {
      type: Object,
      //default: () => ({}),
    },
    student: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      obs_: { ...(this.obs || {}) },
    };
  },
  watch: {
    obs(v) {
      this.obs_ = { ...(v || {}) };
      if (v) this.obs_.shouldRegisterEvent = false;
    },
    student() {
      this.obs_ = {};
    },
  },
  computed: {
    isNew() {
      return !this.obs;
    },
    showModal: {
      get: function () {
        return this.shouldShow;
      },
      set: function () {
        this.$emit("close-modal", false);
      },
    },
    EventCategories() {
      return [
        {
          label: "Urgente",
          value: "urgent",
          uid: "urgent",
        },
        {
          label: "Agendamento",
          value: "normal",
          uid: "normal",
        },
        {
          label: "Informação",
          value: "info",
          uid: "info",
        },
        {
          label: "Contato",
          value: "problem",
          uid: "problem",
        },
      ];
    },
  },
  methods: {
    save() {
      this.isNew ? this.createObs() : this.updateObs();
    },
    createEvent() {
      fetch(`${this.$store.state.apiUrl}events`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          category: this.obs_.event_category,
          label: this.obs_.title,
          description: this.obs_.description,
          day: this.obs_.day,
          student: {
            uid: this.student ? this.student.uid : this.$route.params.id,
          },
          visibility: "public",
        }),
      })
        .then(async (response) => {
          if (response.status == 200) {
            this.errorMessages = {};
            this.$emit("reload-data", true);
            this.$emit("close-modal", true);

            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
            }),
          (e) =>
            ElNotification.error({
              message: e.message,
            })
        )
        .finally(() => (this.isLoadingSubmit = false));
    },
    createObs() {
      fetch(`${this.$store.state.apiUrl}observations`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...this.obs_,
          student: {
            uid: this.student ? this.student.uid : this.$route.params.id,
          },
        }),
      })
        .then(async (response) => {
          if (response.status === 200) return response.json();
          else throw await response.json();
        })
        .then(
          () => {
            ElNotification.success({
              title: "Observação cadastrada com sucesso",
            });
          },
          (e) => {
            ElNotification.error({ message: e.message });
          }
        )
        .finally(() => this.emitReloadData());

      if (this.obs_.shouldRegisterEvent) this.createEvent();
    },
    updateObs() {
      fetch(`${this.$store.state.apiUrl}observations/${this.obs.uid}`, {
        credentials: "include",
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...this.obs_,
        }),
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          () => {
            ElNotification.success({
              message: "Observação atualizada com sucesso",
            });
          },
          (e) => {
            ElNotification.errro({ message: e.message });
          }
        )
        .finally(() => this.emitReloadData());
    },
    emitReloadData() {
      this.$emit("reload-data");
      this.showModal = false;
    },
  },
};
</script>
