<template>
  <h4>Dados Pessoais:</h4>
  <div class="row">
    <div class="col-6">
      <!--CÓDIGO-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >Código:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            formClasses="input-group-alternative"
            disabled
            :value="SafeStudent.code"
          ></base-input>
        </div>
      </div>
    </div>

    <div class="col-6">
      <!--CPF-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >CPF:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            formClasses="input-group-alternative"
            :value="SafeStudent.cpf"
            disabled
            :mask="[
              '000\.0##\.###\-##',
              '000\.###\.###\-##',
              '00#\.###\.###\-##',
              '0##\.###\.###\-##',
              '###\.###\.###\-##',
            ]"
          ></base-input>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <!--NOME-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >Nome:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            formClasses="input-group-alternative"
            :value="SafeStudent.name"
            disabled
          ></base-input>
        </div>
      </div>
    </div>

    <div class="col-6">
      <!--DATA NASCIMENTO-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >Data de Nascimento:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            :value="new Date(SafeStudent.birthday).toLocaleDateString()"
            formClasses="input-group-alternative"
            mask="##/##/####"
            disabled
          ></base-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <!--RG-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >RG:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            :value="SafeStudent.rg"
            formClasses="input-group-alternative"
            mask="#*"
            disabled
          ></base-input>
        </div>
      </div>
    </div>

    <div class="col-6">
      <!--EMISSOR RG-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >Emissor:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            :value="SafeStudent.rg_emitter"
            formClasses="input-group-alternative"
            disabled
            mask="A*/AA"
          ></base-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <!--DATA CADASTRO-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-2 col-md-12 col-sm-12 col-form-label form-control-label"
          >Data de Cadastro:</label
        >
        <div class="col-lg-10 col-md-12 col-sm-12">
          <base-input
            :value="new Date(SafeStudent.created_at).toLocaleDateString()"
            formClasses="input-group-alternative"
            mask="##/##/####"
            disabled
          ></base-input>
        </div>
      </div>
    </div>
  </div>

  <div class="dropdown-divider"></div>
  <h4>Endereço:</h4>
  <div class="row">
    <div class="col-6">
      <!--RUA-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >Rua:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            disabled
            :value="SafeAddr.street"
            formClasses="input-group-alternative"
          ></base-input>
        </div>
      </div>
    </div>

    <div class="col-6">
      <!--NUMERO-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >Nº:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            formClasses="input-group-alternative"
            disabled
            :value="SafeAddr.number"
          ></base-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <!--BAIRRO-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >Bairro:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            formClasses="input-group-alternative"
            disabled
            :value="SafeAddr.place"
          ></base-input>
        </div>
      </div>
    </div>

    <div class="col-6">
      <!--CEP-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >CEP:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            formClasses="input-group-alternative"
            disabled
            :mask="'##\.###\-###'"
            :value="SafeAddr.postal_code"
          ></base-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <!--CIDADE-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >Cidade:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            formClasses="input-group-alternative"
            disabled
            :value="SafeAddr.city"
          ></base-input>
        </div>
      </div>
    </div>

    <div class="col-6">
      <!--ESTADO-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >Estado:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            formClasses="input-group-alternative"
            disabled
            :value="SafeAddr.state"
          ></base-input>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <!--Obs-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-2 col-md-12 col-sm-12 col-form-label form-control-label"
          >Complemento:</label
        >
        <div class="col-lg-10 col-md-12 col-sm-12">
          <base-input
            formClasses="input-group-alternative"
            disabled
            :value="SafeAddr.complement"
          ></base-input>
        </div>
      </div>
    </div>
  </div>
  <div class="dropdown-divider"></div>
  <h4>Contato:</h4>
  <div class="row">
    <div class="col-6">
      <!--EMAIL-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >Email:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            formClasses="input-group-alternative"
            disabled
            :value="SafeStudent.email"
          ></base-input>
        </div>
      </div>
    </div>

    <div class="col-6">
      <!--CELULAR-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >Celular:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            formClasses="input-group-alternative"
            disabled
            :value="SafeContact.phone"
          ></base-input>
        </div>
      </div>
    </div>
  </div>

  <div class="dropdown-divider"></div>
  <h4>Amigo Indicação:</h4>
  <div class="row">
    <div class="col-6">
      <!--EMAIL-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >Nome:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            formClasses="input-group-alternative"
            disabled
            :value="SafeStudent.friends_name"
          ></base-input>
        </div>
      </div>
    </div>

    <div class="col-6">
      <!--CELULAR-->
      <div class="form-group row mb-2">
        <label
          class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
          >Celular:</label
        >
        <div class="col-lg-8 col-md-12 col-sm-12">
          <base-input
            formClasses="input-group-alternative"
            disabled
            :value="SafeStudent.friends_phone"
          ></base-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import dayGridPlugin from "@fullcalendar/daygrid";
//import interactionPlugin from "@fullcalendar/interaction"
//import timeGridDay from "@fullcalendar/timegrid";

export default {
  name: "student-details-tab-personal",
  data() {
    return {
      statesOfBrazil: this.$store.state.statesOfBrazil,
    };
  },
  props: {
    student: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    SafeStudent: {
      get: function () {
        return this.student || {};
      },
      set: function () {
        //this.$emit("change", v);
      },
    },
    SafeAddr() {
      return this?.student?.addr || {};
    },
    SafeContact() {
      return this?.student?.contact || {};
    },
  },
};
</script>