<template>
  <modal
    v-model:show="showModal"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template v-slot:header>
        <h4 class="modal-title" id="exampleModalLabel">
          {{ isNew ? "CADASTRAR USUÁRIO" : "EDITAR USUÁRIO" }}
        </h4>
        <h6 class="text-muted">
          {{
            isNew
              ? "Informe os dados do usuário"
              : "Preencha os dados que deseja alterar"
          }}
        </h6>
      </template>

      <div class="text-left mb-2">
        <h5>Dados:</h5>
      </div>
      <form role="form" ref="InsertOrUpdateStudentForm">
        <div class="row">
          <div class="col-12">
            <!--NOME-->
            <div class="form-group row mb-2">
              <label
                class="
                  col-lg-2 col-md-12 col-sm-12 col-form-label
                  form-control-label
                "
                >Nome:</label
              >
              <div class="col-lg-10 col-md-12 col-sm-12">
                <base-input
                  v-model="user_.name"
                  formClasses="input-group-alternative"
                  required
                  placeholder="Nome do usuário"
                ></base-input>
              </div>
            </div>
          </div>

          <div class="col-12">
            <!--CPF-->
            <div class="form-group row mb-2">
              <label
                class="
                  col-lg-2 col-md-12 col-sm-12 col-form-label
                  form-control-label
                "
                >Email:</label
              >
              <div class="col-lg-10 col-md-12 col-sm-12">
                <base-input
                  v-model="user_.email"
                  formClasses="input-group-alternative"
                  required
                  placeholder="usuario@email.com"
                ></base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <!--NOME-->
            <div class="form-group row mb-2">
              <label
                class="
                  col-lg-4 col-md-12 col-sm-12 col-form-label
                  form-control-label
                "
                >Senha:</label
              >
              <div class="col-lg-8 col-md-12 col-sm-12">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="user_.password"
                  required
                  type="password"
                  placeholder="**********"
                ></base-input>
              </div>
            </div>
          </div>

          <div class="col-6">
            <!--DATA NASCIMENTO-->
            <div class="form-group row mb-2">
              <label
                class="
                  col-lg-4 col-md-12 col-sm-12 col-form-label
                  form-control-label
                "
                >Confirme:</label
              >
              <div class="col-lg-8 col-md-12 col-sm-12">
                <base-input
                  formClasses="input-group-alternative"
                  required
                  type="password"
                  v-model="user_.password_confirmation"
                  placeholder="**********"
                ></base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-md-2 col-form-label form-control-label"
            >Cargo:</label
          >
          <div class="col-md-10">
            <el-select
              v-model="user_.roles"
              clearable
              value-key="uid"
              loading-text="Buscando cargo"
              class="input-group-alternative"
              placeholder="Digite as iniciais do cargo para filtrar"
              :loading="isRoleLoading"
              no-data-text="Nenhum cargo encontrado"
            >
              <el-option
                v-for="item in roles"
                :key="item.uid"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </form>
      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button
            size="sm"
            @click="cancel"
            class="float-right"
            type="danger"
            >CANCELAR</base-button
          >
          <base-button
            size="sm"
            @click="isNew ? createUser() : updateUser()"
            class="float-right"
            :isLoading="isLoadingSubmit"
            type="primary"
            >SALVAR</base-button
          >
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
import BaseButton from "../../components/BaseButton.vue";
import { ElNotification, ElSelect, ElOption } from "element-plus";
export default {
  components: { BaseButton, ElSelect, ElOption },
  name: "add-user",
  props: {
    shouldShow: {
      default: false,
      type: Boolean,
    },
    user: {
      type: Object,
      description:
        "Student to be updated, dont pass objects if you wanted to register a new student",
    },
  },
  computed: {
    isNew() {
      return !this.user;
    },
    showModal: {
      get: function () {
        return this.shouldShow;
      },
      set: function () {
        this.$emit("close-modal", false);
      },
    },
    Errors() {
      return this.errorMessages || {};
    },
  },
  mounted() {
    this.fetchRoles();
  },
  watch: {
    user(v) {
      this.user_ = { ...(v || {}) };
    },
  },
  methods: {
    fetchRoles() {
      fetch(`${this.$store.state.apiUrl}roles`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          (json) => (this.roles = json),
          () => (this.roles = [])
        );
    },
    cancel() {
      this.showModal = false;
    },

    updateUser() {
      this.isLoadingSubmit = true;

      fetch(`${this.$store.state.apiUrl}users/${this.user_.uid}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(this.user_),
      })
        .then(async (response) => {
          if (response.status == 200) {
            this.errorMessages = {};
            this.$emit("close-modal", true);
            this.$emit("reload-data", true);
            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
            }),
          (e) =>
            ElNotification.error({
              message: e.message,
            })
        )
        .finally(() => (this.isLoadingSubmit = false));
    },
    createUser() {
      this.isLoadingSubmit = true;

      fetch(`${this.$store.state.apiUrl}users`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(this.user_),
      })
        .then(async (response) => {
          if (response.status == 200) {
            this.errorMessages = {};
            this.$emit("close-modal", true);
            this.$emit("reload-data", true);
            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
            }),
          (e) =>
            ElNotification.error({
              title: "Não foi possível cadastrar o usuário",
              message: e.message,
            })
        )
        .catch((json) => {
          this.errorMessages = json.reduce((t, e) => {
            t[e.field] = e.message;
            return t;
          }, {});
        })
        .finally(() => (this.isLoadingSubmit = false));
    },
  },
  data() {
    return {
      isLoadingSubmit: false,
      errorMessages: {},
      isRoleLoading: false,
      roles: [],
      user_: {
        ...this.user,
      },
    };
  },
};
</script>
