<template>
  <modal
    @update:show="showModal = $event"
    :show="showModal"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <base-loader v-if="isLoading"></base-loader>
    <card
      type="secondary"
      v-else
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-0 py-lg-1"
      class="border-0"
    >
      <template v-slot:header>
        <h4 class="modal-title">
          {{ student?.name }}
        </h4>
        <h6 class="text-muted">Exibindo apenas títulos em atraso</h6>
      </template>

      <div class="table-responsive">
        <base-table
          thead-classes="thead-light"
          tbody-classes="list"
          :limit="limit"
          :offset="(pagination - 1) * limit"
        >
          <template v-slot:columns>
            <th v-for="col in TableColumns" :key="col">
              {{ col }}
            </th>
          </template>

          <!--

        <tr v-for="(items, index) of tableData" :key="index">
          <td v-for="item of items" :key="item">
            <div v-if="Array.isArray(item)">
              <div class="btn-group">
                <base-button v-for="button of item" :key="button" type="primary"><i :class="button"></i></base-button>
              </div>
            </div>
            <h5 v-else>
              {{ item.value }}
            </h5>
          </td>
        </tr>-->

          <template v-slot:data v-if="hasData">
            <tr v-for="p in ResumePayments" :key="p">
              <td>
                {{ p.course_name }}
              </td>
              <td>
                {{ p.total }}
              </td>
              <td>
                {{ currencyFormatter.format(p.cost) }}
              </td>
            </tr>
          </template>
          <template #pagination>
            <base-pagination
              @input="pagination = $event"
              :value="pagination"
              :perPage="limit"
              :total="total"
            ></base-pagination>
          </template>
        </base-table>

        <base-collapse
          v-for="obs in student.observations"
          :key="obs"
          :title="obs.title"
          ><div class="p-1 m-1">{{ obs.description }}</div></base-collapse
        >
      </div>
      <template v-slot:footer>
        <div class="row justify-content-end">
          <base-button
            size="sm"
            @click="showModal = false"
            class="float-right"
            type="secondary"
            >FECHAR</base-button
          >
        </div>
      </template>
    </card>
  </modal>
</template>
<script>
import BaseTable from "../../components/BaseTable.vue";
import BaseLoader from "../../components/BaseLoader.vue";
import BasePagination from "../../components/BasePagination.vue";
import BaseCollapse from "../../components/BaseCollapse.vue";
export default {
  name: "UnpaidDetailsModal",
  props: {
    data: { type: Array },
    student: { type: Object },
    shouldShow: { type: Boolean },
  },
  data() {
    return {
      isLoading: true,
      student_: null,
      limit: 15,
      pagination: 1,
      currencyFormatter: new Intl.NumberFormat("pt-br", {
        minimumFractionDigits: 2,
      }),
    };
  },
  components: {
    BasePagination,
    BaseTable,
    BaseLoader,
    BaseCollapse,
  },
  watch: {
    student(v) {
      if (v) {
        this.isLoading = true;
        this.student_ = null;
        this.fetchStudent();
      }
    },
  },
  computed: {
    TableColumns() {
      return ["curso", "parcelas", "total"];
    },
    showModal: {
      get() {
        return this.shouldShow;
      },
      set(v) {
        this.$emit("close-modal", v);
      },
    },
    UnpaidPayments() {
      return (
        this?.student_?.payments?.filter(
          (p) => p.payment_status === "unpaid"
        ) || []
      );
    },
    ResumePayments() {
      return Object.values(
        this.UnpaidPayments.map((p) => ({
          course_name: p.enrol._course.name,
          cost: p.cost,
          enrol_uid: p.enrol.uid,
          total: 1,
        })).reduce((t, p) => {
          if (t[p.enrol_uid]) {
            t[p.enrol_uid]["cost"] =
              Number(t[p.enrol_uid]["cost"]) + Number(p.cost);
            t[p.enrol_uid]["total"]++;
          } else t[p.enrol_uid] = p;
          return t;
        }, {})
      );
    },
    hasData() {
      return !!this?.UnpaidPayments?.length;
    },
  },
  methods: {
    fetchStudent() {
      if (this.student)
        fetch(`${this.$store.state.apiUrl}students/${this.student?.uid}/uid`, {
          credentials: "include",
          method: "GET",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            throw response.json();
          })
          .then((json) => (this.student_ = json))
          .catch(() => (this.hasError = true))
          .finally(() => (this.isLoading = false));
    },
  },
};
</script>