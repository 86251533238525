<template>
  <modal
    :show="showModal"
    @update:show="showModal = $event"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template v-slot:header>
        <h4 class="modal-title" id="exampleModalLabel">
          {{ isNew ? "CADASTRAR" : "EDITAR" }} INSTITUIÇÃO DE ENSINO
        </h4>
        <h6 class="text-muted">
          Informe abaixo os dados do novo curso a ser cadastrado
        </h6>
      </template>

      <div class="text-left mb-2">
        <h5>Dados:</h5>
      </div>
      <form role="form">
        <div class="row">
          <div class="col-12">
            <!--NOME-->
            <div class="form-group row mb-2">
              <label class="col-2 col-form-label form-control-label"
                >Nome:</label
              >
              <div class="col-10">
                <base-input
                  v-model="school_.name"
                  formClasses="input-group-alternative"
                  required
                  placeholder="Nome completo da Instituição"
                ></base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-sm-12">
            <!--C/H-->
            <div class="row mb-2">
              <label class="col-4 col-form-label form-control-label"
                >Sigla:</label
              >
              <div class="col-8">
                <base-input
                  formClasses="input-group-alternative"
                  required
                  v-model="school_.initials"
                  placeholder="Sigla da Instituição"
                ></base-input>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <!--CNPJ-->
            <div class="form-group row mb-2">
              <label class="col-4 col-form-label form-control-label"
                >CNPJ:</label
              >
              <div class="col-8">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="school_.cnpj"
                  required
                  mask="##!.###!.###!/####!-##"
                  placeholder="99.999.999/0001-99"
                ></base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <!--Endereço-->
            <div class="form-group row mb-2">
              <label class="col-2 col-form-label form-control-label"
                >Endereço:</label
              >
              <div class="col-10">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="school_.address"
                  required
                  placeholder="Rua dos Portos, 555, Monte Oliveira"
                ></base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="dropdown-divider"></div>
        <div class="text-left mb-2">
          <h5>Formas de Pagamento:</h5>
        </div>

        <div class="row">
          <div class="col-md-8 col-sm-12">
            <!--Quantidade de Períodos-->
            <div class="row mb-2 align-items-center">
              <label class="col-4 col-form-label form-control-label"
                >Boleto:</label
              >
              <div class="col-8">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="school_.max_payments_ticket"
                  placeholder="Parcelamento Máximo"
                ></base-input>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <base-checkbox v-model="school_.is_one_time_transfer_ticket" inline>
              Recebimento único ?
            </base-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 col-sm-12">
            <!--Quantidade de Períodos-->
            <div class="row mb-2 align-items-center">
              <label class="col-4 col-form-label form-control-label"
                >Cartão de Crédito:</label
              >
              <div class="col-8">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="school_.max_payments_credit"
                  placeholder="Parcelamento Máximo"
                ></base-input>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <base-checkbox v-model="school_.is_one_time_transfer_credit" inline>
              Recebimento único ?
            </base-checkbox>
          </div>
        </div>

        <div class="dropdown-divider"></div>
        <div class="text-left mb-2">
          <h5>Repasse dos Pagamentos:</h5>
        </div>

        <div class="row">
          <div class="col-md-4 col-sm-12">
            <!--C/H-->
            <div class="row mb-2">
              <label class="col-7 col-form-label form-control-label"
                >Fechamento:</label
              >
              <div class="col-5">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="school_.closing_day"
                  mask="##"
                  placeholder="dia"
                ></base-input>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-sm-12">
            <!--DURAÇÃO-->
            <div class="form-group row mb-2">
              <label class="col-7 col-form-label form-control-label"
                >Pagamento:</label
              >
              <div class="col-5">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="school_.payday"
                  required
                  mask="##"
                  placeholder="dia"
                ></base-input>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <!--DURAÇÃO-->
            <div class="form-group row mb-2">
              <label class="col-7 col-form-label form-control-label"
                >Prazo:</label
              >
              <div class="col-5">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="school_.payment_delay"
                  required
                  mask="##"
                  placeholder="dias"
                ></base-input>
              </div>
            </div>
          </div>
        </div>
      </form>
      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button
            size="sm"
            @click="showModal = false"
            class="float-right"
            type="danger"
            >CANCELAR</base-button
          >
          <base-button
            size="sm"
            @click="save"
            class="float-right"
            :isLoading="isLoadingSubmit"
            type="primary"
            >SALVAR</base-button
          >
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
import BaseButton from "../../components/BaseButton.vue";
import { ElNotification } from "element-plus";
export default {
  components: { BaseButton },
  name: "add-school",
  props: {
    shouldShow: {
      type: Boolean,
    },
    school: {
      type: Object,
      default: () => ({}),
      description: "course object when modal is opened by edit button",
    },
    isNew: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    showModal: {
      get: function () {
        return this.shouldShow;
      },
      set: function () {
        this.$emit("close-modal", false);
      },
    },
    SafeSchool() {
      return this?.school_?.school_id || {};
    },
  },
  data() {
    return {
      school_: this.school || {},
      isLoadingSubmit: false,
    };
  },
  watch: {
    school() {
      this.school_ = {
        ...this.school,
      };
    },
  },
  methods: {
    save() {
      if (this.isNew) this.createSchool();
      else this.updateSchool();
    },
    updateSchool() {
      this.isLoadingSubmit = true;

      fetch(this.$store.state.apiUrl + "schools/" + this.school_.uid, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(this.school_),
      }).finally(() => {
        this.isLoadingSubmit = false;
        this.$store.dispatch("fetchSchools");
        this.$emit("reload-data");
        this.$emit("close-modal");
      });
    },
    createSchool() {
      this.isLoadingSubmit = true;

      fetch(this.$store.state.apiUrl + "schools", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",

          Accept: "application/json",
        },
        body: JSON.stringify(this.school_),
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then((e) => {
          ElNotification.success({ title: e.message });
          this.showModal = false;
        })
        .catch((e) => e.then((e) => ElNotification.error({ title: e.message })))
        .finally(() => {
          this.isLoadingSubmit = false;
          this.$store.dispatch("fetchSchools");
          this.$emit("reload-data");
        });
    },
  },
};
</script>
