import http from "./http";
export default (paymentId) => ({
	async index(filters) {
		return http
			.index("payments", filters)
			.then((json) => ({ payments: json }))
			.catch((e) => ({ payments: null, error: e }));
	},
	async get() {
		return http
			.get("payments", paymentId)
			.then((json) => ({ payment: json }))
			.catch((e) => ({ payment: null, error: e }));
	},
	async create(data) {
		return http
			.post("payments", data)
			.then((json) => ({ payment: json }))
			.catch((e) => ({ payment: null, error: e }));
	},
	async update(data) {
		return http
			.put("payments", paymentId, data)
			.then((json) => ({ payment: json }))
			.catch((e) => ({ payment: null, error: e }));
	},
	async delete() {
		return http
			.delete("payments", paymentId)
			.then((json) => ({ payment: json }))
			.catch((e) => ({ payment: null, error: e }));
	},
});
