import http from "./http";
export default (courseId) => ({
	async index(filters) {
		return http
			.index("courses", filters)
			.then((json) => ({ courses: json }))
			.catch((e) => ({ courses: null, error: e }));
	},
	async get() {
		return http
			.get("courses", courseId)
			.then((json) => ({ course: json }))
			.catch((e) => ({ course: null, error: e }));
	},
	async create(data) {
		return http
			.post("courses", data)
			.then((json) => ({ course: json }))
			.catch((e) => ({ course: null, error: e }));
	},
	async update(data) {
		return http
			.put("courses", courseId, data)
			.then((json) => ({ course: json }))
			.catch((e) => ({ course: null, error: e }));
	},
	async delete() {
		return http
			.delete("courses", courseId)
			.then((json) => ({ course: json }))
			.catch((e) => ({ course: null, error: e }));
	},
});
