/*!

=========================================================
* Vue Argon Dashboard - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createApp } from "vue";

import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import VueLoaders from "vue-loaders";
import "element-plus/lib/theme-chalk/index.css";
import App from "./App.vue";
import store from "./store";
import Maska from "maska";

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(VueLoaders);
appInstance.use(store);
appInstance.use(Maska);
store.dispatch('loadUser');
appInstance.mount("#app");

