<template>
  <div>
    <dashboard-navbar
      searchHint="Buscar matricula por aluno, CPF ou curso..."
      @updateSearch="updateSearch"
    ></dashboard-navbar>

    <div class="container-fluid d-flex h-100 flex-column pt-6 px-2">
      <base-loader v-if="isLoading"></base-loader>
      <projects-table
        v-else
        type="hover"
        title="Lista de Matrículas"
        :tableData="enrols"
        @showModal="showEnrolModal = $event"
      >
        <template #actions>
          <base-button
            type="primary"
            class="mt-2"
            icon="fa fa-filter"
            title="Filtrar"
            size="sm"
            v-on:click.stop.prevent="openFilterModal()"
          ></base-button>
          <el-popover
            placement="bottom"
            :width="300"
            :visible="dialogVisible"
            @update:visible="dialogVisible = $event"
          >
            <p>Em qual formato deseja baixar?</p>
            <div style="text-align: right; margin: 0">
              <base-button
                size="sm"
                type="text"
                disabled
                @click="dialogVisible = false"
                >Excel</base-button
              >
              <base-button
                size="sm"
                type="text"
                @click="() => generateCSV((dialogVisible = false))"
                >CSV</base-button
              >
              <base-button
                type="text"
                size="sm"
                v-on:click.stop.prevent="
                  () => generatePDF((dialogVisible = false))
                "
                >PDF</base-button
              >
            </div>
            <template #reference>
              <base-button
                type="primary"
                icon="fa fa-download"
                class="mt-2"
                size="sm"
                :disabled="isLoadingGeneratePDF"
              ></base-button>
            </template>
          </el-popover>
          <base-button
            v-if="$store.state.user.canPreview"
            type="primary"
            class="mt-2"
            icon="fa fa-upload"
            size="sm"
            v-on:click.stop.prevent="() => this.openUploadDataModal()"
          ></base-button>
        </template>
        <template #columns>
          <th v-for="col in TableColumns" :key="col">
            {{ col.label }}
            <span
              class="caret-wrapper"
              v-if="col?.sortable"
              @click="sortColumn(col)"
            >
              <i
                :class="{
                  'fa fa-sort-up ascending sort-icon': true,
                  'is-sorted': sortOptions[col.prop] === 'asc',
                }"
              ></i>
              <i
                :class="{
                  'fa fa-sort-down descending sort-icon': true,
                  'is-sorted': sortOptions[col.prop] === 'desc',
                }"
              ></i>
            </span>
          </th>
        </template>

        <template #data v-if="enrols?.length">
          <tr
            v-for="(c, id) in enrols"
            :key="id"
            :class="
              c.periods || c.status != 'created' ? 'status-' + c.status : ''
            "
          >
            <td>
              {{ c?.code }}
            </td>
            <td>
              {{
                c?.start_in
                  ? dateFormatter.format(new Date(c.start_in))
                  : dateFormatter.format(new Date(c.created_at))
              }}
            </td>
            <td>
              {{ c?.student?.name }}
            </td>
            <td>
              {{ cpfFormatter.format(c?.student?.cpf) }}
            </td>
            <td class="wrap-content">
              {{ c?._course?.name }}
            </td>

            <td>
              {{ phoneFormatter.format(c?.student?.contact?.phone) }}
            </td>

            <td>
              <div class="btn-group float-right">
                <base-button
                  type="primary"
                  size="sm"
                  icon="fa fa-external-link-alt"
                  @click="navigateToRoute(c.student)"
                ></base-button>
                <base-button
                  size="sm"
                  @click="openAddObsModal(c?.student)"
                  type="primary"
                >
                  <i class="ni ni-book-bookmark"></i>
                </base-button>
                <el-popconfirm
                  title="Remover matrícula? Os títulos de pagamento também serão removidos"
                  confirm-button-text="sim"
                  cancel-button-text="não"
                  @confirm="deleteEnrol(c)"
                >
                  <template #reference>
                    <base-button size="sm" type="danger"
                      ><i class="fa fa-trash"></i
                    ></base-button>
                  </template>
                </el-popconfirm>
              </div>
            </td>
          </tr>
        </template>

        <template #pagination>
          <base-pagination
            @input="pagination = $event"
            :value="pagination"
            :perPage="limit"
            :total="total"
          ></base-pagination>
        </template>
      </projects-table>
      <add-student-observation-modal
        :shouldShow="showObservationModal"
        @close-modal="showObservationModal = false"
        :obs="null"
        :student="selectedStudent"
      ></add-student-observation-modal>
      <filter-enrols-modal
        :shouldShow="showFilterModal"
        @close-modal="showFilterModal = false"
        @update-filter="updateFilter($event)"
      ></filter-enrols-modal>

      <upload-data-modal
        @close-modal="showUploadDataModal = false"
        csvRules="code,student_id,course_id,created_at,status,start_in"
        uploadMessage="Você está enviando dados para matrículas"
        :shouldShow="showUploadDataModal"
        :upload-method="uploadMethod"
        @should-update="() => loadEnrols()"
        :allowSelectComission="true"
      ></upload-data-modal>
    </div>
  </div>
</template>

<script>
//import Card from "../components/Card.vue";
import DashboardNavbar from "../layout/DashboardNavbar.vue";
import AddStudentObservationModal from "./Modals/AddStudentObservationModal.vue";
import ProjectsTable from "../views/Tables/ProjectsTable.vue";
import "vue-loaders/dist/vue-loaders.css";
import "jspdf-autotable";
import jsPDF from "jspdf";
import BaseLoader from "../components/BaseLoader.vue";
import { ElPopconfirm, ElNotification } from "element-plus";
import FilterEnrolsModal from "./Modals/FilterEnrolsModal.vue";
import UploadDataModal from "./Modals/UploadDataModal.vue";
import { loadEnrols } from "../utils/integrations";
//import BaseDropdown from "../components/BaseDropdown.vue";

// @ is an alias to /src

export default {
  name: "Enrols",
  components: {
    DashboardNavbar,
    ProjectsTable,
    BaseLoader,
    ElPopconfirm,
    AddStudentObservationModal,
    FilterEnrolsModal,
    //BaseDropdown,
    UploadDataModal,
  },
  data() {
    return {
      isLoading: true,
      sortOptions: {},
      showEnrolModal: false,
      pagination: 1,
      uploadMethod: loadEnrols,
      showObservationModal: false,
      selectedStudent: null,
      showUploadDataModal: false,
      filterOptions: {},
      showFilterModal: false,
      jsPDF: new jsPDF("landscape"),
      cpfFormatter: {
        format(cpf) {
          let cpfFormatted = `${cpf}`;
          cpfFormatted = `${"0".repeat(
            11 - cpfFormatted.length
          )}${cpfFormatted}`;

          return cpfFormatted.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4"
          );
        },
      },
      phoneFormatter: {
        format(phone) {
          if (phone) {
            return `${phone}`.replace(
              /(\d{0,2})(\d{0,1})(\d{4})(\d{4})/,
              "($1)$2 $3-$4"
            );
          } else return phone;
        },
      },
      newCourse: false,
      dateFormatter: new Intl.DateTimeFormat("pt-BR"),
      limit: 15,
      total: 0,
      searchQuery: "",
      course: { disciplines: [] },
      enrols: [],
    };
  },
  created() {
    this.loadEnrols();
  },
  watch: {
    SelectedSchool() {
      this.pagination = 1;
      this.searchQuery = "";
      this.loadEnrols();
    },
    pagination() {
      this.loadEnrols();
    },
  },
  computed: {
    SelectedSchool() {
      return this?.$store?.state?.user?.selectedSchool;
    },
    NavActions() {
      return [
        {
          icon: "fa fa-filter",
          action: () => {
            console.log("filter clicked");
          },
        },
        {
          icon: "fa fa-download",
          action: () => {
            console.log("download clicked");
          },
        },
      ];
    },

    TableColumns: () => [
      {
        label: "código",
        sortable: true,
        prop: "code",
      },
      {
        label: "data da matrícula",
        sortable: true,
        prop: "start_in",
      },
      {
        label: "nome do aluno",
        sortable: true,
        prop: "students.name",
      },
      {
        label: "cpf",
        sortable: true,
        prop: "students.cpf",
      },
      {
        label: "curso",
        sortable: true,
        prop: "courses.name",
      },
      {
        label: "telefone",
        sortable: false,
      },
      {
        label: "ações",
        sortable: false,
      },
    ],
  },
  methods: {
    openUploadDataModal() {
      this.showUploadDataModal = true;
    },
    navigateToRoute(items) {
      let route = this.$router.resolve(`/alunos/${items.uid}`);
      window.open(route.href, "_blank");
    },
    updateFilter(v) {
      this.filterOptions = v;
      this.loadEnrols();
    },
    openFilterModal() {
      this.showFilterModal = true;
    },
    sortColumn(v) {
      if (this.sortOptions[v.prop]) {
        if (this.sortOptions[v.prop] != "asc") this.sortOptions[v.prop] = "asc";
        else delete this.sortOptions[v.prop];
      } else this.sortOptions[v.prop] = "desc";

      this.fetchData()
        .then((response) => {
          if (response.status == 200) return response.json();
          else throw response.json();
        })
        .then((json) => {
          this.enrols = json.data;
          this.total = json.total;
        });
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [...arrData.map((item) => Object.values(item).join(";"))]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Exportação.csv");
      link.click();
    },
    async generateCSV() {
      this.isLoadingGeneratePDF = true;
      ElNotification.info({
        title: "Gerando relatório",
        message: "Isso pode levar até 1 minuto",
      });

      const data = await this.fetchData(
        false,
        this.page === "payments" ? "opened" : "paid"
      ).then((response) => response.json());

      const mappedRows = data.map((e) => [
        e.status_formatted,
        e.code,
        this.dateFormatter.format(new Date(e.start_in)),
        e?.student?.name,
        this.cpfFormatter.format(e?.student?.cpf),
        e?._course?.name,
        this.phoneFormatter.format(e?.student?.contact?.phone),
      ]);

      const columns = this.TableColumns;
      columns.length--;
      columns.unshift({ label: "estado da matrícula" });

      mappedRows.unshift(columns.map((c) => c.label));

      this.csvExport(mappedRows);

      this.isLoadingGeneratePDF = false;
    },
    async generatePDF() {
      this.isLoadingGeneratePDF = true;
      ElNotification.info({
        title: "Gerando relatório",
        message: "Isso pode levar até 1 minuto",
      });

      const data = await this.fetchData(false).then((response) =>
        response.json()
      );

      const mappedRows = data.map((e) => [
        e.status_formatted,
        e.code,
        this.dateFormatter.format(new Date(e.start_in)),
        e?.student?.name,
        this.cpfFormatter.format(e?.student?.cpf),
        e?._course?.name,
        this.phoneFormatter.format(e?.student?.contact?.phone),
      ]);

      const columns = this.TableColumns;
      columns.unshift({ label: "estado" });
      columns.length--;
      this.jsPDF.autoTable({
        columns: this.TableColumns.map((c) => c.label),
        body: mappedRows,
      });
      this.jsPDF.save(`Relatório de Matrículas.pdf`);
      this.isLoadingGeneratePDF = false;
    },
    isEmpty(o) {
      for (let i in o) return false;
      return true;
    },
    openAddObsModal(s) {
      this.selectedStudent = s;
      this.showObservationModal = true;
    },
    updateSearch(v) {
      this.searchQuery = v;
      this.pagination = 1;
      this.fetchData()
        .then((response) => {
          if (response.status == 200) return response.json();
          else throw response.json();
        })
        .then((json) => {
          this.enrols = json.data;
          this.total = json.total;
        });
    },
    getStartInDate(enrol) {
      const d = new Date(enrol.expires_at);
      d?.setMonth(d.getMonth() - enrol._course.duration);
      if (d != "Invalid Date") return this.dateFormatter.format(d);
      else return "desconhecido";
    },
    deleteEnrol(e) {
      fetch(this.$store.state.apiUrl + "enrols/" + e.uid, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();

          throw response.json();
        })
        .then((e) => {
          if (isPromise(e))
            e.then((e) =>
              ElNotification.success({
                title: e.message,
              })
            );
          else
            ElNotification.success({
              title: e.message,
            });
        })
        .catch((e) =>
          ElNotification.error({
            message: e.message || "Não foi possível remover essa matrícula",
          })
        )
        .finally(() => {
          this.fetchData();
        });
    },
    showAddEnrol() {
      this.newCourse = true;
      this.course = {};
      this.showEnrolModal = true;
    },
    loadEnrols() {
      this.isLoading = true;
      this.fetchData()
        .then((response) => {
          if (response.status == 200) return response.json();
          else throw response.json();
        })
        .then((json) => {
          this.enrols = json.data;
          this.total = json.total;
        })
        .catch((e) => console.log(e))
        .finally(() => (this.isLoading = false));
    },
    fetchData(paginate = true) {
      const url = new URL(this.$store.state.apiUrl + "enrols");
      const params = { school_uid: this.SelectedSchool?.uid };
      if (paginate) {
        params.pagination = this.pagination || 1;
        params.limit = this.limit || 15;
      }
      params.queryName = this.searchQuery || "";
      params.ordenation = Object.values(this.sortOptions)?.length
        ? JSON.stringify(this.sortOptions)
        : "";
      url.search = new URLSearchParams({
        ...params,
        ...(this.filterOptions ? this.filterOptions : {}),
      });
      return fetch(url, {
        credentials: "include",
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
    },
  },
};
function isPromise(promise) {
  return !!promise && typeof promise.then === "function";
}
</script>
<style>
tr.status-finalized {
  background-color: #e0f2f1;
}
tr.status-created {
  background-color: #eeffff;
}
tr.unpaid {
  background-color: #ffebee;
}
tr.closed {
  background-color: #a4a4a4;
}
tr.status-canceled {
  background-color: #ffebee;
}
tr.status-locked {
  background-color: #eceff1;
}
</style>