<template>
  <div
    :class="[
      { 'input-group': hasIcon },
      { focused: focused },
      { 'has-label': label || $slots.label },
      formClasses,
    ]"
  >
    <slot name="label">
      <label v-if="label" class="form-control-label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>

    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
      <span class="input-group-text">
        <slot name="addonLeft">
          <i :class="addonLeftIcon"></i>
        </slot>
      </span>
    </div>
    <slot>
      <input
        v-if="type === 'money'"
        v-bind="$attrs"
        v-model="MoneyValue"
        class="form-control"
        @click.stop=""
        :class="[
          { 'is-valid': valid === true },
          { 'is-invalid': valid === false },
          inputClasses,
          { 'input-dark': inputDark },
        ]"
        aria-describedby="addon-right addon-left"
      />
      <input
        :value="MaskedValue"
        v-else
        v-bind="$attrs"
        v-maska="mask"
        :type="type"
        @maska="onMaska($event)"
        v-on:input="onMaska($event)"
        class="form-control"
        @click.stop=""
        :class="[
          { 'is-valid': valid === true },
          { 'is-invalid': valid === false },
          inputClasses,
          { 'input-dark': inputDark },
        ]"
        aria-describedby="addon-right addon-left"
      />
    </slot>
    <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
      <span class="input-group-text">
        <slot name="addonRight">
          <i :class="addonRightIcon"></i>
        </slot>
      </span>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div
        class="text-danger invalid-feedback pl-1"
        style="display: block"
        :class="{ 'mt-2': hasIcon }"
        v-if="error"
      >
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>
import { maska, mask, tokens } from "maska";
export default {
  inheritAttrs: false,
  name: "base-input",
  directives: {
    maska,
  },
  props: {
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)",
    },
    valid: {
      type: Boolean,
      description: "Whether is valid",
      default: undefined,
    },
    mask: {
      type: [String, Array, Object],
      default: () => "",
      description: "Mask of input",
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    error: {
      type: String,
      description: "Input error (below input)",
    },
    formClasses: {
      type: String,
      description: "Form css classes",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
    },
    inputClasses: {
      type: String,
      description: "Input css classes",
    },
    modelValue: {
      type: [String, Number, Object],
      description: "Input value",
    },
    addonRightIcon: {
      type: String,
      description: "Addon right icon",
    },
    addonLeftIcon: {
      type: String,
      description: "Addont left icon",
    },
    minimumFractionDigits: {
      type: Number,
      default: 2,
    },
    inputDark: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      focused: false,
      moneyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "decimal",
        minimumFractionDigits: this.minimumFractionDigits,
      }),
    };
  },
  computed: {
    SafeValue() {
      return this.modelValue ? this.modelValue : "";
    },
    MaskedValue() {
      if (this.mask) return mask(`${this.SafeValue}`, this.mask, tokens);
      return this.SafeValue;
    },
    MoneyValue: {
      get() {
        return this.moneyFormatter.format(Number(this.SafeValue) || 0);
      },
      set(v) {
        this.$emit("update:modelValue", Number(this.transformMoneyToNumber(v)));
      },
    },
    listeners() {
      return {
        ...this,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
  },
  methods: {
    onMaska(v) {
      const val = this.mask ? v.target.dataset.maskRawValue : v.target.value;

      if (!this.mask || !(v instanceof InputEvent))
        this.$emit("update:modelValue", val);
    },
    transformMoneyToNumber(s) {
      return s
        ? (
            parseFloat(onlyNumbers(s)) /
            Math.pow(10, this.minimumFractionDigits)
          ).toFixed(this.minimumFractionDigits)
        : s;
    },
    onFocus(value) {
      this.focused = true;
      this.$emit("focus", value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit("blur", value);
    },
  },
};
const onlyNumbers = (s = "") => `${s}`.replace(/[^0-9]/g, "");
</script>
<style>
.input-dark {
  color: #212121 !important;
}
</style>
