<template>
  <div>
    <base-header type="gradient-info" class="pb-6 pb-md-8 pt-5 pt-md-8">
      <dashboard-navbar searchHint="Buscar curso..."></dashboard-navbar>

      <div class="row mb-2">
        <div class="col-xs-4 col-md-9 col-sm-12 col-short-padding">
          <stats-card
            title="matriculas no mês"
            type="gradient-info"
            :subTitle="`${ActualMonthEnrols?.length}`"
            class="fill-height margin-bottom-1"
            icon="fa fa-newspaper"
          >
          </stats-card>
          <stats-card
            title="alunos no mês"
            type="gradient-info"
            :subTitle="`${ActualMonthStudents?.length || 0}`"
            class="fill-height margin-top-1"
            icon="fa fa-address-card"
          >
          </stats-card>
        </div>

        <div class="col-xs-4 col-md-3 col-sm-12 col-short-padding">
          <card shadow bodyClasses="p-0" headerClasses="pl-3 pt-3 pr-3 pb-1">
            <template #header><h4>Matrículas no mês</h4></template>
            <apex-charts
              type="pie"
              :options="EnrolsChartOptions"
              :series="Object.values(EnrolChartSeries)"
            ></apex-charts>
          </card>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--8">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-short-padding">
          <card
            shadow
            bodyClasses="p-0"
            headerClasses="pl-3 pt-3 pr-3 pb-1"
            style="height=500px;"
          >
            <template #header>
              <div class="row justify-space-between">
                <div class="col-md-4">
                  <h4>Notificações</h4>
                </div>
                <div class="col-md-8">
                  <el-input
                    v-model="searchNotification"
                    v-on:keydown.enter="fetchNotifications() | fetchMentions()"
                    placeholder="Pressione Enter para buscar a notificação"
                  ></el-input>
                </div>
              </div>
            </template>
            <template #default>
              <base-loader
                v-if="!notifications || !mentions"
                style="height: 200px"
              ></base-loader>
              <el-scrollbar max-height="500px" v-else>
                <ul class="list-group">
                  <li
                    class="list-group-item"
                    style="cursor: pointer"
                    v-for="p in MergeMentionsAndNotifications"
                    @click="navigateToRoute(p?.link)"
                    :key="p"
                  >
                    {{ p?.title }}
                  </li>
                </ul>
              </el-scrollbar>
            </template>
          </card>
        </div>

        <div class="col-md-6 col-sm-12 col-short-padding">
          <card
            shadow
            bodyClasses="p-0"
            headerClasses="pl-3 pt-3 pr-3 pb-1"
            style="height: 100%"
          >
            <template #header>
              <div class="row justify-content-between pr-4">
                <h4>Eventos</h4>
                <div class="row">
                  <base-button
                    type="primary"
                    size="sm"
                    @click="setReferenceDate(-7)"
                    icon="ni ni-bold-left"
                  ></base-button>

                  <base-button
                    type="primary"
                    size="sm"
                    @click="setReferenceDate(7)"
                    icon="ni ni-bold-right"
                  ></base-button>
                </div>
              </div>
            </template>
            <template #default>
              <base-loader v-if="!events" style="height: 500px"></base-loader>
              <base-calendar
                id="studentCalendar"
                v-else
                :key="calendarKey"
                :options="getCalendarOptions()"
                :events="Events"
                @get-calendar="calendar = $event"
              ></base-calendar>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Charts

import DashboardNavbar from "../layout/DashboardNavbar";
import ApexCharts from "vue3-apexcharts";
import BaseLoader from "../components/BaseLoader.vue";
import BaseCalendar from "../components/BaseCalendar.vue";
import StatsCard from "../components/StatsCard";
import EventService from "../services/events";
import Card from "../components/Card.vue";
import { uuid } from "vue-uuid";
import { ElScrollbar, ElInput } from "element-plus";
export default {
  data() {
    return {
      mentions: null,
      enrols: null,
      calendarKey: uuid.v4(),
      events: null,
      referenceDate: new Date(),
      students: null,
      notifications: null,
      enrolsLastMonth: null,
      searchNotification: null,
      dateFormatter: new Intl.DateTimeFormat("pt-BR", { month: "short" }),
      currencyFormatter: new Intl.NumberFormat("pt-br", {
        minimumFractionDigits: 2,
      }),
      eventColors: {
        normal: "#0069c0",
        urgent: "#fb8c00",
        info: "#006978",
        problem: "#b91400",
      },
    };
  },
  components: {
    DashboardNavbar,
    StatsCard,
    ApexCharts,
    Card,
    ElScrollbar,
    BaseCalendar,
    BaseLoader,
    ElInput,
  },
  methods: {
    getCalendarOptions() {
      return {
        initialView: "listSixDays",
        selectable: true,
        headerToolbar: false,
        displayEventTime: false,
        height: "auto",
        views: {
          listSixDays: {
            type: "list",
            visibleRange: {
              start: new Date(
                Date.UTC(
                  this.referenceDate.getFullYear(),
                  this.referenceDate.getMonth(),
                  this.referenceDate.getDate()
                )
              ),
              end: new Date(
                Date.UTC(
                  this.referenceDate.getFullYear(),
                  this.referenceDate.getMonth(),
                  this.referenceDate.getDate() + 7
                )
              ),
            },
          },
        },
        contentHeight: "500px",
        //display: "block",
        //weekends:false,
        noEventsContent: "Nenhum evento cadastrado",
        eventClassNames: ["font-12", "clickable"],
        locale: "pt-br",
        eventClick(e) {
          this.navigateToRoute(
            `/alunos/${e?.event?.extendedProps?.object?.student?.uid}`
          );
        },
      };
    },
    navigateToRoute(uid) {
      let route = this.$router.resolve(uid);
      window.open(route.href, "_blank");
    },
    getLastMonths(months) {
      const dates = [];
      const now = new Date();
      while (months--) {
        dates.push(now.toISOString());
        now.setMonth(now.getMonth() - 1);
      }

      return dates;
    },
    fetchMentions() {
      const url = new URL(`${this.$store.state.apiUrl}mentions`);

      if (this.searchNotification)
        url.search = new URLSearchParams({
          searchName: this.searchNotification,
        });

      fetch(url, {
        credentials: "include",
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((r) => r.json())
        .then((json) => (this.mentions = json));
    },
    async fetchEvents() {
      const { events } = await EventService().index({
        start_at: new Date(
          Date.UTC(
            this.referenceDate.getFullYear(),
            this.referenceDate.getMonth(),
            this.referenceDate.getDate()
          )
        ).toString(),
        expire_at: new Date(
          Date.UTC(
            this.referenceDate.getFullYear(),
            this.referenceDate.getMonth(),
            this.referenceDate.getDate() + 7
          )
        ).toString(),
      });

      if (events) this.events = events;
    },
    setReferenceDate(days) {
      this.referenceDate.setDate(this.referenceDate.getDate() + days);
      this.fetchEvents();
    },
    fetchNotifications() {
      this.notifications = null;
      const url = new URL(
        `${this.$store.state.apiUrl}users/${this.$store.state.user.uid}/notifications`
      );

      if (this.searchNotification)
        url.search = new URLSearchParams({
          searchName: this.searchNotification,
        });

      fetch(url, {
        credentials: "include",
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((r) => r.json())
        .then((json) => (this.notifications = json));
    },
    fetchStudentsInMonth() {
      const url = new URL(`${this.$store.state.apiUrl}students`);
      url.search = new URLSearchParams({
        created_at_start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ),
        created_at_end: new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ),
        school_uid: this.SelectedSchool?.uid,
      });
      fetch(url, {
        credentials: "include",
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((r) => r.json())
        .then((json) => (this.students = json));
    },
    showEventWithColors(eventCategory) {
      return this.eventColors[`${eventCategory}`] || this.eventColors["normal"];
    },
    rebuildCalendar() {
      this.calendarKey = uuid.v4();
    },
    fetchEnrolsInMonth() {
      const url = new URL(`${this.$store.state.apiUrl}enrols`);
      url.search = new URLSearchParams({
        start_in_start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ),
        start_in_end: new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ),
        school_uid: this.SelectedSchool?.uid,
      });
      fetch(url, {
        credentials: "include",
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((r) => r.json())
        .then((json) => (this.enrols = json));
    },
  },
  watch: {
    SelectedSchool() {
      this.fetchEnrolsInMonth();
      this.fetchStudentsInMonth();
    },
    events() {
      this.rebuildCalendar();
    },
  },
  computed: {
    SelectedSchool() {
      return this?.$store?.state?.user?.selectedSchool;
    },
    Mentions() {
      return this.mentions || [];
    },
    Notifications() {
      return this.notifications || [];
    },
    Events() {
      return this.events?.map((e) => ({
        title: `${e.label} para aluno(a) ${e?.student?.name}`,
        id: e.uid,
        start: e.day,
        object: e,
        allDay: true,
        backgroundColor: this.showEventWithColors(e.category),
        editable: true,
      }));
    },
    MergeMentionsAndNotifications() {
      return [
        ...(this.Mentions.map((s) => ({
          title: `Você foi marcado na observação ${s?.observation?.title} do(a) aluno(a) ${s?.observation?.student?.name}`,
          link: `/alunos/${s?.observation?.student?.uid}`,
          created_at: new Date(s.created_at),
        })) || []),
        ...(this.Notifications.map((n) => ({
          title: `${n?.user?.name} ${n.title}`,
          link: `/alunos/${n.item_uid}`,
          created_at: new Date(n.created_at),
        })) || []),
      ].sort((a, b) => b.created_at - a.created_at);
    },
    ActualMonthEnrols() {
      return (
        this?.enrols?.filter(
          (e) => new Date(e.start_in).getMonth() === new Date().getMonth()
        ) || []
      );
    },
    ActualMonthStudents() {
      return (
        this?.students?.filter(
          (s) => new Date(s.created_at).getMonth() === new Date().getMonth()
        ) || []
      );
    },
    EnrolChartSeries() {
      const totalEnrols = {};

      this.ActualMonthEnrols?.forEach((e) => {
        if (totalEnrols[e._course.level]) totalEnrols[e._course.level]++;
        else totalEnrols[e._course.level] = 1;
      });

      return totalEnrols; //Object.entries(totalEnrols).map((e) => e[1].data);
    },
    EnrolsChartOptions() {
      //const _this = this;
      return {
        labels: Object.keys(this.EnrolChartSeries),
        noData: {
          text: "Nenhuma matrícula realizada",
          align: "center",
          verticalAlign: "middle",
        },
        legend: {
          show: false,
        },
        //colors: ["#4caf50", "#bdbdbd", "#ff5722"],
        /*chart: {
          stacked: true,
          zoom: {
            enabled: false,
          },

        },*/
        /*tooltip: {
          y: {
            formatter(v) {
              return `${v} matrículas`;
            },
          },
        },
        
        xaxis: {
          type: "datetime",
          labels: {
            formatter(value, timestamp) {
              return _this.dateFormatter.format(new Date(timestamp));
            },
          },
        },*/
      };
    },
  },
  mounted() {
    this.fetchMentions();
    this.fetchEnrolsInMonth();
    this.fetchStudentsInMonth();
    this.fetchNotifications();
    this.fetchEvents();
  },
};
</script>
<style>
.fill-height {
  height: 48.5% !important;
}
.margin-bottom-1 {
  margin-bottom: 1% !important;
}
.margin-top-1 {
  margin-top: 1% !important;
}
.font-12 {
  font-size: 12px !important;
}
.clickable {
  cursor: pointer;
}
.fc-day > th {
  padding-left: 0px !important;
}
.col-short-padding {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
</style>
