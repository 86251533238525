<template>
  <label class="custom-toggle">
    <input type="checkbox" v-model="model" v-bind="$attrs" />
    <span
      class="custom-toggle-slider rounded-circle obscure-border-switch"
    ></span>
  </label>
</template>
<script>
export default {
  name: "base-switch",
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      default: false,
      description: "Switch modelValue",
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(modelValue) {
        this.$emit("update:modelValue", modelValue);
      },
    },
  },
};
</script>
<style>
.obscure-border-switch {
  border: 1px solid #aeaeae !important;
}
</style>
