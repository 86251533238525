<template>
  <modal
    :show="showModal"
    @update:show="showModal = $event"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template v-slot:header>
        <div class="row">
          <div class="col">
            <h4 class="modal-title">Upload de arquivo CSV</h4>
            <h6 class="text-muted">{{ uploadMessage }}</h6>
          </div>
          <h4>{{ uploadState }}</h4>
        </div>
      </template>

      <div class="text-left mb-2">
        <h5>Dados:</h5>
      </div>
      <form role="form">
        <div class="row">
          <div class="col col-12">
            <div class="form-group row mb-2">
              <div class="col-4">
                <label class="col-form-label form-control-label"
                  >Instituição de Ensino:</label
                >
              </div>
              <div class="col col-8">
                <el-select
                  noData="Nenhuma instituição cadastrada"
                  type="secondary"
                  :items="Schools"
                  value-key="uid"
                  placeholder="Selecione a instituição de ensino"
                  v-model="selectedSchool"
                >
                  <el-option
                    v-for="s in Schools"
                    :key="s.uid"
                    :label="s.initials"
                    :value="s.uid"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-12">
            <div class="form-group row mb-2">
              <div class="col-4">
                <label class="col-form-label form-control-label"
                  >Arquivo CSV</label
                >
              </div>
              <div class="col col-8">
                <base-upload @uploaded-file="saveFile"></base-upload>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="allowSelectComission">
          <div class="col col-12">
            <div class="form-group row mb-2">
              <div class="col-4">
                <label class="col-form-label form-control-label"
                  >Comissão cadastrada</label
                >
              </div>
              <div class="col col-8">
                <el-select
                  v-model="comission_id"
                  clearable
                  remote
                  value-key="uid"
                  :loading="!comissions"
                  @visible-change="fetchComissions"
                  popper-append-to-body
                  placeholder="Selecione o tipo de parcelamento"
                >
                  <template #empty>
                    <h4 class="text-muted">
                      Não existem comissões cadastradas
                    </h4>
                  </template>
                  <div v-if="comissions?.length">
                    <el-option
                      v-for="item in comissions || []"
                      :key="item?.uid"
                      :label="item?.name"
                      :value="item"
                    >
                    </el-option>
                  </div>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <h5>{{ Errors }}</h5>
        </div>
      </form>
      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button
            size="sm"
            @click="showModal = false"
            class="float-right"
            type="danger"
            >CANCELAR</base-button
          >
          <base-button
            size="sm"
            @click="startUpload"
            class="float-right"
            :disabled="!!Errors?.length"
            :isLoading="isLoadingSubmit"
            type="primary"
            >SALVAR DADOS NA API</base-button
          >
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
import { ElSelect, ElOption, ElNotification } from "element-plus";
import BaseButton from "../../components/BaseButton.vue";
import BaseUpload from "../../components/BaseUpload.vue";
import { convertCsvToObject } from "../../utils/integrations";
import ComissionService from "../../services/comissions";
import { dateReverse } from "../../utils/formatters";
export default {
  components: { BaseButton, ElSelect, ElOption, BaseUpload },
  name: "upload-data",
  props: {
    shouldShow: {
      type: Boolean,
    },
    uploadMessage: {
      type: String,
      default: () => "Você está enviando dados para alunos",
    },
    csvRules: {
      type: String,
      default: "category,id",
    },
    uploadMethod: {
      type: Function,
      default: () => () => {},
    },
    allowSelectComission: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedSchool() {
      this.comission_id = null;
    },
  },
  computed: {
    CSVData() {
      return convertCsvToObject(this.fileTxt);
    },
    Errors() {
      if (this.errors?.length) return this.errors;

      if (!this.selectedSchool?.length)
        return "Selecione uma instituição de ensino";

      if (!this.fileTxt) return "Selecione um arquivo CSV para upload";
      else return this.validateCSV(this.CSVData[0]);

      //return null;
    },
    showModal: {
      get: function () {
        return this.shouldShow;
      },
      set: function () {
        this.$emit("close-modal", false);
      },
    },
    SelectedSchool() {
      return this?.$store?.state?.user?.selectedSchool?.uid;
    },
    Schools() {
      return this.$store.state.user.schools;
    },
  },
  data() {
    return {
      isLoadingSubmit: false,
      uploadState: null,
      selectedSchool: this.SelectedSchool,
      fileTxt: null,
      errors: "",
      comission_id: null,
      comissions: null,
    };
  },
  methods: {
    async fetchComissions() {
      const { comissions } = await ComissionService().index({
        school_uid: this.selectedSchool,
      });

      if (comissions) this.comissions = comissions;
    },
    setErrors(error) {
      this.errors += error;
    },
    setUploadState(state) {
      this.uploadState = state;
    },
    async startUpload() {
      this.isLoadingSubmit = true;
      const self = this;
      this.errors = "";

      this.uploadMethod(
        this.selectedSchool,
        this.CSVData,
        this.setUploadState,
        this.setErrors,
        this.comission_id
      )
        .catch((e) => {
          self.errors = e;
        })
        .finally(() => this.finalizeUpload());
    },
    finalizeUpload() {
      this.isLoadingSubmit = false;
      this.$emit("close-modal");
      this.$emit("should-update");
      ElNotification.success("Upload efetuado com sucesso");
    },
    validateCSV(data) {
      const rules = this.csvRules.split(",");

      for (let rule of rules)
        if (!(rule in data)) return `${rule} está faltando`;

      if ("birthday" in data && !new Date(dateReverse(data.birthday)).getTime())
        return "Campo data de nascimento em formato incorreto";

      return null;
    },
    saveFile(f) {
      this.fileTxt = f;
    },
    selectSchool(v) {
      this.selectedSchool = v.name;
    },
  },
};
</script>
<style scoped>
.el-select {
  display: block;
}
a.dropdown-item {
  cursor: pointer;
}
</style>
