<template>
  <base-nav
    class="navbar-top navbar-dark bg-primary"
    id="navbar-main"
    :show-toggle-button="true"
    expand
  >
    <div class="col-12">
      <ul class="navbar-nav row justify-content-between ml-0">
        <div class="col-xl-8 col-md-8 col-sm-6 col-xs-4">
          <div class="navbar-search navbar-search-dark form-inline">
            <div class="form-group mb-0">
              <base-input
                :placeholder="searchHint"
                class="input-group-alternative"
                alternative=""
                :inputDark="false"
                v-model="searchQuery"
                v-on:keyup.enter="emitQuery"
                addon-right-icon="fa fa-search"
              >
              </base-input>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 col-sm-6 col-xs-8">
          <li
            class="nav-item"
            v-for="a in actions"
            :key="a.icon"
            @click="a.action"
          >
            <a class="nav-link nav-link-icon bg-primary" href="#">
              <i :class="a.icon"></i>
            </a>
          </li>

          <div class="row justify-content-end">
            <li class="nav-item dropdown pull-right mr-1">
              <el-dropdown @command="selectSchool" trigger="click">
                <el-button class="btn btn-primary btn-large no-border">
                  {{ SelectedSchool.initials
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <template #dropdown>
                  <h5 class="p-2 text-muted">Instituições de Ensino</h5>
                  <el-dropdown-menu v-if="Schools.length">
                    <el-dropdown-item
                      :command="school"
                      v-for="school in Schools"
                      :key="school.uid"
                      >{{ school.initials }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                  <el-dropdown-menu v-else>
                    <el-dropdown-item disabled>
                      Nenhuma Instituição cadastrada
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </li>
            <li class="nav-item dropdown pull-right">
              <el-dropdown trigger="click">
                <el-button class="btn btn-primary no-border">
                  {{ $store?.state?.user?.firstName }}
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <router-link to="/conta"
                      ><el-dropdown-item
                        >Minha conta</el-dropdown-item
                      ></router-link
                    >
                    <router-link to="/sair">
                      <el-dropdown-item>Sair</el-dropdown-item>
                    </router-link>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </li>
          </div>
        </div>
      </ul>
    </div>
  </base-nav>
</template>
<script>
import {
  ElDropdownItem,
  ElDropdownMenu,
  ElDropdown,
  ElButton,
} from "element-plus";
//import BaseDropdown from "../components/BaseDropdown.vue";
export default {
  props: ["searchHint", "actions"],
  components: {
    ElDropdownItem,
    ElDropdownMenu,
    ElDropdown,
    ElButton,
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
    };
  },
  emits: ["updateSearch"],
  computed: {
    Schools() {
      return this.$store?.state?.user?.schools || [];
    },
    SelectedSchool() {
      return this?.$store?.state?.user?.selectedSchool || {};
    },
  },
  methods: {
    emitQuery(event) {
      event.preventDefault();
      this.$emit("updateSearch", this.searchQuery);
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    selectSchool(v) {
      this.$store.commit("selectSchool", v);
    },
  },
};
</script>
<style scoped>
.el-button.el-button--default {
  background: #1565c0 !important;
  color: white !important;
}
input.input-dark {
  color: white !important ;
}
.no-border {
  border: 0px !important;
}
</style>
