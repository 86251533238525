<template>
  <modal
    v-model:show="showModal"
    body-classes="p-3"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <template #header>
      <h4 class="modal-title" id="exampleModalLabel">Detalhes do Pagamento</h4>
    </template>

    <div>
      <base-loader v-if="isLoading && payment_"></base-loader>
      <div v-else>
        <!--Obs-->
        <div class="form-group row mb-2">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Nome do Aluno:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              disabled
              :modelValue="payment_?.student?.name"
            ></base-input>
          </div>
        </div>

        <!--Obs-->
        <div class="form-group row mb-2">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Código da Matrícula:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              :modelValue="payment_?.enrol?.code || 'desconhecido'"
              disabled
            ></base-input>
          </div>
        </div>

        <!--Obs-->
        <div class="form-group row mb-2">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Nome do Curso:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              disabled
              :modelValue="payment_?.enrol?._course?.name || 'desconhecido'"
            ></base-input>
          </div>
        </div>

        <!--Obs-->
        <div class="form-group row mb-2">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Valor do Título:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              disabled
              :modelValue="currencyFormatter.format(payment_?.cost || 0)"
            ></base-input>
          </div>
        </div>

        <!--Obs-->
        <div class="form-group row mb-2">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Valor Pago:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              disabled
              :modelValue="
                payment_?.value
                  ? currencyFormatter.format(payment_?.value)
                  : '-'
              "
            ></base-input>
          </div>
        </div>

        <!--Obs-->
        <div class="form-group row mb-2">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Data de Vencimento:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              :modelValue="dateFormatter.format(new Date(payment_?.expires_at))"
              disabled
            ></base-input>
          </div>
        </div>

        <!--Obs-->
        <div class="form-group row mb-2">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Data de Pagamento:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              :modelValue="
                payment_?.paid_at
                  ? dateFormatter.format(new Date(payment_?.paid_at))
                  : '-'
              "
              disabled
            ></base-input>
          </div>
        </div>

        <!--Nome da Comissão-->
        <div class="form-group row mb-2" v-if="userCan('comissions.r')">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >% de Repasse:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              :modelValue="
                Number.parseFloat(payment_?.comission) != NaN
                  ? `${Number.parseFloat(payment_?.comission) * 100} %`
                  : 'desconhecido'
              "
              disabled
            ></base-input>
          </div>
        </div>

        <div class="form-group row mb-2" v-if="userCan('comissions.r')">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Comissão:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              :modelValue="
                payment_?._comission?.name ?? payment_?.enrol?.comission?.name
              "
              disabled
            ></base-input>
          </div>
        </div>

        <!--Obs-->
        <div class="form-group row mb-2">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Estado do Título:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              disabled
              :modelValue="PaymentStates[`${payment_?.status}`]"
            ></base-input>
          </div>
        </div>

        <div class="form-group row mb-2">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Tipo do Título:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              disabled
              :modelValue="payment_?.category"
            ></base-input>
          </div>
        </div>

        <div class="form-group row mb-2">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Forma de Pagamento:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              disabled
              :modelValue="payment_?.type"
            ></base-input>
          </div>
        </div>

        <!--Obs-->
        <div class="form-group row mb-2">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Cadastrado em:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              disabled
              :modelValue="
                dateFormatter.format(new Date(payment_?.created_at || '0000'))
              "
            ></base-input>
          </div>
        </div>

        <!--Obs-->
        <div class="form-group row mb-2">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Cadastrado por:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              disabled
              :modelValue="payment_?.createdBy?.name || 'desconhecido'"
            ></base-input>
          </div>
        </div>

        <!--Obs-->
        <div class="form-group row mb-2">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Atualizado em:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              disabled
              :modelValue="
                dateFormatter.format(new Date(payment_?.updated_at || '0000'))
              "
            ></base-input>
          </div>
        </div>

        <div class="form-group row mb-2">
          <label
            class="col-lg-4 col-md-12 col-sm-12 col-form-label form-control-label"
            >Atualizado por:</label
          >
          <div class="col-lg-8 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              disabled
              :modelValue="payment_?.updatedBy?.name || 'desconhecido'"
            ></base-input>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import BaseLoader from "../../components/BaseLoader.vue";
export default {
  name: "payment-details-modal",
  props: ["shouldShow", "payment"],
  components: { BaseLoader },
  data() {
    return {
      isLoading: true,
      hasError: false,
      payment_: null,
      intlDate: new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "full",
        timeStyle: "short",
      }),
      dateFormatter: {
        format: (v) =>
          new Date(v) != "Invalid Date" ? this.intlDate.format(v) : v,
      },
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
    };
  },
  watch: {
    payment() {
      this.isLoading = true;
      this.fetchPayment();
    },
  },
  mounted() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
  methods: {
    userCan(permission) {
      const { authorizations } = this.$store.state.user?.role || {};

      if (authorizations) {
        const [entity, action] = permission.split(".");

        if (authorizations[entity] && authorizations[entity].includes(action))
          return true;
      }

      return false;
    },
    fetchPayment() {
      fetch(this.$store.state.apiUrl + "payments/" + this.payment.uid, {
        credentials: "include",
        //mode: "no-cors",
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => {
          if (response.status == 200) return response.json();
          else throw response.json();
        })
        .then((json) => (this.payment_ = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    showModal: {
      get() {
        return this.shouldShow;
      },
      set(v) {
        this.$emit("close-modal", v);
      },
    },
    PaymentStates() {
      return {
        opened: "aberto",
        paid: "pago",
        closed: "fechado",
        undefined: "desconhecido",
      };
    },
  },
};
</script>
