<template>
  <modal
    @update:show="showModal = $event"
    :show="showModal"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-xxl"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-0 py-lg-1"
      class="border-0"
    >
      <template v-slot:header>
        <div class="row justify-content-between">
          <div style="display: flex; flex-direction: column; max-width: 80%">
            <h4 class="modal-title">
              Atualizar pagamentos dos seguintes alunos
            </h4>
            <h6 class="text-muted">Selecione os alunos que deseja buscar</h6>
          </div>
          <base-button
            type="primary"
            icon="fa fa-download"
            size="sm"
            style="float: right; height: fit-content"
            v-on:click.stop.prevent="() => this.downloadSyncMessages()"
          ></base-button>
        </div>
      </template>

      <base-input
        label="URL:"
        v-model="systemUrl"
        formClasses="input-group-alternative mx-2"
        placeholder="https://endereçodosite.com"
      ></base-input>

      <div class="row justify-content-end sync-controls">
        <base-button
          size="sm"
          @click="stopSync()"
          v-if="keepUpdating"
          class="float-right"
          type="warning"
          >PAUSAR</base-button
        >
        <base-button
          size="sm"
          v-else
          @click="startSync()"
          class="float-right"
          type="secondary"
          >SINCRONIZAR</base-button
        >
      </div>

      <base-loader v-if="isLoading"></base-loader>

      <div class="table-responsive" v-else>
        <base-table
          thead-classes="thead-light"
          tbody-classes="list"
          :limit="limit"
          :offset="(pagination - 1) * limit"
        >
          <template v-slot:columns>
            <th v-for="col in TableColumns" :key="col">
              {{ col }}
            </th>
          </template>

          <!--

        <tr v-for="(items, index) of tableData" :key="index">
          <td v-for="item of items" :key="item">
            <div v-if="Array.isArray(item)">
              <div class="btn-group">
                <base-button v-for="button of item" :key="button" type="primary"><i :class="button"></i></base-button>
              </div>
            </div>
            <h5 v-else>
              {{ item.value }}
            </h5>
          </td>
        </tr>-->

          <template v-slot:data v-if="hasData">
            <tr v-for="p in Students" :key="p" :class="p.sync_status || 'none'">
              <td>
                <base-checkbox
                  v-model="p.should_update"
                  :checked="p.should_update"
                ></base-checkbox>
              </td>
              <td>
                {{ p.name }}
              </td>
              <td>
                {{ p.enrols?.length }}
              </td>
              <td>
                {{ p.payments?.length }}
              </td>
              <td>
                {{
                  currencyFormatter.format(
                    sumRemainingPayments(p.payments || [])
                  )
                }}
              </td>
              <td>
                {{ updatedPayments[p.uid] || 0 }}
              </td>
              <td>
                <el-popover
                  placement="left"
                  trigger="hover"
                  width="1200px"
                  :title="getStatusTitle(p.sync_status)"
                >
                  <template #reference>
                    <base-button
                      size="sm"
                      :type="getStatusType(p.sync_status)"
                      >{{ getStatusLabel(p.sync_status) }}</base-button
                    >
                  </template>
                  <template #content>
                    <p
                      v-for="c in getStatusContent(p.uid, p.sync_status)"
                      :key="c + getStatusContent(p.uid, p.sync_status)?.length"
                    >
                      c
                    </p>
                  </template>
                </el-popover>
              </td>
            </tr>
          </template>
        </base-table>
      </div>
    </card>
  </modal>
</template>
<script>
import BaseTable from "../../components/BaseTable.vue";
import BaseLoader from "../../components/BaseLoader.vue";
import BaseInput from "../../components/BaseInput.vue";
import BaseCheckbox from "../../components/BaseCheckbox.vue";
import BaseButton from "../../components/BaseButton.vue";
import { ElNotification, ElPopover } from "element-plus";
export default {
  name: "UpdatePaymentsModal",
  props: {
    data: { type: Array },
    shouldShow: { type: Boolean },
  },
  data() {
    return {
      isLoading: true,
      students: [],
      syncMessages: {},
      currentStudent: 0,
      updatedPayments: {},
      systemUrl: null,
      limit: 15,
      keepUpdating: false,
      pagination: 1,
      currencyFormatter: new Intl.NumberFormat("pt-br", {
        minimumFractionDigits: 2,
      }),
    };
  },
  components: {
    BaseTable,
    BaseLoader,
    BaseInput,
    BaseCheckbox,
    BaseButton,
    ElPopover,
  },
  watch: {
    shouldShow(v) {
      if (v) this.fetchStudents();
    },
  },
  computed: {
    TableColumns() {
      return [
        "sincronizar",
        "nome",
        "matriculas",
        "parcelas",
        "total em aberto",
        "títulos atualizados",
        "status",
      ];
    },
    Students() {
      return this.students || [];
    },
    SelectedSchool() {
      return this?.$store?.state?.user?.selectedSchool;
    },
    showModal: {
      get() {
        return this.shouldShow;
      },
      set(v) {
        this.$emit("close-modal", v);
      },
    },
    hasData() {
      return !!this?.students?.length;
    },
  },
  methods: {
    downloadSyncMessages() {
      const data = encodeURI(
        "data:text/plain;charset=utf-8," + this.formatSyncMessages()
      );
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Log de Sincronização.txt");
      link.click();
    },
    formatSyncMessages() {
      return Object.entries(this.syncMessages)
        ?.map((e) => {
          const uid = e[0];
          const log = e[1];
          const student = this.students.find((s) => s.uid === uid);

          return `ALUNO: ${student.name}\n${log?.join(
            "\n"
          )}\n=====================================================================================================\n`;
        })
        .join("\n");
    },
    async syncStudent(student) {
      if (student) {
        student.sync_status = "running";
        fetch(`${this.$store.state.apiUrl}students/${student.uid}/syncs`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ cpf: student.cpf, url: this.systemUrl }),
        })
          .then((r) => {
            if (r.status === 200) {
              return r.json();
            } else throw r.json();
          })
          .then((json) => this.checkPayments(student, json))
          .catch(() => (student.sync_status = "error"))
          .finally(() =>
            this.keepUpdating ? this.syncStudent(this.getNextStudent()) : null
          );
      }
    },
    checkPayments(student, syncedPayments) {
      const paidPayments = this.filterPaidPayments(syncedPayments);

      paidPayments?.forEach((p) => {
        const relatedPayment = student.payments
          ?.filter((_) => _.status === "opened")
          ?.find(
            (rP) =>
              rP.category === p.category &&
              Number(rP.enrol?.code) === p.enrol_code &&
              Number(rP.order) === p.order &&
              Number(rP.total) === p.total &&
              new Date(rP.expires_at).toLocaleString() ===
                new Date(p.expires_at).toLocaleString()
          );

        if (relatedPayment) {
          this.updatePayment(
            {
              uid: relatedPayment.uid,
              value: p.value,
              paid_at: p.paid_at,
              enrol_code: p.enrol_code,
              order: p.order,
              status: "paid",
              total: p.total,
            },
            student
          );

          student.sync_status = "completed";

          this.increaseUpdatedPayments(student.uid);

          this.registerStudentLog(
            student.uid,
            `TÍTULO PAGO: Título ${relatedPayment.order}/${relatedPayment.total} da matrícula ${p.enrol_code} no valor de ${relatedPayment.cost} foi pago em ${p.paid_at}`
          );
        } /*else
          this.registerStudentLog(
            student.uid,
            `TÍTULO NÃO ENCONTRADO: Título ${p.order}/${p.total} da matrícula ${p.enrol_code} no valor de ${p.cost} não foi encontrado na nossa base de dados`
          );*/
      });

      //this.checkRenegotiations();
      if (student.sync_status === "running") student.sync_status = "no-change";
    },
    increaseUpdatedPayments(studentId) {
      this.updatedPayments[studentId] =
        (Number(this.updatedPayments[studentId]) || 0) + 1;
    },
    checkRenegotiations(student, syncedPayments) {
      const rnInSystem = student.payments?.filter?.(
        (p) => p.category === "RN"
      )?.length;
      const rnSynced = syncedPayments?.filter(
        (p) => p.category === "RN"
      )?.length;

      if (rnInSystem != rnSynced)
        this.registerStudentLog(
          student.uid,
          `RENEGOCIAÇÕES FALTANDO: Foram encontrados ${rnSynced} renegociações no sistema da instituição`
        );
    },
    registerStudentLog(uid, log) {
      if (Array.isArray(this.syncMessages[uid]))
        this.syncMessages[uid].push(log);
      else this.syncMessages[uid] = [log];
    },
    filterPaidPayments(payments) {
      return payments?.filter((p) => p.status === "paid") || [];
    },
    startSync() {
      this.keepUpdating = true;
      if (this.systemUrl?.length) {
        this.syncStudent(this.getNextStudent());
        this.syncStudent(this.getNextStudent());
        this.syncStudent(this.getNextStudent());
        return this.syncStudent(this.getNextStudent());
      }

      ElNotification.error({
        title: "Não foi possível iniciar a sincronização",
        message:
          "Você deve informar a url do sistema de boletos da instituição",
      });

      this.stopSync();
    },
    stopSync() {
      this.keepUpdating = false;
    },
    getNextStudent() {
      if (this.currentStudent < this.students.length) {
        const i = this.currentStudent;
        this.currentStudent++;
        return this.students[i]?.should_update
          ? this.students[i]
          : this.getNextStudent();
      } else return null;
    },
    sumRemainingPayments(payments) {
      return payments?.reduce(
        (t, p) => (t += p.status === "opened" ? Number(p.cost) : 0),
        0
      );
    },
    getStatusTitle(status) {
      return STATUS_TITLE[status] || "";
    },
    getStatusContent(studentId, status) {
      return this.syncMessages[studentId] || STATUS_CONTENT[status];
    },
    getStatusLabel(status) {
      return STATUS_LABEL[status] || "";
    },
    getStatusType(status) {
      return STATUS_TYPE[status] || "";
    },
    addStatusAndShouldUpdate(students) {
      return students?.map((s) => ({
        ...s,
        should_update: true,
        sync_status: "waiting",
      }));
    },
    updatePayment(payment, student) {
      fetch(`${this.$store.state.apiUrl}payments/${payment.uid}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payment),
      })
        .then(async (response) => {
          if (response.status == 200) {
            return response.json();
          }
          throw await response.json();
        })
        .then(
          () => {},
          () => {
            this.registerStudentLog(
              student.uid,
              `ERRO AO ATUALIZAR: Ocorreu um erro ao atualizar o título ${payment.order}/${payment.total} da matrícula ${payment.enrol_code} do aluno ${student.name}`
            );

            ElNotification.error({
              title: "Houve um erro ao atualizar um título",
              message: "Verifique os logs da sincronização",
            });
          }
        )
        .finally(() => (this.isLoadingSubmit = false));
    },
    fetchStudents() {
      const url = new URL(`${this.$store.state.apiUrl}students`);
      url.search = new URLSearchParams({
        pagination: this.pagination,
        limit: this.limit,
        has_remaining_payments: true,
        school_uid: this.SelectedSchool.uid,
      });
      fetch(url, {
        credentials: "include",
        method: "GET",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          throw response.json();
        })
        .then((json) => {
          this.students.push(
            ...(this.addStatusAndShouldUpdate(json?.data) || [])
          );

          if (json.page < json.lastPage) {
            this.pagination++;
            this.fetchStudents();
          }
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
const STATUS_TITLE = {
  waiting: "Aguardando execução",
  running: "Solicitação enviada ao servidor",
  completed: "Dados recebidos, títulos do aluno sincronizados",
  error: "A resposta não foi a esperada, verifique este aluno",
  "no-change":
    "A resposta foi recebida, mas não houve títulos para sincronizar",
};
const STATUS_TYPE = {
  waiting: "secondary",
  running: "info",
  completed: "success",
  error: "danger",
  "no-change": "warning",
};
const STATUS_CONTENT = {
  waiting: "Os dados deste aluno ainda não foram enviados ao servidor.",
  running:
    "Requisitamos os dados deste aluno ao site da instituição, aguardando a resposta",
  completed:
    "Recebemos os títulos do aluno da instituição, e já foi atualizado tudo no nosso sistema.",
  error:
    "O servidor da instituição parece estar indisponível ou sobrecarregado, se o erro for com apenas este aluno verifique o CPF e tente novamente.",
  "no-change":
    "Isso acontece quando existem renegociações não cadastradas no nosso sistema.",
};
const STATUS_LABEL = {
  waiting: "aguardando",
  running: "enviado",
  completed: "finalizado",
  error: "erro",
  "no-change": "sem alterações",
};
</script>
<style>
.modal-xxl {
  max-width: 1200px !important;
}
.none {
  background-color: white;
}
.completed {
  background-color: #dcedc8;
}
.error {
  background-color: #ef9a9a;
}
.no-change {
  background-color: azure;
}
.sync-controls {
  padding: 24px;
}
</style>