import http from "./http";
export default (periodId) => ({
	async index(filters) {
		return http
			.index("periods", filters)
			.then((json) => ({ periods: json }))
			.catch((e) => ({ periods: null, error: e }));
	},
	async get() {
		return http
			.get("periods", periodId)
			.then((json) => ({ period: json }))
			.catch((e) => ({ period: null, error: e }));
	},
	async create(data) {
		return http
			.post("periods", data)
			.then((json) => ({ period: json }))
			.catch((e) => ({ period: null, error: e }));
	},
	async update(data) {
		return http
			.put("periods", periodId, data)
			.then((json) => ({ period: json }))
			.catch((e) => ({ period: null, error: e }));
	},
	async delete() {
		return http
			.delete("periods", periodId)
			.then((json) => ({ period: json }))
			.catch((e) => ({ period: null, error: e }));
	},
});
