<template>
  <div>
    <slot />
    <table class="table tablesorter">
      <thead :class="theadClasses">
        <tr>
          <slot name="columns" :columns="columns">
            <!--<th v-for="column in columns" :key="column">{{ column }}</th>-->
          </slot>
        </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <slot name="data"></slot>
      </tbody>
    </table>
    <div v-if="!hasRows" class="col-12 py-3">
      <div class="row justify-content-center">
        <h4 class="text-muted">
          O servidor não retornou dados para sua busca.
        </h4>
      </div>
    </div>
    <tfooter v-if="hasFooter">
      <slot name="footer"></slot>
    </tfooter>
  </div>
</template>
<script>
export default {
  name: "base-table",
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    hasInlineRoute: {
      type: Boolean,
      default: false,
      description: "Table should insert click event on each row of table",
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type",
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
    limit: {
      type: Number,
      default: 15,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    colsWithValue() {
      return (row) => {
        return this.columns.filter((column) => this.hasValue(row, column));
      };
    },
    hasRows() {
      return this.$slots.data?.()?.[0]?.children?.length || this.data?.length;
    },
    hasFooter() {
      return this.$slots.data?.()?.[1]?.children?.length || this.data?.length;
    },
  },
};
</script>
<style>
table {
  width: 100% !important;
  display: table;
  padding-left: 10px;
  padding-right: 10px;
  word-break: break-all;
}
th {
  padding-left: 0.2rem !important;
}
tr {
  display: table-row;
  text-transform: none;
  text-align: center;
  letter-spacing: 0px;
  word-wrap: break-word;
  font-size: 9px !important;
}

td {
  padding-left: 0.2rem !important;
  padding-right: 0.2rem !important;
  text-align: center;
}
.is-sortable {
  cursor: pointer;
}
.caret-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  overflow: initial;
  position: relative;
}
.wrap-content {
  word-wrap: break-word !important;
  width: 200px;
  max-width: 200px;
  word-break: break-word !important;
  white-space: pre-line !important;
  overflow-wrap: break-word !important;
  -ms-word-break: break-word !important;
  word-break: break-word;
  text-overflow: clip;
}
.sort-icon {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  position: absolute;
  left: 7px;
}
.is-sorted {
  color: black;
}
.ascending {
  top: -10px;
  height: 0;
}
.descending {
  bottom: -3px;
  height: 0;
}
</style>
