<template>
  <div
    class="custom-control custom-checkbox"
    :class="{ disabled: disabled, 'form-check-inline': inline }"
  >
    <input
      :id="cbId"
      class="custom-control-input"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      :value="modelValue"
      v-on:change="updateValue(!modelValue)"
    />
    <label :for="cbId" class="custom-control-label">
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>
<script>
import { randomString } from "./stringUtils";

export default {
  name: "base-checkbox",
  props: {
    checked: {
      type: [Array, Boolean],
      description: "Whether checkbox is checked",
    },
    disabled: {
      type: Boolean,
      description: "Whether checkbox is disabled",
    },
    inline: {
      type: Boolean,
      description: "Whether checkbox is inline",
    },
    modelValue: {
      type: Boolean,
    },
  },
  data() {
    return {
      cbId: "",
    };
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event);
    },
  },
  mounted() {
    this.cbId = randomString();
  },
};
</script>
