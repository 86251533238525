<template>
  <modal
    @update:show="showModal = $event"
    :show="showModal"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template v-slot:header>
        <h4 class="modal-title" id="exampleModalLabel">
          {{ isNew ? "Cadastrar cargo" : "Editar cargo" }}
        </h4>
        <h6 class="text-muted">
          Selecione as permissões que deseja atribuir para este cargo
        </h6>
      </template>

      <div>
        <div class="form-group row mb-2">
          <label
            class="
              col-lg-3 col-md-12 col-sm-12 col-form-label
              form-control-label
            "
            >Nome:</label
          >
          <div class="col-lg-9 col-md-12 col-sm-12">
            <base-input
              formClasses="input-group-alternative"
              v-model="role_.name"
              required
              placeholder="Administrador"
            ></base-input>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button
            size="sm"
            @click="showModal = false"
            class="float-right"
            type="danger"
            >CANCELAR</base-button
          >
          <base-button
            size="sm"
            @click="save"
            class="float-right"
            type="primary"
            >SALVAR</base-button
          >
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
import BaseButton from "../../components/BaseButton";
import { ElNotification } from "element-plus";
export default {
  components: { BaseButton },
  name: "roles-modal",
  props: ["shouldShow", "role"],
  data() {
    return {
      role_: this.role || {},
    };
  },
  watch: {
    role(v) {
      this.role_ = v || {};
    },
  },
  computed: {
    isNew() {
      return this.role ? false : true;
    },
    showModal: {
      get: function () {
        return this.shouldShow;
      },
      set: function () {
        this.$emit("close-modal", false);
      },
    },
  },
  methods: {
    save() {
      if (this.role) this.updateRole();
      else this.createRole();
    },
    createRole() {
      fetch(`${this.$store.state.apiUrl}roles`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ permissions: "{}", ...this.role_ }),
      })
        .then(async (response) => {
          if (response.status == 200) {
            this.errorMessages = {};
            this.$emit("close-modal", true);
            this.$emit("reload-data", true);
            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
            }),
          (e) =>
            ElNotification.error({
              message: e.message,
            })
        )
        .finally(() => (this.isLoadingSubmit = false));
    },
    updateRole() {
      fetch(`${this.$store.state.apiUrl}roles/${this.role.uid}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(this.role_),
      })
        .then(async (response) => {
          if (response.status == 200) {
            this.errorMessages = {};
            this.$emit("close-modal", true);
            this.$emit("reload-data");
            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
            }),
          (e) =>
            ElNotification.error({
              message: e.message,
            })
        )
        .finally(() => (this.isLoadingSubmit = false));
    },
  },
};
</script>
