import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store/index";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";
import Courses from "../views/Courses.vue";
import StudentDetails from "../views/StudentDetails.vue";
import Students from "../views/Students.vue";
import Users from "../views/Users.vue";
import Roles from "../views/Roles.vue";
import Enrols from "../views/Enrols.vue";
import Schools from "../views/Schools.vue";
import Comissions from "../views/Comissions.vue";
import Incomings from "../views/Incomings.vue";
import Unpaids from "../views/Unpaids.vue";
import Exams from "../views/Exams.vue";
import UserAccount from "../views/UserAccount.vue";
import Tasks from "../views/Tasks.vue";
//import Logoff from "../views/Logoff.vue";

import Login from "../views/Login.vue";

const onlyAuthenticated = (to, from, next) => {
  if (store?.getters?.isAuthenticated) next();
  else next('/entrar');
}

const onlyNotAuthenticated = (to, from, next) => {
  if (store?.getters?.isAuthenticated) next();
  else next();
}

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    beforeEnter: onlyAuthenticated,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/cursos",
        name: "Cursos",
        components: { default: Courses },
      },
      {
        path: "/alunos/:id",
        name: "Detalhes do Aluno",
        components: { default: StudentDetails },
      },
      {
        path: "/tarefas/:id?",
        name: "Minhas tarefas",
        components: { default: Tasks },
      },
      {
        path: "/alunos",
        name: "Alunos Cadastrados",
        components: { default: Students },
      },
      {
        path: "/usuarios",
        name: "Usuários Cadastrados",
        components: { default: Users },
      },
      {
        path: "/cargos",
        name: "Cargos Cadastrados",
        components: { default: Roles },
      },
      {
        path: "/matriculas",
        name: "Matrículas Cadastrados",
        components: { default: Enrols },
      },
      {
        path: "/escolas",
        name: "Instituições de Ensino Cadastradas",
        components: { default: Schools },
      },
      {
        path: "/comissoes",
        name: "Comissões Cadastradas",
        components: { default: Comissions },
      },
      {
        path: "/repasse",
        name: "Repasse das Instituições",
        components: { default: Incomings },
      },
      {
        path: "/inadimplencia",
        name: "Títulos não pagos",
        components: { default: Unpaids },
      },
      {
        path: "/prova",
        name: "Relatório de provas",
        components: { default: Exams },
      },
      {
        path: "/conta",
        name: "Minha Conta",
        components: { default: UserAccount },
      },
      {
        path: '/sair',
        name: 'Sair do sistema',
        //components: { default: Logoff },
        beforeEnter: (to, from, next) => {
          store.dispatch('logoff')

          next('/entrar')
        }
      }

    ],
  },
  {
    path: "/",
    redirect: "/entrar",
    component: AuthLayout,
    beforeEnter: onlyNotAuthenticated,
    children: [
      {
        path: "/entrar",
        name: "Entrar",
        components: { default: Login },
      }
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
