<template>
  <modal
    :show="showModal"
    @update:show="showModal = $event"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template v-slot:header>
        <h4 class="modal-title" id="exampleModalLabel">
          {{ isNew ? "Adicionar Tarefa" : "Editar Tarefa" }}
        </h4>
        <h6 class="text-muted">
          Apenas os usuários que você marcar poderão visualizar esta tarefa
        </h6>
      </template>

      <base-form @onSubmit="save">
        <div class="row">
          <div class="col-12">
            <!--TÍTULO-->
            <div class="form-group row mb-2">
              <label
                class="
                  col-lg-2 col-md-12 col-sm-12 col-form-label
                  form-control-label
                "
                >Título:</label
              >
              <div class="col-lg-10 col-md-12 col-sm-12">
                <base-input
                  formClasses="input-group-alternative"
                  v-model="task_.title"
                  required
                  placeholder="Digite o título desta tarefa"
                >
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <!--DESCRIÇÃO-->
            <div class="form-group row mb-2">
              <label
                class="
                  col-lg-2 col-md-12 col-sm-12 col-form-label
                  form-control-label
                "
                >Descrição:</label
              >
              <div class="col-lg-10 col-md-12 col-sm-12">
                <base-input
                  formClasses="input-group-alternative"
                  required
                  placeholder="Descrição breve da observação"
                >
                  <textarea
                    v-model="task_.description"
                    class="form-control"
                    rows="4"
                    maxlength="999"
                  ></textarea>
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="dropdown-divider"></div>
        <div class="row justify-content-between">
          <h5>Usuários</h5>
          <el-autocomplete
            v-model="searchUser"
            size="mini"
            v-if="showInputMarkedUsers"
            value-key="name"
            popper-class="expand-popper"
            :fetch-suggestions="queryUsers"
            placeholder="+ Usuário"
            trigger-on-focus
            @select="markUser($event, a)"
          >
          </el-autocomplete>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInputMarkedUsers = true"
            >+ Usuário</el-button
          >
        </div>

        {{ task_.marks?.map((m) => m.user.name) }}
      </base-form>

      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button
            size="sm"
            @click="showModal = false"
            class="float-right"
            type="danger"
            >CANCELAR</base-button
          >
          <base-button
            size="sm"
            @click="save"
            class="float-right"
            type="primary"
            >{{ isNew ? "CADASTRAR" : "SALVAR" }}</base-button
          >
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
/** External dependencies */
import { ElNotification, ElAutocomplete, ElButton } from "element-plus";

/** Internal dependencies */
import BaseButton from "../../components/BaseButton.vue";
import BaseForm from "../../components/BaseForm.vue";
import UserService from "../../services/users";
import ObservationService from "../../services/observations";

export default {
  components: { BaseButton, BaseForm, ElAutocomplete, ElButton },
  name: "task-modal",
  props: {
    shouldShow: {
      default: () => false,
      type: Boolean,
    },
    task: {
      type: Object,
      //default: () => ({}),
    },
    student: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      task_: { ...(this.task || {}) },
      showInputMarkedUsers: false,
      searchUser: null,
    };
  },
  watch: {
    task(v) {
      this.task_ = v || {};
    },
    student() {
      this.task_ = {};
    },
    shouldShow(v) {
      if (v && this.task?.uid) this.getTask(this.task.uid);
    },
  },
  computed: {
    isNew() {
      return !this.task;
    },
    showModal: {
      get: function () {
        return this.shouldShow;
      },
      set: function () {
        this.$emit("close-modal", false);
      },
    },
  },
  methods: {
    async queryUsers(query, callback) {
      const { users } = await UserService().index({ searchName: query });

      if (users) callback(users);
    },
    async markUser(user, { uid: obsId }) {
      const { observation } = await ObservationService(obsId).update({
        addUser: user,
      });

      if (observation) {
        this.emitReloadData();
        ElNotification.success({ message: "Usuário adicionado com sucesso!" });
      }
    },
    save() {
      return this.isNew ? this.createTask() : this.updateTask();
    },
    toggleLoading() {
      this.isLoading = !this.isLoading;
    },
    async createTask() {
      this.toggleLoading();
      const { task } = await UserService(this.$store.state.user?.uid)
        .Task()
        .create(this.task_);

      if (task) {
        ElNotification.success({ title: "Tarefa cadastrada com sucesso!" });
        this.emitReloadData();
      } else ElNotification.error({ title: "Erro ao cadastrar tarefa!" });

      this.toggleLoading();
    },
    async updateTask() {
      this.toggleLoading();

      const { task } = await UserService(this.$store.state.user?.uid)
        .Task(this.task_.uid)
        .update(this.task_);

      if (task) {
        ElNotification.success({ title: "Tarefa atualizada com sucesso!" });

        this.emitReloadData();
      } else ElNotification.error({ title: "Erro ao atualizar tarefa!" });

      this.toggleLoading();
    },
    async getTask(uid) {
      const { task } = await UserService(this.$store.state.user?.uid)
        .Task(uid)
        .get();

      if (task) this.task_ = task;
    },
    emitReloadData() {
      this.$emit("reload-data");
      this.showModal = false;
    },
  },
};
</script>
