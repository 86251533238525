import { ElNotification, ElMessageBox } from "element-plus";
export const notifySuccess = (
  title = "A operação foi realizada com sucesso",
  message = ""
) => {
  ElNotification.success({ title: title, message: message });
};
export const notifyError = (
  title = "Não foi possível realizar essa operação",
  message = "Verifique se você tem permissão ou se o servidor está funcional"
) => {
  ElNotification.error({ title, message });
};
export const messageOnCenter = (
  title = "Temos um problema",
  message = "Existe um problema e não podemos avançar com sua requisição"
) => {
  ElMessageBox({ title, message });
};
export const confirmOnCenter = ElMessageBox.confirm;

export const promptUser = (title, message, type = "warning") =>
  new Promise((resolve, reject) => {
    ElMessageBox.prompt(message, title, {
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      type: type,
    })
      .then(({ value }) => {
        resolve(value);
      })
      .catch(() => {
        reject(null);
      });
  });
