<template>
  <div>
    <dashboard-navbar
      searchHint="Buscar curso..."
      @update-search="searchLogs"
    ></dashboard-navbar>

    <div class="container-fluid d-flex h-100 flex-column pt-6 px-2">
      <base-loader v-if="isLoading"></base-loader>
      <base-error v-else-if="hasError"></base-error>
      <projects-table
        v-else
        type="hover"
        :tableData="users"
        title="Lista de Usuários"
        @showModal="showUserModal = $event"
      >
        <template #actions>
          <div class="row justify-content-end mr-0">
            <el-tabs v-model="userPage">
              <el-tab-pane
                label="Usuários"
                value="users"
                name="users"
              ></el-tab-pane>

              <el-tab-pane label="Logs" value="logs" name="logs"></el-tab-pane>
            </el-tabs>
            <div class="col-md-3 col-sm-4 pr-0">
              <base-button
                type="primary"
                icon="fa fa-plus-square"
                size="sm"
                v-on:click.stop.prevent="openUserModal(null)"
              ></base-button>
            </div>
          </div>
        </template>

        <template #columns v-if="userPage == 'users'">
          <th v-for="col in TableColumnsUsers" :key="col">{{ col }}</th>
        </template>

        <template #columns v-else>
          <th v-for="col in TableColumnsLogs" :key="col">{{ col }}</th>
        </template>

        <template
          #data
          v-if="users && users?.length > 0 && userPage === 'users'"
        >
          <tr v-for="_ in LimitedRows" :key="_.uid">
            <td>
              {{ _.name }}
            </td>
            <td>
              {{ _.email }}
            </td>

            <td>
              {{ _.roles?.name || "" }}
            </td>

            <td>
              <div class="btn-group float-right">
                <el-popconfirm
                  :title="`Tem certeza que deseja ${
                    _.is_active ? 'bloquear' : 'liberar'
                  } este usuário?`"
                  :confirm-button-type="_.is_active ? 'danger' : 'success'"
                  :confirm-button-text="`${
                    _.is_active ? 'Bloquear' : 'Liberar'
                  }`"
                  @confirm="updateUser({ ..._, is_active: !_.is_active })"
                  cancel-button-text="Cancelar"
                >
                  <template #reference>
                    <base-button
                      :type="!_.is_active ? 'danger' : 'success'"
                      iconOnly
                      size="sm"
                      :icon="`fa fa-${!_.is_active ? 'lock' : 'lock-open'}`"
                    ></base-button>
                  </template>
                </el-popconfirm>
                <base-button
                  size="sm"
                  type="primary"
                  @click.stop="openUserModal(_)"
                  ><i class="fa fa-pencil"></i
                ></base-button>
                <el-popconfirm
                  title="Tem certeza que deseja remover este usuário?"
                  confirm-button-type="danger"
                  confirm-button-text="Deletar usuário!"
                  @confirm="removeUser(_)"
                  cancel-button-text="Cancelar"
                >
                  <template #reference>
                    <base-button
                      type="danger"
                      iconOnly
                      size="sm"
                      icon="fa fa-trash"
                    ></base-button>
                  </template>
                </el-popconfirm>
              </div>
            </td>
          </tr>
        </template>

        <template
          #data
          v-else-if="logs && logs?.length > 0 && userPage === 'logs'"
        >
          <tr v-for="_ in logs" :key="_.uid">
            <td>
              {{ _.user?.name }}
            </td>
            <td>
              {{ _.title }}
            </td>
            <td>
              {{ _.details }}
            </td>

            <td>
              {{ _.ip }}
            </td>

            <td>
              {{ dateFormatter.format(new Date(_.created_at)) }}
            </td>
          </tr>
        </template>

        <template #pagination>
          <base-pagination
            v-if="userPage == 'users'"
            @input="pagination = $event"
            :value="pagination"
            :perPage="limit"
            :total="users?.length"
          ></base-pagination>
          <base-pagination
            v-else
            @input="pagination = $event"
            :value="pagination"
            :perPage="limit"
            :total="totalLogs"
          ></base-pagination>
        </template>
      </projects-table>

      <user-modal
        :user="user"
        :shouldShow="showUserModal"
        @close-modal="showUserModal = false"
        @reload-data="fetchUsers"
      ></user-modal>
    </div>
  </div>
</template>

<script>
//import Card from "../components/Card.vue";
import DashboardNavbar from "../layout/DashboardNavbar.vue";
import "vue-loaders/dist/vue-loaders.css";
import BaseLoader from "../components/BaseLoader.vue";
import BaseError from "../components/BaseError.vue";
import UserModal from "./Modals/AddUserModal.vue";
import { ElPopconfirm, ElNotification, ElTabs, ElTabPane } from "element-plus";
import UserService from "../services/users";
import ProjectsTable from "./Tables/ProjectsTable.vue";
// @ is an alias to /src

export default {
  name: "Users",
  components: {
    DashboardNavbar,
    BaseLoader,
    BaseError,
    ElPopconfirm,
    ProjectsTable,
    ElTabs,
    UserModal,
    ElTabPane,
  },
  data() {
    return {
      isLoading: true,
      pagination: 1,
      limit: 15,
      userPage: "users",
      showUserModal: false,
      searchName: "",
      selectedTab: "users",
      users: null,
      totalLogs: 0,
      logs: null,
      user: null,
      dateFormatter: new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
        timeStyle: "short",
      }),
      hasError: false,
    };
  },
  mounted() {
    this.fetchUsers();
    this.fetchLogs();
  },
  computed: {
    Offset() {
      return (this.pagination - 1) * this.limit;
    },
    LimitedRows() {
      return this.users.slice(this.Offset, this.Offset + this.limit);
    },
    TableColumnsUsers: () => ["nome", "email", "cargo", "ações"],
    TableColumnsLogs: () => ["usuário", "título", "descrição", "ip", "data"],
  },
  watch: {
    pagination() {
      this.fetchUsers();
      this.fetchLogs();
    },
    userPage() {
      this.pagination = 1;
    },
  },
  methods: {
    openUserModal(user) {
      this.user = user;
      this.showUserModal = true;
    },
    searchLogs(name) {
      this.searchName = name;
      this.pagination = 1;
      this.fetchLogs();
    },
    async updateUser(_) {
      const { user } = await UserService(_.uid).update(_);

      if (user) this.fetchUsers();
    },
    removeUser(user) {
      fetch(`${this.$store.state.apiUrl}users/${user.uid}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) {
            this.errorMessages = {};
            this.fetchUsers();
            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
            }),
          (e) =>
            ElNotification.error({
              message: e.message,
            })
        )
        .finally(() => (this.isLoadingSubmit = false));
    },
    hasValue(item, column) {
      return item[column] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    click() {},
    fetchUsers() {
      fetch(`${this.$store.state.apiUrl}users`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          (json) => (this.users = json),
          () => (this.hasError = true)
        )
        .finally(() => (this.isLoading = false));
    },
    fetchLogs() {
      this.logs = null;
      const url = new URL(`${this.$store.state.apiUrl}users/logs`);
      url.search = new URLSearchParams({
        paginate: this.pagination,
        searchName: this.searchName,
        limit: 15,
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          (json) => {
            this.logs = json?.data;
            this.totalLogs = json?.total;
          },
          () => {}
        )
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
