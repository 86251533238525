<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <el-scrollbar max-height="100%" class="hidden-xs-only">
      <side-bar
        :background-color="sidebarBackground"
        short-title="SM"
        title="educação"
      >
        <template v-slot:links>
          <sidebar-item
            :link="{
              name: 'Inicio',
              icon: 'fa fa-home text-primary',
              path: '/dashboard',
            }"
          />
          <sidebar-item
            v-if="false"
            :link="{
              name: 'Tarefas',
              icon: 'fas fa-list-ol text-primary',
              path: '/tarefas',
            }"
          />
          <!--Divider-->
          <hr class="my-3" />
          <!--Heading-->
          <h6 class="navbar-heading text-muted mx-auto">Cadastro</h6>
          <sidebar-item
            v-if="userCan('students.r')"
            :link="{
              name: 'Alunos',
              icon: 'fa fa-user text-primary',
              path: '/alunos',
            }"
          />
          <sidebar-item
            v-if="userCan('roles.r')"
            :link="{
              name: 'Cargos',
              icon: 'fa fa-id-card text-primary',
              path: '/cargos',
            }"
          />
          <sidebar-item
            v-if="userCan('comissions.r')"
            :link="{
              name: 'Comissões',
              icon: 'fa fa-money-bill-wave-alt text-primary',
              path: '/comissoes',
            }"
          />
          <sidebar-item
            v-if="userCan('courses.r')"
            :link="{
              name: 'Cursos',
              icon: 'ni ni-books text-primary',
              path: '/cursos',
            }"
          />
          <sidebar-item
            v-if="userCan('schools.r')"
            :link="{
              name: 'Inst. de Ensino',
              icon: 'fa fa-building text-primary',
              path: '/escolas',
            }"
          />

          <sidebar-item
            v-if="userCan('enrols.r')"
            :link="{
              name: 'Matrículas',
              icon: 'fa fa-copy text-primary',
              path: '/matriculas',
            }"
          />
          <sidebar-item
            v-if="userCan('users.r')"
            :link="{
              name: 'Usuários',
              icon: 'fa fa-users text-primary',
              path: '/usuarios',
            }"
          />

          <hr class="my-3" />
          <!--Heading-->
          <h6
            class="navbar-heading text-muted mx-auto"
            v-if="userCan('payments.r')"
          >
            Financeiro
          </h6>

          <sidebar-item
            v-if="userCan('payments.r')"
            :link="{
              name: 'Repasse',
              icon: 'fa fa-cash-register text-primary',
              path: '/repasse',
            }"
          />

          <sidebar-item
            v-if="userCan('payments.r')"
            :link="{
              name: 'Inadimplência',
              icon: 'fa fa-cash-register text-primary',
              path: '/inadimplencia',
            }"
          />

          <hr class="my-3" />
          <!--Heading-->
          <h6
            class="navbar-heading text-muted mx-auto"
            v-if="userCan('enrol_exams.r')"
          >
            Pedagógico
          </h6>

          <sidebar-item
            v-if="userCan('enrol_exams.r')"
            :link="{
              name: 'Prova',
              icon: 'fa fa-file-text text-primary',
              path: '/prova',
            }"
          />

          <!--Divider
          <hr class="my-3" />
          Heading
          <h6 class="navbar-heading text-muted mx-auto">Conta</h6>
          <sidebar-item
            :link="{
              name: 'Minha Conta',
              icon: 'fa fa-user-circle text-primary',
              path: '/cursos',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Sair',
              icon: 'fa fa-sign-out text-primary',
              path: '/sair',
            }"
          />-->
        </template>
      </side-bar>
    </el-scrollbar>
    <div class="main-content" :data="sidebarBackground">
      <!--<dashboard-navbar></dashboard-navbar>-->

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
//import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import "element-plus/lib/theme-chalk/display.css";
import { ElScrollbar } from "element-plus";

export default {
  components: {
    //DashboardNavbar,
    ContentFooter,
    ElScrollbar,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    userCan(permission) {
      const { authorizations } = this.$store.state.user?.role || {};

      if (authorizations) {
        const [entity, action] = permission.split(".");

        if (authorizations[entity] && authorizations[entity].includes(action))
          return true;
      }

      return false;
    },
  },
};
</script>
<style lang="scss">
.navbar-vertical {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  width: 175px !important;
}
@media (min-width: 768px) {
  .main-content {
    margin-left: 175px !important;
  }
}
</style>
