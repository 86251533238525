<template>
  <modal
    v-model:show="showModal"
    body-classes="p-0"
    :showClose="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-1"
      body-classes="px-lg-5 py-lg-1"
      class="border-0"
    >
      <template v-slot:header>
        <h4 class="modal-title" id="exampleModalLabel">
          {{ isNewEvent ? "CADASTRAR EVENTO" : "EDITAR EVENTO" }}
        </h4>
        <h6 class="text-muted">
          preencha o formulário para cadastrar o evento
        </h6>
      </template>

      <form role="form">
        <div class="row">
          <div class="col-12">
            <!--NOME-->
            <div class="form-group row mb-2">
              <label class="col-2 col-form-label form-control-label"
                >Título:</label
              >
              <div class="col-10">
                <base-input
                  v-model="event_.label"
                  formClasses="input-group-alternative"
                  required
                  placeholder="Título do evento"
                ></base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <!--NOME-->
            <div class="form-group row mb-2">
              <label class="col-2 col-form-label form-control-label"
                >Descrição:</label
              >
              <div class="col-10">
                <base-input
                  v-model="event_.description"
                  formClasses="input-group-alternative"
                  required
                  type="textarea"
                  placeholder="Descrição breve deste evento"
                ></base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col col-6">
            <div class="form-group row mb-2">
              <div class="col-4">
                <label class="col-form-label form-control-label"
                  >Data do Evento:</label
                >
              </div>
              <div class="col col-8">
                <DatePicker
                  v-model="event_.day"
                  timezone="UTC"
                  :model-config="{ timeAdjust: '00:00:00' }"
                  :popover="{ visibility: 'focus' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <base-input
                      formClasses="input-group-alternative"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="25/02/2021"
                    ></base-input>
                  </template>
                </DatePicker>
              </div>
            </div>
          </div>
        </div>

        <div class="dropdown-divider"></div>

        <div class="row justify-content-between">
          <div class="col-md-6 col-sm-12">
            <div class="text-left mb-2">
              <h5>Visibilidade:</h5>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="row justify-content-end mx-auto">
              <h5 class="mr-3">Evento público</h5>
              <base-switch v-model="event_.isPublic" inline class="mb-3 pl-3">
              </base-switch>
            </div>
          </div>
        </div>

        <div class="dropdown-divider"></div>
        <h5>Tipo do Evento:</h5>
        <base-dropdown
          size="large"
          hint="Selecione o tipo do evento"
          class="input-group-alternative"
          v-model="event_.category"
          valueKey="value"
          labelKey="label"
          :items="EventCategories"
        ></base-dropdown>
      </form>
      <template v-slot:footer>
        <div class="row justify-content-between">
          <base-button
            size="sm"
            @click="showModal = false"
            class="float-right"
            type="danger"
            >CANCELAR</base-button
          >
          <base-button
            size="sm"
            @click="save"
            class="float-right"
            type="primary"
            >SALVAR</base-button
          >
        </div>
      </template>
    </card>
  </modal>
</template>

<script>
import BaseButton from "../../components/BaseButton";
import BaseSwitch from "../../components/BaseSwitch";
import BaseDropdown from "../../components/BaseDropdown";
import { DatePicker } from "v-calendar";
import { ElNotification } from "element-plus";
export default {
  components: { BaseButton, DatePicker, BaseSwitch, BaseDropdown },
  name: "event-modal",
  props: ["shouldShow", "event", "student"],
  computed: {
    showModal: {
      get() {
        return this.shouldShow;
      },
      set() {
        this.$emit("close-modal", false);
      },
    },
    isNewEvent() {
      return !this?.event?.uid;
    },
    EventCategories() {
      return [
        {
          label: "Urgente",
          value: "urgent",
          uid: "urgent",
        },
        {
          label: "Agendamento",
          value: "normal",
          uid: "normal",
        },
        {
          label: "Informação",
          value: "info",
          uid: "info",
        },
        {
          label: "Contato",
          value: "problem",
          uid: "problem",
        },
      ];
    },
  },
  methods: {
    save() {
      this.isNewEvent ? this.createEvent() : this.updateEvent();
    },
    createEvent() {
      fetch(`${this.$store.state.apiUrl}events`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...this.event_,
          student: { uid: this.student?.uid },
          user: { uid: this?.$store?.state?.user?.uid },
          visibility: this.event_.isPublic ? "public" : "private",
        }),
      })
        .then(async (response) => {
          if (response.status == 200) {
            this.errorMessages = {};
            this.$emit("reload-data", true);
            this.$emit("close-modal", true);

            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
            }),
          (e) =>
            ElNotification.error({
              message: e.message,
            })
        )
        .finally(() => (this.isLoadingSubmit = false));
    },
    updateEvent() {
      fetch(`${this.$store.state.apiUrl}events/${this.event_.uid}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...this.event_,
          student: { uid: this.student?.uid },
          user: { uid: this?.$store?.state?.user?.uid },
          visibility: this.event_.isPublic ? "public" : "private",
        }),
      })
        .then(async (response) => {
          if (response.status == 200) {
            this.errorMessages = {};
            this.$emit("reload-data", true);
            this.$emit("close-modal", true);

            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
            }),
          (e) =>
            ElNotification.error({
              message: e.message,
            })
        )
        .finally(() => (this.isLoadingSubmit = false));
    },
  },
  emits: ["reload-data", "close-modal"],
  watch: {
    event(e) {
      this.event_ = { ...(e || {}) };
      this.event_.isPublic = e?.visibility === "public";
    },
  },
  data() {
    return {
      event_: { ...(this.event || {}) },
    };
  },
};
</script>
