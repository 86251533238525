
import router from "../../router";
export default {
    state: () => ({
        user: null,
    }),
    mutations: {
        save(state, user) {
            if (user)
                this.state.user = this?.state?.user ? { ...this.state.user, ...user } : user;

            localStorage.setItem('user', this.state.user)
        },
        removeUser() {
            this.state.user = null
            localStorage.setItem('user', null)
            router.push('/entrar')
        }
    },
    actions: {
        loadUser({ commit }) {
            fetch(`${this.state.apiUrl}user/data`, {
                credentials: "include",
                method: 'GET',
                headers: {
                    Accept: 'application/json'
                }
            })
                .then(response => {
                    if (response.status == 200) return response.json()
                    throw response.json()
                })
                .then(
                    json => commit('save', json)
                )
                .catch(
                    () => {
                        commit('removeUser')
                    }
                )
        },
        async authenticate({ commit, dispatch }, data_auth) {
            return fetch(`${this.state.apiUrl}sign/in`, {
                credentials: "include",
                //mode: "no-cors",
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data_auth)
            }).then(response => {
                if (response.status == 200 || response.status == 302) return response.json()
                throw response.json()
            }).then(json => {
                commit('save', json);
                dispatch('fetchSchools')
                return json;
            });
        },
        async logoff({ commit }) {
            return fetch(`${this.state.apiUrl}sign/out`, {
                credentials: "include",
                //mode: "no-cors",
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
            }).then(response => {
                if (response.status == 200 || response.status == 302) return response.json()
                throw response.json()
            }).then(json => {
                commit('removeUser');
                return json;
            });
        },
    },

    getters: {
        isAuthenticated() {
            return localStorage.getItem('user') != "null"
        }
    }
}
