<template>
  <div id="app">
    <router-view />
  </div>
</template>
<style>
div {
  font-size: 12px !important;
}
.el-select {
  width: 100%;
}
</style>
