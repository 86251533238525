import StudentService from "../services/students";
import CourseService from "../services/courses";
import EnrolService from "../services/enrols";
import PaymentService from "../services/payments";
import { dateReverse } from "./formatters";
const postStudent = (student, resolve, reject, setUploadState) => {
	StudentService()
		.create(student)
		.then(() =>
			queries.length
				? postStudent(queries.pop(), resolve, reject, setUploadState)
				: resolve()
		)
		.catch((e) => reject(e.message));
	setUploadState(`${queries.length} objetos restantes`);
};

export const loadStudents = (school_uid, students, setUploadState) => {
	return new Promise((resolve, reject) => {
		if (Array.isArray(students)) {
			students.forEach((s) =>
				queries.push({
					...parseDateAttrs(s),
					contact: { phone: s["contact"] },
					obs: s["obs"]?.length
						? {
							title: "Documentos pendentes",
							description: s["obs"],
						}
						: null,
					addr: {
						street: s["street"],
						place: s["place"],
						postal_code: s["postal_code"],
						number: s["number"],
						city: s["city"],
						state: s["state"],
					},
					school_uid,
				})
			);

			postStudent(queries.pop(), resolve, reject, setUploadState);
			postStudent(queries.pop(), resolve, reject, setUploadState);
			postStudent(queries.pop(), resolve, reject, setUploadState);
		} else resolve();
	});
};

//let log = "";
const queries = [];

const postCourse = (course, resolve, reject, setUploadState) => {
	CourseService()
		.create(course)
		.then(() =>
			queries.length
				? postCourse(queries.pop(), resolve, reject, setUploadState)
				: resolve()
		)
		.catch((e) => reject(e.message));
	setUploadState(`${queries.length} objetos restantes`);
};

export const loadCourses = (school_id, courses, setUploadState) => {
	if (Array.isArray(courses)) {
		return new Promise((resolve, reject) => {
			courses.forEach((s) =>
				queries.push({
					...parseDateAttrs(s),
					school_id,
				})
			);

			postCourse(queries.pop(), resolve, reject, setUploadState);
			postCourse(queries.pop(), resolve, reject, setUploadState);
			postCourse(queries.pop(), resolve, reject, setUploadState);
		});
	}
};

const postEnrol = (enrol, resolve, reject, setUploadState) => {
	EnrolService()
		.create(enrol)
		.then(() =>
			queries.length
				? postEnrol(queries.pop(), resolve, reject, setUploadState)
				: resolve()
		)
		.catch((e) => reject(e.message));
	setUploadState(`${queries.length} objetos restantes`);
};

export const loadEnrols = async (
	school_uid,
	enrols,
	setUploadState,
	setErrors,
	comission
) => {
	const [{ courses }, { students }] = await Promise.all([
		CourseService().index({ school_uid }),
		StudentService().index({ school_uid }),
	]);

	if (Array.isArray(enrols))
		return new Promise((resolve, reject) => {
			enrols.forEach((s) => {
				const student = students.find((st) => st.code === s.student_id);
				const course = courses.find(
					(ct) => Number(ct.integration_id) === Number(s.course_id)
				);
				const expires_at = new Date(parseDateAttrs(s).start_in);

				if (student && course) {
					if (expires_at)
						expires_at.setMonth(expires_at.getMonth() + course.duration);
					queries.push({
						...parseDateAttrs(s),
						_course: course,
						comission: comission
							? { uid: comission.uid, percentage: [], name: comission.name }
							: null,
						student_uid: student.uid,
						expires_at,
					});
				} else
					setErrors(`Erro ao cadastrar matrícula do aluno código: ${s.student_id} com o curso ${s.course_name}
					`);
			});

			postEnrol(queries.pop(), resolve, reject, setUploadState);
			postEnrol(queries.pop(), resolve, reject, setUploadState);
			postEnrol(queries.pop(), resolve, reject, setUploadState);
			postEnrol(queries.pop(), resolve, reject, setUploadState);
		});
};

const postPayment = (payment, resolve, reject, setUploadState) => {
	PaymentService()
		.create(payment)
		.then(() =>
			queries.length
				? postPayment(queries.pop(), resolve, reject, setUploadState)
				: resolve()
		);
	setUploadState(`${queries.length} objetos restantes`);
};

export const loadPayments = async (
	school_uid,
	payments,
	setUploadState,
	setErrors,
	comission
) => {
	const { enrols } = await EnrolService().index({ school_uid });

	const Enrols = enrols?.reduce((t, e) => {
		t[e.code] = e;
		return t;
	}, {});

	return new Promise((resolve, reject) => {
		if (Array.isArray(payments)) {
			payments.forEach((s) => {
				const enrol = Enrols[Number(s.enrol_id)];
				const student = enrol?.student;

				if (!s.cost) s.cost = s.value;
				if (student && enrol && s.status != "closed") {
					queries.push({
						...parseNumber(parseDateAttrs(s)),
						comission: enrol.comission || comission,
						enrol_id: enrol.uid,
						student_id: student.uid,
					});
				} else if (s.status != "closed")
					setErrors(`Erro ao criar título para matrícula ${s.enrol_id}
					`);
			});

			postPayment(queries.pop(), resolve, reject, setUploadState, setErrors);
			postPayment(queries.pop(), resolve, reject, setUploadState, setErrors);
			postPayment(queries.pop(), resolve, reject, setUploadState, setErrors);
			postPayment(queries.pop(), resolve, reject, setUploadState, setErrors);
			postPayment(queries.pop(), resolve, reject, setUploadState, setErrors);
			postPayment(queries.pop(), resolve, reject, setUploadState, setErrors);
		}
	});
};

const dateAttrs = [
	"created_at",
	"start_in",
	"expires_at",
	"birthday",
	"paid_at",
];

const numberAttrs = [
	'cost', 'value'
]

const parseDateAttrs = (data) => {
	for (let o in data) {
		if (dateAttrs.includes(o)) data[o] = dateReverse(data[o]);
	}

	return data;
};

const parseNumber = (data) => {
	for (let o in data) {
		if (numberAttrs.includes(o)) data[o] = Number(data[o].replace(',', '.'));
	}

	return data;
}

/*const postObs = (observation) =>
	fetch(`${API_URL}/observations`, {
		method: "POST",
		credentials: "include",
		mode: "cors",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body: JSON.stringify({
			...observation,
		}),
	});




const putEnrol = (enrol) =>
	fetch(`${API_URL}/enrols/${enrol.uid}`, {
		method: "PUT",
		credentials: "include",
		mode: "cors",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body: JSON.stringify({
			...enrol,
		}),
	}).then((e) => (queries.length ? putEnrol(queries.pop()) : e));

const postPayment = (payment) =>
	fetch(`${API_URL}/payments`, {
		method: "POST",
		credentials: "include",
		mode: "cors",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body: JSON.stringify({
			...payment,
		}),
	}).then((e) => (queries.length ? postPayment(queries.pop()) : e));







const loadPayments = async (school_uid, payments) => {
	const urlEnrols = new URL(API_URL + "/enrols");

	urlEnrols.search = new URLSearchParams({ school_uid });
	const enrols = await fetch(urlEnrols, {
		credentials: "include",
	}).then((response) => response.json());

	/*const urlStudents = new URL(API_URL + "/students");

		urlStudents.search = new URLSearchParams({ school_uid });
		const students = await fetch(urlStudents, {
				credentials: 'include',
		}).then(response => response.json())

		const mapped_students = students?.reduce((t, s) => {
				t[s.code] = s;
				return t;
		}, {})

	const mapped_enrols = enrols?.reduce((t, e) => {
		t[e.code] = e;
		return t;
	}, {});
	if (Array.isArray(payments)) {
		payments.forEach((s) => {
			const enrol = mapped_enrols[s.admission];
			const student = enrol?.student;

			if (!s.cost) s.cost = s.value;
			if (student && enrol && s.status != "closed") {
				queries.push({
					...s,
					comission: {
						percentages: [1, s.profit * 100],
						name: "Padrão SM Finance",
					},
					enrol_id: enrol.uid,
					student_id: student.uid,
				});
			} else if (s.status != "closed")
				log += `Erro ao criar título para matrícula ${s.admission} `;
		});

		postPayment(queries.pop());
		postPayment(queries.pop());
		postPayment(queries.pop());
		postPayment(queries.pop());
		postPayment(queries.pop());
	}
};

const updateEnrols = async (updated_enrols) => {
	let enrols;
	await fetch(`${API_URL}/enrols`, {
		credentials: "include",
		headers: {
			Accept: "application/json",
		},
	})
		.then((r) => r.json())
		.then((json) => (enrols = json));

	const mapped_enrols = updated_enrols?.reduce((t, e) => {
		t[e.faveni_uid] = e;
		return t;
	}, {});

	enrols.forEach((e) => {
		const matchEnrol = mapped_enrols[e.code];
		if (matchEnrol) {
			log += `updating enrol ${e.code}\n`;
			queries.push({
				status: matchEnrol.status,
				uid: e.uid,
			});
			delete mapped_enrols[e.code];
		}
	});

	log += `could not find ${Object.keys(mapped_enrols).length} enrols to update`;
	log += Object.keys(mapped_enrols);

	putEnrol(queries.pop());
	putEnrol(queries.pop());
	putEnrol(queries.pop());
};
const API_URL = "http://api.edu.salmazomorais.info";*/

export const convertCsvToObject = (csvTxt) => {
	if (csvTxt?.length) {
		const csvLines = csvTxt.split("\n").map((h) => h.trim());

		const headers = csvLines?.shift()?.split(";");

		return csvLines
			?.filter((line) => !!line?.length)
			.map((csvLine) => {
				let totalHeaders = headers.length;
				const mappedLine = csvLine.split(";");
				const csvObject = {};

				while (totalHeaders--)
					csvObject[headers[totalHeaders]] = mappedLine[totalHeaders];

				return csvObject;
			});
	}
};
